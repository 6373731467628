import React from 'react';
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LocalizedStrings from "react-localization";
import * as actions from './Redux/actions.js'

import Principal from './Components/Principal/Principal';
import GenerateQuote from './Components/GenerateQuote/GenerateQuote';
import { localisedText } from '../../../lib/localisation.es6';
import { getCustomer, updateCustomerRequest, resetUpdateCustomerl } from '../../../containers/Customer/actions.es6.js';
import { registerCustomerRequest, resetRegistration } from '../../../containers/CustomerScreen/components/RegisterCustomer/actions.es6.js';
import { getAllProductsRequest } from '../../../containers/CustomerScreen/actions.es6.js';
import { getUsersRequest } from '../../../containers/Users/actions.es6.js';
import { createDebitOrdersDcpRequest, resetDebitOrdersDcp, uploadPolicyDcpDocumentRequest } from '../../../containers/Policyv2/Redux/actions.js';

class PolicyV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: new LocalizedStrings(localisedText),
      openRegisterCustomerDialog: false,
      customerQuote: [],
      autoFillPrincipal: false,
      
    };
  }


  componentDidMount() {
    let customer_guid = this.props.params.customer_guid
    this.props.dispatch(getCustomer(customer_guid));
  }

  componentWillMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getCustomer(currentCustomer.guid))
    }
    this.props.dispatch(getUsersRequest());
    this.props.dispatch(getAllProductsRequest())
    this.props.dispatch(actions.getEmailTemplateByGuidRequest({guid: "965cfd73-04b3-43d4-9534-d6ffd25860b5"}));
    this.resetAllAlerts();
  }

  resetAllAlerts() {
    this.props.dispatch(actions.resetQuoteSuccess());
    this.props.dispatch(actions.resetQuoteError());
    this.props.dispatch(actions.resetBuypolicySuccess());
    this.props.dispatch(actions.resetBuypolicyError());
  }

  registerCustomer(customerData) {
    this.props.dispatch(registerCustomerRequest(customerData));
  }
  resetRegistrationSuccess() {
    this.props.dispatch(resetRegistration());
    hashHistory.push('admin/home');
    this.toggleRegisterCustomerDialog()
  }

  resetRegistrationError() {
    this.props.dispatch(resetRegistration())
  }
  toggleRegisterCustomerDialog() {
    this.setState({ openRegisterCustomerDialog: !this.state.openRegisterCustomerDialog })
  }

  createQuote(payload) {
    payload.customer_guid = this.props.customerData.currentCustomer.guid

    this.props.dispatch(actions.createQuoteRequest(payload))
    this.setState({ customerQuote: payload })
  }


  createDependant(payload) {
    this.props.dispatch(actions.CreateDependantRequest(payload))
  }

  buyPolicy(payload) {
    this.props.dispatch(actions.buyPolicyRequest(payload))
  }

  editCustomer(payload) {
    this.props.dispatch(updateCustomerRequest(payload))
  }

  resetAfterSucesfulyEdit() {
    this.props.dispatch(resetUpdateCustomerl());
  }

  resetAfterFailedEdit() {
    this.props.dispatch(resetUpdateCustomerl());
  }

  changequote(payload) {
    this.props.dispatch(actions.changeQuoteRequest(payload))
  }

  toggleAutoFillPrincipal() {
    this.setState({ autoFillPrincipal: !this.state.autoFillPrincipal })
  }


  addDebitOrder(payload) {
    this.props.dispatch(createDebitOrdersDcpRequest(payload));
  }

  DocumentUpload(doc) {
    const policyGuid = this.props.QuoteData.buyPolicy.guid
    this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...doc, policyGuid }))

  }

  sendEmailRequest(payload){
    this.props.dispatch(actions.sendEmailRequest(payload))
    
  }

  render() {

    if (!this.props.customerData.currentCustomer) {
      return "Loading...";
  }


  

    return (

      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <Principal
              customerData={this.props.customerData.currentCustomer}
              showEditButton={false}
              showAutofillButton={true}
              showClaimButton={false}
              editCustomer={this.editCustomer.bind(this)}
              loader={this.props.customerData.loader}
              customerEditedSuccessfully={this.props.customerData.customer_edited_succesfully}
              customerEditedFailed={this.props.customerData.customer_edited_failed}
              resetAfterSucesfulyEdit={this.resetAfterSucesfulyEdit.bind(this)}
              resetAfterFailedEdit={this.resetAfterFailedEdit.bind(this)}
              toggleAutoFillPrincipal={this.toggleAutoFillPrincipal.bind(this)}
            />
          </Grid>
          <GenerateQuote
            createDependant={this.createDependant.bind(this)}
            dependant={this.props.QuoteData.createdDependant}
            createQuote={this.createQuote.bind(this)}
            createdQuote={this.props.QuoteData.createdQuote}
            products={this.props.customerScreenData.products}

            showAddDependantProgressAlert={this.props.QuoteData.createDependantLoader}
            showAddDependantSuccessAlert={this.props.QuoteData.createDependantSuccess}
            showAddDependantErrorAlert={!!this.props.QuoteData.createDependantError}
            resetAddDependantSuccessAlert={() => { this.props.dispatch(actions.resetCreateDependantSuccess()) }}

            createQuoteError={this.props.QuoteData.createQuoteError}
            showAddQuoteProgressAlert={this.props.QuoteData.createQuoteLoader}
            showAddQuoteSuccessAlert={this.props.QuoteData.createQuoteSuccess}
            showAddQuoteErrorAlert={!!this.props.QuoteData.createQuoteError}
            resetAddQuoteSuccessAlert={() => { this.props.dispatch(actions.resetQuoteSuccess()) }}
            resetAddQuoteErrorAlert={() => { this.props.dispatch(actions.resetQuoteError()) }}


            buyPolicy={this.buyPolicy.bind(this)}
            buyPolicyData={this.props.QuoteData.buyPolicy}
            buyPolicyError={this.props.QuoteData.buyPolicyError}
            showBuyPolicyProgressAlert={this.props.QuoteData.buyPolicyLoader}
            showBuyPolicySuccessAlert={this.props.QuoteData.buyPolicySuccess}
            showBuyPolicyErrorAlert={!!this.props.QuoteData.buyPolicyError}
            resetBuyPolicySuccessAlert={() => { this.props.dispatch(actions.resetBuypolicySuccess()) }}
            resetBuyPolicyErrorAlert={() => { this.props.dispatch(actions.resetBuypolicyError()) }}
            customerData={this.props.customerData.currentCustomer}

            resetAllAlerts={this.resetAllAlerts.bind(this)}

            changeQuote={this.changequote.bind(this)}
            policies={this.props.customerStatusV2Data.policies}
            changeQuoteError={this.props.QuoteData.changeQuoteError}
            changeQuoteProgressAlert={this.props.QuoteData.changeQuoteLoader}
            changeQuoteSuccessAlert={this.props.QuoteData.changeQuoteSuccess}
            changeQuoteErrorAlert={!!this.props.QuoteData.changeQuoteError}
            resetChangeQuoteSuccessAlert={() => { this.props.dispatch(actions.resetChangeQuoteSuccess()) }}
            resetChangeQuoteErrorAlert={() => { this.props.dispatch(actions.resetChangeQuoteError()) }}

            autoFillPrincipal={this.state.autoFillPrincipal}
            toggleAutoFillPrincipal={this.toggleAutoFillPrincipal.bind(this)}
            quoteToReCreate={this.props.QuoteData.quoteToReCreate}

            addDebitOrder={this.addDebitOrder.bind(this)}
            resetDebitOrdersDcp={() => this.props.dispatch(resetDebitOrdersDcp())}

            showAddDebitOrderProgressAlert={
              this.props.QuoteData.createDebitOrderLoader
            }
            showAddDebitOrderSuccessAlert={
              this.props.QuoteData.createDebitOrderSuccess
            }
            showAddDebitOrderErrorAlert={
              !!this.props.QuoteData.createDebitOrderError
            }
            createDebitOrderError={
              this.props.QuoteData.createDebitOrderError
            }
            DocumentUpload={this.DocumentUpload.bind(this)}


            emailTemplateData={this.props.incMotorData.template}
            sendEmailRequest={this.sendEmailRequest.bind(this)}

            sendEmailError={this.props.incMotorData.sendEmailError}
            showSendEmailProgressAlert={this.props.incMotorData.sendEmailLoader}
            showSendEmailSuccessAlert={this.props.incMotorData.sendEmailSuccess}
            showSendEmailErrorAlert={!!this.props.incMotorData.sendEmailError}
            sendEmail={this.props.incMotorData.sendEmail}
           // resetAddQuoteSuccessAlert={() => { this.props.dispatch(actions.resetQuoteSuccess()) }}
            //resetAddQuoteErrorAlert={() => { this.props.dispatch(actions.resetQuoteError()) }}
          />
        </Grid>

      </>
    )
  }
}
export default connect((state) => ({
  QuoteData: state.quoteData,
  incMotorData: state.incMotorData,
  customerStatusV2Data: state.customerStatusV2Data,
  customerData: state.currentCustomer,
  registrationData: state.customerRegistration,
  customerScreenData: state.customerScreen,
  userData: state.userAdmin,
  globalData: state.global,
}))(PolicyV2);