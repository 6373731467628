import { take, call, put } from 'redux-saga/effects';
import * as types from '../../../../lib/actionTypes.es6'
import * as actions from './actions';

import { createQuote,buyPolicy,changeQuote, sendEmail } from '../../../../lib/requests.es6';
import { GAloggerException } from '../../../../lib/monitoring.es6';
//import { unAuthorizedNotification } from '../../../lib/access.es6';
//import { getAllPoliciesRequest } from '../../PolicyV2CustomerStatus/Redux/actions';
import { getEmailTemplateByGuid } from '../../../../lib/requests.es6';
import { unAuthorizedNotification } from '../../../../lib/access.es6';
import { getAllPoliciesRequest } from '../../CustomerStatus/Redux/actions';


export function* createQuoteRequestWatcher() {
  for (;;) {
    const request = yield take(types.CREATE_QUOTE_REQUEST);
    try {
      const response = yield call(createQuote, request.payload);
      yield put(actions.createQuoteSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.createQuoteFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* BuyPolicyRequestWatcher() {
  for (;;) {
    const request = yield take(types.BUY_POLICY_REQUEST);
    try {
      const response = yield call(buyPolicy, request.payload);
      yield put(actions.buyPolicySuccess(response.data));
      yield put(getAllPoliciesRequest({guid: response.data.customer_guid}));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.buyPolicyFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}


export function* changeQuoteRequestWatcher() {
  for (;;) {
    const request = yield take(types.CHANGE_QUOTE_REQUEST);
    try {
      const response = yield call(changeQuote, request.payload);
      yield put(actions.changeQuoteSuccess(response.data));
      yield put(actions.buyPolicySuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.changeQuoteFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* getEmailTemplateByGuidRequestWatcher() {
  for (;;) {
    const request = yield take(types.GET_EMAIL_TEMPLATE_BY_GUID_REQUEST);
    try {
      const response = yield call(getEmailTemplateByGuid, request.payload);
      yield put(actions.getEmailTemplateByGuidSuccess(response.data));
    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.getEmailTemplateByGuidFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}

export function* sendEmailRequestWatcher() {
  for (;;) {
    const request = yield take(types.SEND_EMAIL_REQUEST);
    try {
      const response = yield call(sendEmail, request.payload);
      yield put(actions.sendEmailSuccess(response.data));

    } catch (e) {
      let errorMessage = 'An unexpected error occurred';
      if (e.response) {
        errorMessage = e.response.data;
      }
      yield put(actions.sendEmailFailure(errorMessage));

      GAloggerException(e);
      unAuthorizedNotification(e);
    }
  }
}