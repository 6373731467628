import React from 'react'
import { useStyle } from "./Style";
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Button from '@mui/material/Button';
// import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { formatDateLocale } from '../../../../lib/utils.es6';
const ITEM_HEIGHT = 48;

const CustomerDetails = (props) => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
   
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    < >
     <section className={classes.customerStatusWrapper}>
     <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={(event) => {
                    handleClick(event);
                  //  props.toggleEditCustomerDialog();
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  slotProps={{
                    paper: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                      },
                    },
                  }}
                >

                  <MenuItem style={{
                    cursor: "pointer",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#000000",
                  }} 
                  //onClick={handleClose}
                  onClick={(event) => {
                    handleClose(event);
                    props.toggleEditCustomerDialog();
                  }}
                  >
                    Edit Customer
                  </MenuItem>

                </Menu>
              </div>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData} >
        <Grid item xs={6}><h3>First Name</h3></Grid>
        <Grid item xs={6}><p>{props.data.first_name || "N/A"}</p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData}>
        <Grid item xs={6}><h3>Full Name </h3></Grid>
        <Grid item xs={6}><p> {props.data.full_name || "N/A"}</p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData}>
        <Grid item xs={6}><h3>Surname </h3></Grid>
        <Grid item xs={6}><p> {props.data.surname || "N/A"}</p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData}>
        <Grid item xs={6}><h3>Date Of Birth  </h3></Grid>
        <Grid item xs={6}><p> {props.data.date_of_birth || "N/A"}  </p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData}>
        <Grid item xs={6}><h3>Gender  </h3></Grid>
        <Grid item xs={6}><p> {props.data.gender || "N/A"} </p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData}>
        <Grid item xs={6}><h3>National ID  </h3></Grid>
        <Grid item xs={6}><p> {props.data.national_id || "N/A"}  </p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData}>
        <Grid item xs={6}><h3>Phone Number  </h3></Grid>
        <Grid item xs={6}><p> {props.data.msisdn || "N/A"} </p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      <Grid container spacing={2} className={classes.customerProfileData}>
        <Grid item xs={6}><h3>Created At  </h3></Grid>
        <Grid item xs={6}><p> {formatDateLocale(props.data.created_at) || "N/A"}  </p></Grid>
        <div className={classes.customerProfileDataBorder}></div>
      </Grid>
      {/* <Grid container spacing={2} className={classes.showMoreBtn}>
        <Grid item xs={12}>
          <Button variant="outlined" endIcon={<ExpandMoreOutlinedIcon />}>
            Show More
          </Button>
        </Grid>
      </Grid> */}
     </section>

    </>

  )
}

export default CustomerDetails