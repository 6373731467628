export const MAX_DOCUMENT_SIZE = 5 * 1024 * 1024; // 5MB
export const ALLOWED_DOCUMENT_TYPES = ['application/pdf', 'image/jpeg', 'image/png'];
export const MAX_CLAIM_AMOUNT = 1000000; // $1,000,000

export const CLAIM_STATUSES = {
  ALL_DOCS_PENDING: 'All_Docs_Pending',
  DOCS_PARTIALLY_SUBMITTED: 'Docs_Partially_Submitted',
  DECISION_PENDING: 'Decision_Pending',
  APPROVED_PENDING: 'Approved_Pending',
  APPROVED: 'Approved',
  APPROVED_PAID: 'Approved_Paid',
  REJECTED_PENDING: 'Rejected_Pending',
  REJECTED: 'Rejected',
  EXGRATIA_PENDING: 'ExGratia_Pending',
  EXGRATIA: 'ExGratia',
  EXGRATIA_PAID: 'ExGratia_Paid',
  PAYMENT_IN_REVIEW: 'Payment_In_Review',
  EXPIRED: 'Expired'
};

export const PAYABLE_STATUSES = [
  'Approved',
  'ExGratia'
];

export const BUTTON_STYLES = {
  approve: {
    width: "221px",
    height: "55px",
    background: "#75A85E",
    color: "white"
  },
  reject: {
    width: "221px", 
    height: "55px",
    background: "#8F191C",
    color: "white"
  },
  default: {
    width: "221px",
    height: "55px",
    background: "#FBA92D",
    color: "white"
  }
};

export const READ_ONLY_CLAIM_STATUES = [
  'Approved',
  'Approved_Paid',
  'Approved_Pending',
  'Payment_In_Review',
  'Rejected_Pending',
  'Rejected',
  'ExGratia',
  'ExGratia_Paid',
  'ExGratia_Pending',
  'Decision_Pending',
  'Not_Valid'
]