import React  from 'react'
import { TextValidator, SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import MenuItem from '@mui/material/MenuItem';

import { useStyle } from "./Style";
const VehicleDetails = (props) => {


  const classes = useStyle();


  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'vehicleRegistrationNumber') {
      props.setVehicleRegistrationNumber(value)
    }
    if (name === 'chasisNumber') {
      props.setChasisNumber(value)
    }
    if (name === 'engineNumber') {
      props.setEngineNumber(value)
    }
    if (name === 'bodyDescription') {
      props.setBodyDescription(value)
    }
    if (name === 'fuelType') {
      props.setFuelType(value)
    }
    if(name === "sittingCapabilities"){
      props.setSittingCapabilities(value)
    }
    if(name === "yearsWithDrivingLicence"){
      props.setYearsWithDrivingLicence(value)
    }
    if(name === "color"){
      props.setColor(value)
    }
  }

  const addVehicleDetails = () => {

  }
  return (
    <>
      <ValidatorForm onSubmit={() => addVehicleDetails()}  >
        <section className={classes.quoteinputwrapper}>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.detailstitle}>
                <h3>Lets add Vehicle Details</h3>
                {/* {
                  props.currentRelationShip === 'Principal'
                    ? <h3>Lets add Main member first</h3>
                    : <h3>Add a {props.currentRelationShip} member</h3>

                } */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6}>

              <TextValidator
                id="vehicleRegistrationNumber"
                fullWidth
                label="vehicle Registration Number"
                name='vehicleRegistrationNumber'
                value={props.vehicleRegistrationNumber}
                type='text'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required"]}
                errorMessages={[
                  "Vehicle registration number required",
                ]}


              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="ChasisNumber"
                fullWidth
                label="Chasis Number"
                name='chasisNumber'
                value={props.chasisNumber}
                type='text'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required"]}
                errorMessages={[
                  "Vehicle chasis number required",
                ]}


              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextValidator
                id="engineNumber"
                fullWidth
                label="Engine Number"
                name='engineNumber'
                value={props.engineNumber}
                type='text'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required"]}
                errorMessages={[
                  "Vehicle engine number required",
                ]}


              />

            </Grid>

            <Grid item xs={12} sm={6} md={6}>
            <TextValidator
                id="bodyDescription"
                fullWidth
                label="Body Description (eg Sedan, Hatchback, Station Wagon, SUV, Pick-up, Double Cabin, Van, Bus, Truck, Trailer, Tractor etc)"
                name='bodyDescription'
                value={props.bodyDescription}
                type='text'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required"]}
                errorMessages={[
                  "Vehicle body description required",
                ]}


              />

            </Grid>

            <Grid item xs={12} sm={6} md={6}>


            <SelectValidator
                labelId="select-vehicle-fuel-type"
                id="select-vehicle-fuel-type"
                value={props.fuelType }
                name='fuelType'
                type="text"
                label="Fuel Type"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select vehicle fuel Type']}
                style={{ width: "100%" }}
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="Petrol">Petrol</MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "12px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    color: "#000000",
                  }} value="Diesel">Diesel</MenuItem>

              </SelectValidator>

            </Grid>

            <Grid item xs={12} sm={6} md={6}>
            <TextValidator
                id="sittingCapabilities"
                fullWidth
                label="Sitting Capabilities(1,2,3)"
                name='sittingCapabilities'
                value={props.sittingCapabilities}
                type='number'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required"]}
                errorMessages={[
                  "Vehicle sitting capabilities required",
                ]}


              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
            <TextValidator
                id="yearsWithDrivingLicence"
                fullWidth
                label="Years with driving licence"
                name='yearsWithDrivingLicence'
                value={props.yearsWithDrivingLicence}
                type='number'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required"]}
                errorMessages={[
                  "years with driving licence required",
                ]}


              />

            </Grid>
            <Grid item xs={12} sm={6} md={6}>
            <TextValidator
                id="color"
                fullWidth
                label="Color"
                name='color'
                value={props.color}
                type='text'
                onChange={handleChange}
                className={classes.quoteinputs}
                variant="outlined"
                style={{ width: "100%" }}
                validators={["required"]}
                errorMessages={[
                  "color required",
                ]}


              />

            </Grid>


          </Grid>
        </section>


        <section className={classes.benefitswrapper}>

          <hr />


          {/* 
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={classes.quotesbtns}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type='submit'
                //onClick={addDependant}
                >
                  Add Details
                </Button>

                <Button variant="contained" onClick={() => {
                  props.viewMembers()
                }} style={{ background: 'green', }}>Cancel</Button>

              </div>
            </Grid>
          </Grid> */}
        </section>
      </ValidatorForm >

    </>
  )
}

export default VehicleDetails