import { hashHistory } from 'react-router';
import { getPartner, getPartnerGuid } from './access.es6';
import React from 'react'
import { POLICY_V2_PARTNERS } from './constants';
import { CLAIM_TAG_PARTNERS, PARTNERS_WITH_POLICY_NUMBER_SPECIAL_CHARACTORS } from '../routes/PartnersEnum';
export function displayValue(showMore) {
  if (showMore === false) {
    return 'none';
  }
  return null;
}

export function isObjectEmpty(obj) {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

export function buildFailureMessage(defaultErrorMessage, errorObject) {
  if (errorObject) {
    if (errorObject.errors) {
      if (!isObjectEmpty(errorObject.errors)) {
        const errorList = Object.keys(errorObject.errors).map(
          errorKey => errorObject.errors[errorKey]
        );
        return errorList.join(',');
      }
      return errorObject.message;
    }
    return errorObject.message || errorObject.error_description;
  }
  return defaultErrorMessage;
}
export function goToPage(page) {
  if (hashHistory.getCurrentLocation().pathname !== `/${page}`) {
    hashHistory.push(page);
  }
}

export function formatDateLocale(date) {
  try {
    if (typeof date === 'string') {
      const dateLength = date.length
      if (dateLength === 10) {
        return new Date(date).toLocaleDateString()
      } else {
        return new Date(date).toLocaleString()
      }
    }
  } catch (error) {
    console.log(error)
  }
  return date;
}



export function formatDate(date) {
  const yyyy = date.getFullYear();
  const mm = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();

  const mmChars = mm.split('');
  const ddChars = dd.split('');

  return `${yyyy}-${mmChars[1] ? mm : `0${mmChars[0]}`}-${ddChars[1] ? dd : `0${ddChars[0]}`
    }`;
}

export function formatDateTime(date) {
  const yyyy = date.getFullYear();
  const mm = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();
  const hh = date.getHours().toString();
  const min = date.getMinutes().toString();
  const sec = date.getSeconds().toString();

  const mmChars = mm.split('');
  const ddChars = dd.split('');
  const hhChars = hh.split('');
  const minChars = min.split('');
  const secChars = sec.split('');

  return `${yyyy}-${mmChars[1] ? mm : `0${mmChars[0]}`}-${ddChars[1] ? dd : `0${ddChars[0]}`
    } ${hhChars[1] ? hh : `0${hhChars[0]}`}:${minChars[1] ? min : `0${minChars[0]}`
    }:${secChars[1] ? sec : `0${secChars[0]}`}`;
}

export function trimTimestampFromDate(dateAsString) {
  return (dateAsString && dateAsString.length > 10) ? dateAsString.substring(0, 10) : dateAsString
}

export function formatStatus(status) {
  if (!status) {
    return 'undefined'
  }
  return status.replace(/_/g, ' ');
}

export function getGroupPremiumEstimate(groupSize, selectedProduct) {
  if (selectedProduct && groupSize) {
    const calculatedPremium = Number(selectedProduct.premiums[0].amount_in_cents / 100 * groupSize)
    const productBenefit = Number(selectedProduct.fixed_indemnities[0].amount_benefit)
    return Math.max(calculatedPremium, productBenefit) + " per " + selectedProduct.premiums[0].granularity
  } else {
    return undefined
  }
}

export function getPaginatedGroups(payload) {
  if (payload.items) {
    return { groups: payload.items, count: payload.count }
  }
  return { groups: payload }
}

export function getPaginatedMembers(payload) {
  if (payload.items) {
    return { members: payload.items, count: payload.count }
  }
  return { members: payload }
}

export function getPaginatedGroupPolicies(payload) {
  if (payload.items) {
    return { policies: payload.items, policiesCount: payload.count }
  }
  return { policies: payload }
}


export function pascalCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCurrentUserInfo() {
  const user = JSON.parse(localStorage.getItem('auth'));
  if (user) {
    return user;
  }
  return null;
}

export function downloadBlobReports(byteArray, fileName) {
  //console.log("byteArray", byteArray)
  //console.log("fileName", fileName)


  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';

  const url = window.URL.createObjectURL(new Blob([byteArray]));
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}
export function displayFieldValidationErrors(errorList, errorMessage, errorMessage2) {
  if (!errorList || errorList.length === 0) {
    return ""
  }

  if (errorList.includes(errorMessage)) {
    return <p style={{ color: "red" }} > {errorMessage} </p>


  } else if (errorList.includes(errorMessage2)) {
    return <p style={{ color: "red" }} > {errorMessage2} </p>
  } else {
    for (let i = 0; i < errorList.length; i++) {
      if (errorList[i].includes(errorMessage)) {
        return <p style={{ color: "red" }} > {errorList[i]} </p>
      }
    }
    return ''
  }
}

export function getEnvironmentInformation() {
  const FQDN = window.location.origin;
  if (FQDN === 'http://localhost') {
    return null;
  }
  if (FQDN) {
    const envURLs = serviceURLsMapping[FQDN];
    const credentials = credentialsMappping[FQDN];
    if (envURLs) {
      const payload = {
        servicesURLs: envURLs,
        credentials,
      };
      return payload;
    }
  }
  return null;
}


export function getEnviroment () {
  const getEnvInformation = getEnvironmentInformation();
  if(getEnvInformation){
      return getEnvInformation.servicesURLs.enviromentType;
  }
  return null
}

export const displayProductFixedBenefits = (fixedIndemnities, fallbackCurrency = '') => {
  let displayedValue = "";
  const partner = getPartner() || {};
  const currency = partner.country ? partner.country.currency_code : fallbackCurrency;
  for (let i = 0; i < fixedIndemnities.length; i++) {
    const indemnity = fixedIndemnities[i];
    displayedValue += `${indemnity.name} Cover ${currency} ${indemnity.amount_benefit}${i === fixedIndemnities.length - 1 ? "" : ", "}`;
  }
  return displayedValue;
}

export const reloadPage = () => {
  window.localStorage.setItem('reload_event', 'internal_reload_fired')
  window.location.reload();
}

export const partnerHasV2Policy = () => {
  const partnerGuid = getPartnerGuid();
  return POLICY_V2_PARTNERS.includes(partnerGuid)
}

export const partnerHasClaimTags = () => {
  const partnerGuid = getPartnerGuid();
  return CLAIM_TAG_PARTNERS.includes(partnerGuid)
}

const servicesURLs = {
  tigoProxy: {
    api: 'https://tigorw.inclusivity-aspin.com:8443/',
    reports: 'https://tigorw.inclusivity-aspin.com:8080/',
  },
  airtel: {
    api: 'https://ingoboka.inclusivity-aspin.com/',
    reports: 'https://ingoboka.inclusivity-aspin.com/',
    bulksms: 'https://ingoboka.inclusivity-aspin.com/',
    loyalty: 'https://ingoboka.inclusivity-aspin.com/',
    enviromentType: 'prod',
    centralAuthentication: 'https://ingoboka.inclusivity-aspin.com/',
    customerApplication: 'https://ingoboka.inclusivity-aspin.com/',
    paymentApplication: 'https://ingoboka.inclusivity-aspin.com/',
    authApplication: 'https://ingoboka.inclusivity-aspin.com/',
  },
  tigo: {
    api: 'https://aspin.tigo.co.rw:8443/',
    reports: 'https://aspin.tigo.co.rw:8080/',
  },
  tigoIP: {
    api: 'https://41.138.83.134:8443/',
    reports: 'https://41.138.83.134:8080/',
  },
  prod: {
    api: 'https://engine.inclusivity-aspin.com/',
    reports: 'https://reports.inclusivity-aspin.com/',
    bulksms: 'https://engine.inclusivity-aspin.com',
    loyalty: 'https://engine.inclusivity-aspin.com/',
    groupMobisite: 'https://mobisite.grouplastexpense.com/#/group-link/',
    enviromentType: 'prod',
    centralAuthentication: 'https://auth.inclusivity-aspin.com/',
    customerApplication: 'https://customer-acquisition.inclusivity-aspin.com/',
    paymentApplication: 'https://api.inclusivity-aspin.com/',
    authApplication: 'https://api.inclusivity-aspin.com/',
  },
  localhost: {
    api: 'https://engine.staging.aspin-inclusivity.com/',
    reports: 'https://reports.staging.aspin-inclusivity.com/',
    bulksms: 'https://engine.staging.aspin-inclusivity.com',
    loyalty: 'https://engine.staging.aspin-inclusivity.com',
    groupMobisite: 'https://mobisite.staging.aspin-inclusivity.com/#/group-link/',
    enviromentType: 'staging',
    centralAuthentication: 'https://auth.staging.aspin-inclusivity.com/',
    customerApplication: 'https://customer-acquisition.staging.aspin-inclusivity.com/',
    paymentApplication: 'https://engine.staging.aspin-inclusivity.com/',
    authApplication: 'https://engine.staging.aspin-inclusivity.com/',
  },
  kubernetesDevelopment: {
    api: 'https://engine.dev.aspin-inclusivity.com/',
    reports: 'https://reports.dev.aspin-inclusivity.com/',
    bulksms: 'https://engine.dev.aspin-inclusivity.com',
    loyalty: 'https://engine.dev.aspin-inclusivity.com',
    groupMobisite: 'https://mobisite.dev.aspin-inclusivity.com/#/group-link/',
    enviromentType: 'dev',
    centralAuthentication: 'https://auth.dev.aspin-inclusivity.com/',
    customerApplication: 'https://customer-acquisition.dev.aspin-inclusivity.com/',
    paymentApplication: 'https://payment-service.dev.aspin-inclusivity.com/',
    authApplication: 'https://engine.dev.aspin-inclusivity.com/',
  },
  kubernetesStaging: {
    api: 'https://engine.staging.aspin-inclusivity.com/',
    reports: 'https://reports.staging.aspin-inclusivity.com/',
    bulksms: 'https://engine.staging.aspin-inclusivity.com',
    loyalty: 'https://engine.staging.aspin-inclusivity.com',
    groupMobisite: 'https://mobisite.staging.aspin-inclusivity.com/#/group-link/',
    enviromentType: 'staging',
    centralAuthentication: 'https://auth.staging.aspin-inclusivity.com/',
    customerApplication: 'https://customer-acquisition.staging.aspin-inclusivity.com/',
    paymentApplication: 'https://payment-service.staging.aspin-inclusivity.com/',
    authApplication: 'https://engine.staging.aspin-inclusivity.com/',
  },
  kubernetesProd: {
    api: 'https://engine.inclusivity-aspin.com/',
    reports: 'https://reports.inclusivity-aspin.com/',
    bulksms: 'https://engine.inclusivity-aspin.com',
    loyalty: 'https://engine.inclusivity-aspin.com',
    groupMobisite: 'https://mobisite.grouplastexpense.com/#/group-link/',
    enviromentType: 'prod',
    centralAuthentication: 'https://auth.inclusivity-aspin.com/',
    customerApplication: 'https://customer-acquisition.inclusivity-aspin.com/',
    paymentApplication: 'https://payment-service.aspin-inclusivity.com/',
    authApplication: 'https://engine.inclusivity-aspin.com/',
  }
};

const credentials = {
  prod: {
    clientID: 'webclient',
    clientSecret: 'jU41IrBvdsfYoRyWU6xnYvhne7e109RU',
    realm: 'inclusivity-solutions'
  },
  localhost: {
    clientID: 'webclient',
    clientSecret: 'NTQxusOV8Bl0zGGZpdtw9Mb8bsTlmc4O',
    realm: 'inclusivity-solutions'
  },
  kubernetesDevelopment: {
    clientID: 'webclient',
    clientSecret: 'NTQxusOV8Bl0zGGZpdtw9Mb8bsTlmc4O',
    realm: 'inclusivity-solutions'
  },
  kubernetesStaging: {
    clientID: 'webclient',
    clientSecret: 'NTQxusOV8Bl0zGGZpdtw9Mb8bsTlmc4O',
    realm: 'inclusivity-solutions'
  },
  kubernetesProd: {
    clientID: 'webclient',
    clientSecret: 'jU41IrBvdsfYoRyWU6xnYvhne7e109RU',
    realm: 'inclusivity-solutions'
  }
};


const serviceURLsMapping = {
  'https://inclusivity-aspin.com': servicesURLs.prod,
  'https://dev.inclusivity-aspin.com': servicesURLs.kubernetesDevelopment,
  'https://staging.inclusivity-aspin.com': servicesURLs.kubernetesStaging,
  'https://tigorw.inclusivity-aspin.com': servicesURLs.tigoProxy,
  'https://41.138.83.134': servicesURLs.tigoIP,
  'https://aspin.tigo.co.rw': servicesURLs.tigo,
  'http://localhost:3000': servicesURLs.kubernetesStaging,
  'http://inclusivity-app-dev.s3-website-eu-west-1.amazonaws.com':
    servicesURLs.localhost,
  'https://inclusivity-app-dev.s3-eu-west-1.amazonaws.com': servicesURLs.localhost,
  'https://ingoboka.inclusivity-aspin.com': servicesURLs.airtel,
  'https://grouplastexpense.com': servicesURLs.prod,
  'https://staging.grouplastexpense.com': servicesURLs.kubernetesStaging,
  'https://webclient.dev.aspin-inclusivity.com': servicesURLs.kubernetesDevelopment,
  'https://webclient.staging.aspin-inclusivity.com': servicesURLs.kubernetesStaging,
  'https://webclient.inclusivity-aspin.com': servicesURLs.kubernetesProd,
  'http://34.252.86.123:3000': servicesURLs.kubernetesStaging,
  'http://34.252.214.120:3000': servicesURLs.kubernetesStaging,
  'http://34.252.214.120': servicesURLs.kubernetesStaging,
};


const credentialsMappping = {
  'https://inclusivity-aspin.com': credentials.prod,
  'https://dev.inclusivity-aspin.com': credentials.kubernetesDevelopment,
  'https://staging.inclusivity-aspin.com': credentials.kubernetesStaging,
  'http://localhost:3000': credentials.kubernetesStaging,
  'http://inclusivity-app-dev.s3-website-eu-west-1.amazonaws.com':
    credentials.localhost,
  'https://inclusivity-app-dev.s3-eu-west-1.amazonaws.com': credentials.localhost,
  'https://grouplastexpense.com': credentials.prod,
  'https://staging.grouplastexpense.com': credentials.kubernetesStaging,
  'https://webclient.dev.aspin-inclusivity.com': credentials.kubernetesDevelopment,
  'https://webclient.staging.aspin-inclusivity.com': credentials.kubernetesStaging,
  'https://webclient.inclusivity-aspin.com': credentials.kubernetesProd,
  'http://34.252.86.123:3000': credentials.kubernetesStaging,
  'http://34.252.214.120:3000': credentials.kubernetesStaging,
  'http://34.252.214.120': credentials.kubernetesStaging,
  'https://ingoboka.inclusivity-aspin.com': credentials.kubernetesProd,
};

export const dashboardURI = {
  equityguid:
    'https://app.powerbi.com/view?r=eyJrIjoiMTUwNWNjMjQtMWU3My00NWQ0LTgwOGEtY2I1NmZiYzZkNWU0IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  tigorwandaguid:
    'https://app.powerbi.com/view?r=eyJrIjoiMjRiMDNjMDQtYzZlZS00MWIzLWI0ODgtYzQ1NDAxMDhlY2M1IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  orangeguid:
    'https://app.powerbi.com/view?r=eyJrIjoiYjc3NTMwM2QtYzkyZS00ZGIxLWE2OGItZDBlYmRhMTA3OTljIiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  mfsguid:
    'https://app.powerbi.com/view?r=eyJrIjoiZDE4MzNkYzMtMWY2OS00MWM4LTkxODMtZjkwNjhkYmMyNWNlIiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  fnbzambiaguid:
    'https://app.powerbi.com/view?r=eyJrIjoiMjRiMmNiNTUtZDNiYi00OGExLTlmNmQtOTQ3NjgxZjEwYzZlIiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  safaricomkenya:
    'https://app.powerbi.com/view?r=eyJrIjoiNmY0Y2M4MjktZTI1ZC00OTUwLWI0NmYtNDVlZWY0ZGFjZjg5IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  instafinanceguid:
    'https://app.powerbi.com/view?r=eyJrIjoiMjVkMDRiMjMtZGRkNi00Mjg3LTkxNzMtMDUwMjQ0YWNiMjBhIiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  airtelzambia:
    'https://app.powerbi.com/view?r=eyJrIjoiZTVjYTZmM2UtZWRmNi00NzBmLTljMzktZTZjMDBlYjg0M2E3IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9',
  airtelmalawiguid:
    'https://app.powerbi.com/view?r=eyJrIjoiMTAxNTQ0NWItOTgyMC00MDM3LTk4MDUtMzBhZTVkMTI0NDE4IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9', airtelkeguid:
    'https://app.powerbi.com/view?r=eyJrIjoiNzRjYzRlNTctZjlkOS00NjM5LWFmMjgtY2IwN2ExODRmYWI1IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9'

};

export function getDashboardURI() {
  const enviroment = getEnvironmentInformation();
  if (enviroment && enviroment.servicesURLs.enviromentType === 'prod') {
    const partnerguid = getPartnerGuid();
    if (!partnerguid) {
      return null;
    }
    const URI = dashboardURI[partnerguid];
    return URI;
  }

  return 'https://app.powerbi.com/view?r=eyJrIjoiMjcyY2QwNmEtYjk2MS00Y2Y1LTg3NTYtZDU4ODRiYjE0Njk0IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9'
}

export function getInternalDashboard() {
  return 'https://app.powerbi.com/view?r=eyJrIjoiMjcyY2QwNmEtYjk2MS00Y2Y1LTg3NTYtZDU4ODRiYjE0Njk0IiwidCI6ImIxZjdhZmRkLWY1MTMtNDhhNC04MTYwLTBkMTE1MjRlMjdiNyIsImMiOjl9';
}

export const ignoreMaxDaysAllowedAfterDischargeOrDeath =
  'ignore_max_days_allowed_after_discharge_or_death';


export function getGroupMobisiteBaseURL() {
  return getEnvironmentInformation().servicesURLs.groupMobisite;
}


export function organiseProductsByTag(products) {
  const organisedProducts = {};

  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    const tag = product.tag || 'Default';

    if (organisedProducts[tag]) {
      organisedProducts[tag].push(product);
    } else {
      organisedProducts[tag] = [product];
    }
  }

  return organisedProducts;
}


export function getNextDayDate(endDate) {
  const currentDate = new Date(endDate);
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toISOString().substring(0, 10)
}

export const hasNuclearPremium = (product) =>  product && product.premiums && product.premiums.length > 0
export const hasNuclearBenefit = (product) => product && product.fixed_indemnities && product.fixed_indemnities.length > 0


export const extractMessageFromSmsPayload = (sms) => {
  const { text: message } = JSON.parse(sms.data).params;
  return message;
}


export const isPolicyWithSpecialChars = () => {
  return PARTNERS_WITH_POLICY_NUMBER_SPECIAL_CHARACTORS.includes(getPartnerGuid())
}

export const formatDateToReadable = (dateString) => {
  if (!dateString) return "N/A";
  
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
};