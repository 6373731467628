import React, { useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import AlertDialog from '../../../../../components/AlertDialog/index.es6';
import WarningDialog from './WarningDialog';
import { hasAuthourity } from '../../../../../lib/access.es6';
import { shouldShowWarning } from '../../../lib/helpers';
import { BUTTON_STYLES } from '../../../lib/constants';

const ApprovedPending = ({ claim, onStatusChange, labels }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pendingStatus, setPendingStatus] = useState(null);

  const handleStatusChange = async (targetStatus) => {
    if (shouldShowWarning(claim, targetStatus)) {
      setPendingStatus(targetStatus);
      setShowWarning(true);
      return;
    }

    await processStatusChange(targetStatus);
  };

  const processStatusChange = async (targetStatus) => {
    setIsSubmitting(true);
    try {
      await onStatusChange(targetStatus);
      setShowSuccess(true);
    } catch (error) {
      const message = error.response && error.response.data ? 
        error.response.data.message || error.response.data.error : 
        'Failed to update status';
      setErrorMessage(message);
      setShowError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleWarningConfirm = () => {
    setShowWarning(false);
    processStatusChange(pendingStatus);
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
          <ButtonInc
            onClick={() => handleStatusChange('Approved')}
            disabled={isSubmitting 
              // || !hasAuthourity('APPROVE_CLAIM')
            }
            variant="contained"
            style={BUTTON_STYLES.approve}
          >
            {/* {labels.confirmApprovalBtn} */}
            Confirm approval
          </ButtonInc>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
          <ButtonInc
            onClick={() => handleStatusChange('Decision_Pending')}
            disabled={isSubmitting || !hasAuthourity('REJECT_CLAIM')}
            variant="contained"
            style={BUTTON_STYLES.reject}
          >
            {labels.reverseClaimBtn}
          </ButtonInc>
        </Grid>
      </Grid>

      <AlertDialog
        custom
        show={isSubmitting}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText={'ok'}
        showCancel={false}
        showConfirm={false}
        cancelBtnText={'cancel'}
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Updating Status...</h2>
      </AlertDialog>

      <WarningDialog
        show={showWarning}
        onConfirm={handleWarningConfirm}
        onCancel={() => setShowWarning(false)}
        title="Warning"
        message={claim.customer_is_high_risk ? labels.highRiskCustomer : labels.approvedClaimWarning}
        confirmText={labels.proceed}
        cancelText={labels.cancel}
      />

      <AlertDialog
        success
        show={showSuccess}
        onConfirm={() => setShowSuccess(false)}
        confirmBtnText="OK"
        title="Success"
      >
        Status updated successfully
      </AlertDialog>

      <AlertDialog
        error
        show={showError}
        onConfirm={() => setShowError(false)}
        confirmBtnText="OK"
        title="Error"
      >
        {errorMessage}
      </AlertDialog>
    </>
  );
};

export default ApprovedPending;