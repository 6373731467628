import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  detailstitle: {
    "& h3": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.005em",
        color: "#000000",
        marginTop: "10px"
    }
},
leftChecklist: {
  
  "& span": {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    color: "#000000",
  }, 
},
rightChecklist: {
  "& span": {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    color: "#000000",
  }, 
}
}));