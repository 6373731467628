import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
    addonlable:{
   
        "& label":{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000"
        }
    },
    detailstitle: {
        "& h3": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000",
            marginTop: "10px"
        }
    },
    benefitstitle:{
        marginTop: "20px",
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000"
        }
    },
    quoteinputwrapper: {
        "& p": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            textAlign: "center",
            marginBottom: "30px"
        },
        "& input": {
            borderRadius: "8px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0.005em",
            color: "#000000",
        },
        "& label": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            color: "#000000",
            transformOrigin: "bottom",
        }
    },
    benefitswrapper:{
        "& label": {
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "12px",
            color: "#000000",
            transformOrigin: "left",

        },
    },
    quotesbtns:{
      "& button:nth-child(1)":{
          background: "#032A37",
          color: "#fff !important",
          borderRadius: "8px",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          boxShadow: "none",
          padding: "10px",
          textTransform: "capitalize",
          marginBottom: "30px",
          marginRight: '-2%',
          "&:hover": {
              background: "#032A37",
              boxShadow: "none",
          }
        },
        "& button:nth-child(2)":{
          background: "#fba92c",
          color: "#fff !important",
          borderRadius: "8px",
          fontFamily: "Open Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "15px",
          boxShadow: "none",
          padding: "10px",
          textTransform: "capitalize",
          marginBottom: "30px",
          marginLeft: "30px",
          "&:hover": {
              background: "#fba92c",
              boxShadow: "none",
          }
        }
  },
  preexistingcondition:{
    display: "flex",
    paddingTop: "20px",
    width: "50%",
    marginLeft: "auto",
    "& input":{
        marginRight: "5px",
        marginTop: "-5px",
    },
    "& label":{
        fontFamily: "open sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
    }
  },
  child_still_in_chool:{
    display: "flex",
    paddingBottom: "20px",
    marginLeft: "auto",
    "& input":{
        marginRight: "5px",
        marginTop: "-5px",
    },
    "& label":{
        fontFamily: "open sans",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
    }
  },
  dependantWrapper:{
   
    "& table": {
        width: "100%",
        background: "#FFFFFF",
        border: "1px solid #A7A7A7",
        marginBottom: "20px",
        "& tr": {
            borderTop: "1px solid #A7A7A7",
        },
        "& th": {
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",
        },
        "& td": {
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            padding: "10px",
            borderRight: "1px solid #A7A7A7",

        }
    }
  },
  totalpremiums:{
    "& ul":{
        listStyle: "none",
        display: "flex",
        float: "right",
        "& li":{
            display: "inline-block",
            fontFamily: "open sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            
        }
    }
  },
  vehicleTypeCheck: {
  
    "& span": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
    }, 
},
}));