import React, { useState } from 'react'
import { useStyle } from "./Style";
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import ModalInc from '../../../../../shared-ui/ModalInc';
import { getS3BucketSignedUrlForBulkPolicyOnboarding } from '../../../../../lib/requests.es6';
import ButtonInc from '../../../../../shared-ui/ButtonInc';
import ProgressState from '../ProgressState/ProgressState';
import { getPartnerGuid, getUserFullname, getUserGuid } from '../../../../../lib/access.es6';
import MenuItem from '@mui/material/MenuItem';

const Upload = (props) => {
  const classes = useStyle();
  const [s3Url] = useState('https://bulk-policy-onboarding.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [channel, setChannel] = useState('');
  const [showChannelError, setShowChannelError] = useState(false);

  const handleFinishedUpload = (info) => {
    const payload = {
      "partner": getPartnerGuid(),
      "bucketKey": info.bucketKey,
      "web_agent_guid": getUserGuid(),
      "web_agent_name": getUserFullname(),
      "channel": channel
    }
    props.saveBulkPolicyOnboarding(payload);
  }

  const handleChannelChange = (event) => {
    setChannel(event.target.value);
    setShowChannelError(false);  // Clear error when channel is selected
  };

  const getS3Ur = (file, callback) => {
    if (!channel) {
      setShowChannelError(true);
      return;
    }
    getS3BucketSignedUrlForBulkPolicyOnboarding(file, callback);
  }

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),
  };

  const reset = () => {
    setProgress(0);
    setChannel('');
    setShowChannelError(false);
    props.close();
    window.location.reload();
  }

  return (
    <>
      <ModalInc
        modalTitle="Upload file"
        subTitle="Fill the below fields to upload files"
        open={props.open}
        onClose={props.close}
        fullWidth
      >
        <ValidatorForm onSubmit={() => {}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <SelectValidator
                fullWidth
                label="Upload Channel"
                name="channel"
                value={channel}
                onChange={handleChannelChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select a channel']}
                error={showChannelError}
                helperText={showChannelError ? "Please select a channel before uploading" : ""}
                style={{ marginBottom: "20px" }}
              >
                <MenuItem value="">Please select</MenuItem>
                <MenuItem value="ai-bulk">AI Bulk</MenuItem>
                <MenuItem value="normal-bulk">Normal Bulk</MenuItem>
              </SelectValidator>

              <div className={classes.uploadsection}>
                <DropzoneS3Uploader
                  onFinish={handleFinishedUpload}
                  upload={uploadOptions}
                  s3Url={s3Url}
                  passChildrenProps={false}
                  maxSize={1024 * 1024 * 500}
                  className={classes.uploadzone}
                  style={{
                    width: "100%",
                    height: "250px",
                    background: "#FFFFFF",
                    border: "1px dashed #B8B8B8",
                    marginTop: "40px",
                    padding: " 70px 0"
                  }}
                >
                  <div>
                    <ProgressState progress={progress} errorMessage={errorMessage} />
                  </div>
                </DropzoneS3Uploader>
              </div>
            </Grid>
            {props.errror && <p style={{color: 'red'}}>{props.errror.message}</p>}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} style={{textAlign: 'center'}}>
                  <ButtonInc
                    size="large"
                    onClick={reset}
                    type="submit"
                    variant="contained"
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%'}}
                  > I'm Done</ButtonInc>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={props.close}
                    style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%'}}
                  >
                    Cancel
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </>
  )
}

export default Upload;