import React, { useState } from 'react'
import { useStyle } from "./Style";
import { hashHistory } from 'react-router';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomerDetails from './Components/CustomerDetails/CustomerDetails';
import Policies from './Components/Policies/Policies'
import Payments from './Components/Payments/Payments';
import Claims from './Components/Claims/Claims';
import Dependants from './Components/DependantsMobile/Dependants';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';

import BuyPolicyMobile from './Components/BuyPolicyMobile/BuyPolicyMobile';
import RegisterCustomer from './Components/RegisterCustomer/index.es6'
import AddDependantMobile from './Components/DependantsMobile/AddDependantMobile';

const pages = [
  {
    name: "CustomerDetails",
    label: "Customer Details",
    url: "admin/customer_status"
  },

  {
    name: "Policies",
    label: "Policies",
    url: "admin/policies"
  },
  {
    name: "Dependants",
    label: "Dependants",
    url: "admin/dependants"
  },
  {
    name: "Payments",
    label: "Payments",
    url: "admin/payments"
  },
  {
    name: "Claims",
    label: "Claims",
    url: "admin/claims"
  },

]

const actions = [

  { icon: <InventoryOutlinedIcon sx={{ color: "#fff" }} fontSize='10px' />, name: 'Make a Claim' },
  { icon: <SourceOutlinedIcon sx={{ color: "#fff" }} fontSize='10px' />, name: 'Buy a Policy' },
  { icon: <FamilyRestroomOutlinedIcon sx={{ color: "#fff" }} fontSize='10px' />, name: 'Add  Dependant' },
  { icon: <HowToRegOutlinedIcon sx={{ color: "#fff" }} fontSize='10px' />, name: 'Register a Customer' },
];

const MobileCustomerProfileInc = (props) => {
  const classes = useStyle();


  const [pageSelect, setSelectPage] = React.useState('CustomerDetails');
  const [open, setOpen] = React.useState(false);
  const [showBuyPolicyForm, setShowBuyPolicyForm] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showAddDependantForm, setShowDependantForm] = useState(false)


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleChange = (event) => {
    setSelectPage(event.target.value);

  };

  const toggleBuyPolicyForm = () => {
    setShowBuyPolicyForm(prevState => !prevState);  // Toggle the form state
  };

  const toggleRegisterForm = () => {
    setShowRegisterForm(prevState => !prevState);
  }

  const toggleAdDependantForm = () => {
    setShowDependantForm(prevState => !prevState);
  }

  const handleMakeAClaim = (guid) => {
    //hashHistory.push(`admin/claim-initiation-mobile/${props.customerData.guid}`);
    hashHistory.push(`admin/claim-initiation-mobile/${guid}`);
  }

  const handleActionClick = (actionName) => {
    if (actionName === 'Register a Customer') {
      setShowRegisterForm(true);
    }

    if (actionName === 'Buy a Policy') {
      setShowBuyPolicyForm(true);
    }
    if (actionName === 'Add  Dependant') {
      setShowDependantForm(true);
    }
    if (actionName === 'Make a Claim') {
      handleMakeAClaim(props.customerData.currentCustomer.guid)
    }
    setOpen(false); // Close the SpeedDial after clicking
  };


  const renderContent = () => {
    switch (pageSelect) {
      case 'CustomerDetails':
        return <CustomerDetails {...props} />;
      case 'Policies':
        return <Policies {...props} />;
      case 'Dependants':
        return <Dependants {...props} />;
      case 'Payments':
        return <Payments {...props} />;

      case 'Claims':
        return <Claims {...props} />;
      default:
        return <div>Customer details default</div>;
    }
  }


  return (
    <>
      <section className={classes.navigationWrapper}>
        <Grid container spacing={2} >
          <Grid item xs={12} >

            <Box className={classes.navigation}>
              <FormControl fullWidth >
                <InputLabel id="select-page">Select Page</InputLabel>
                <Select
                  labelId="select-page"
                  id="select-page"
                  value={pageSelect}
                  label="Select Page"
                  onChange={handleChange}
                  sx={{
                    height: "50px",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#000000",
                  }}
                >
                  {
                    pages.map((page, index) => (<MenuItem style={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#000000",
                    }} key={index} value={page.name}>{page.label}</MenuItem>))
                  }
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </section>

      {
        pageSelect && renderContent()
      }


      {/* Conditionally render the BuyPolicyMobile component */}
      {showBuyPolicyForm && <BuyPolicyMobile showBuyPolicyForm={showBuyPolicyForm} toggleBuyPolicyForm={toggleBuyPolicyForm} getlistPaidProductsRequest={props.getlistPaidProductsRequest} policyPurchaseData={props.policyPurchaseData} customerData={props.customerData} buyMobilePolicy={props.buyMobilePolicy} products={props.products} resetPolicyPurchaseMobile={props.resetPolicyPurchaseMobile} setSelectPage={setSelectPage} setShowBuyPolicyForm={setShowBuyPolicyForm} />}


      {
        showRegisterForm && <RegisterCustomer showRegisterForm={showRegisterForm} toggleRegisterForm={toggleRegisterForm} />
      }
      {
        showAddDependantForm && <AddDependantMobile
          showAddDependantForm={showAddDependantForm}
          toggleAdDependantForm={toggleAdDependantForm}
          customerGuid={props.customerData.currentCustomer.guid}
          saveDependants={props.saveDependants}
          setSelectPage={setSelectPage} 
        

          showAddDependantProgressAlert={props.showAddDependantProgressAlert}
          showAddDependantSuccessAlert={props.showAddDependantSuccessAlert}
          showAddDependantErrorAlert={props.showAddDependantErrorAlert}
          resetAddDependantSuccessAlert={props.resetAddDependantSuccessAlert}
          resetAddDependantErrorAlert={props.resetAddDependantErrorAlert}
          createDependantError={props.createDependantError}
        />
      }


      <Box sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1000,
        transform: 'translateZ(0px)',
        flexGrow: 1

      }}
        className={classes.actionFab}>
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          // sx={{ position: 'fixed', bottom: 16, right: 16, }}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              // onClick={handleClose}
              onClick={() => handleActionClick(action.name)} // Handle action click


            />
          ))}
        </SpeedDial>
      </Box>


    </>
  )
}

export default MobileCustomerProfileInc