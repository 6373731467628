import React from 'react';
import { connect } from 'react-redux';
import Paper from 'material-ui/Paper';
import LocalizedStrings from 'react-localization';
import * as searchActions from '../Search/actions.es6';
import { getCustomer, makeCurrentCustomer } from '../Customer/actions.es6';
import * as globalActions from '../Main/actions.es6';
import Policies from '../../components/Policies/index.es6';
import Premiums from '../../components/Premiums/index.es6';
import Claims from '../../components/Claims/index.es6';
import { updateCurrentLanguage } from '../../lib/languageUtils.es6';
import { localisedText } from '../../lib/localisation.es6';
import { deletePolicyRequest, getPolicyProductRequest, initiatePolicyMobileMoneyPaymentRequest, resetMobileMoneyInitiation } from '../PolicyList/actions.es6';
import { deletePremiumRequest } from '../CustomerStatus/actions.es6';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AppBar, Box, Button, CircularProgress, MenuItem, Menu, Stack, Tab, Tabs, Typography, Grid } from '@mui/material';
import Customer from '../Customer/index.es6';
import { PRIMARY_THEME_COLOR_SHADE_2, SECONDARY_THEME_COLOR_SHADE_1, SECONDARY_THEME_COLOR_SHADE_2 } from '../../lib/constants';
import Backdrop from '@mui/material/Backdrop';
import './style.css';
import RegisterCustomer from './components/RegisterCustomer/index.es6';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Avatar from '@mui/material/Avatar';
import { Dependants } from '../../components/Dependants/index.es6';
import { EditCustomer } from './components/EditCustomer/index.es6';
import { BuyPolicy } from './components/BuyPolicy/index.es6';
import MakeClaim from './components/MakeClaim/index.es6';
import { Payments } from './components/Payments/index.es6';
import { getPartner, getPartnerGuid, isSouthAfricanPartner, partnerHasDebitOrder } from '../../lib/access.es6';
import AlertDialog from '../../components/AlertDialog/index.es6';
import * as dependantActions from "../DependantsList/actions.es6";
import * as actions from '../BeneficiaryList/actions'
import * as beneficiaryBankDetailsActions from "../BeneficiaryBankDetailsList/actions"
import { CustomerDocuments } from "../../components/CustomerDocuments/index.es6";
import * as documentActions from "../CustomerFiles/actions.es6";
import DebitOrder from './components/DebitOrder';
import * as userActions from '../Users/actions.es6';
import { getAllCustomerClaimsRequest, getAllMandatesRequest, getAllPaymentsRequest, getAllPoliciesRequest, getAllPremiumsRequest, getAllProductsRequest } from './actions.es6';
import BeneficiaryBankDetailsList from '../BeneficiaryBankDetailsList';
import CustomerBankDetailsList from '../CustomerBankDetailsList';
import BeneficiaryList from '../BeneficiaryList';
import SmsActivity from '../../partner-apps/Konyana/CustomerStatus/Components/SmsActivity/SmsActivity';
import { getSmsActivityRequest } from '../../partner-apps/Konyana/CustomerStatus/Redux/actions';
import Mandates from './components/Mandates/Mandates';
import CustomerStatusHeader from './components/CustomerStatusHeader';
import DesktopCustomerStatusSimple from '../../components/DesktopCustomerStatusSimple/DesktopCustomerStatusSimple';
import MobileCustomerStatus from '../../components/MobileCustomerStatus/MobileCustomerStatus';
import { listPaidProductsRequest, purchasePolicyRequest, resetPolicyPurchase } from '../BuyPolicy/actions.es6';
import { getExtendedFamilyProductConfigsRequest } from '../NewProductList/actions.es6';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


class CustomerScreen extends React.Component {
  constructor(props) {
    super(props);
    const localizedStrings = new LocalizedStrings(localisedText);
    this.state = {
      labels: localizedStrings,
      value: 0,
      open: false,
      openRegistrationDialog: false,
      openEditCustomerDialog: false,
      openBuyPolicyDialog: false,
      openMakeClaimDialog: false,
      anchorEl: null,
      firstTimeCheck: true,
      beneficiary_guid: null,
      componentLoadedWithoutCustomerGuid: false,
      componentLoadedWithMsisdn: false,

    };
  }

  componentWillMount() {

    this.updateLanguage();
    this.props.dispatch(globalActions.hideCustomerBar());
    this.props.dispatch(getAllProductsRequest())

    // this.props.dispatch(getAllMandatesRequest("233244860672"))


    let customer_guid = this.props.params.customer_guid

    if (customer_guid) {
      this.refreshCustomerStatus(customer_guid);
    } else {
      this.setState({ componentLoadedWithoutCustomerGuid: true })
    }
  }


  componentDidUpdate() {
    if (this.state.componentLoadedWithoutCustomerGuid) {
      let customer_guid = this.props.params.customer_guid
      if (customer_guid) {
        this.refreshCustomerStatus(customer_guid);
        this.setState({ componentLoadedWithoutCustomerGuid: false })
      }
    }

    this.updateLanguage();

    const customer = this.props.customerData.currentCustomer;

    if (this.state.firstTimeCheck && customer) {
      this.getPolicies()
      this.setState({ firstTimeCheck: false })
    }

    const componentLoadedWithMsisdn = this.state.componentLoadedWithMsisdn;

    if (customer && !componentLoadedWithMsisdn) {
      this.refreshCustomerStatusWithMsisdn(customer.msisdn)
      this.setState({ componentLoadedWithMsisdn: true })
    }
  }

  getMandates(msisdn) {
    this.props.dispatch(getAllMandatesRequest(msisdn))

  }

  refreshCustomerStatus(customer_guid) {
    this.props.dispatch(getCustomer(customer_guid));
    this.props.dispatch(dependantActions.getCustomerDependantsRequest(customer_guid));
    this.props.dispatch(documentActions.getCustomerDocumentsRequest(customer_guid));
    this.props.dispatch(getAllPoliciesRequest({ guid: customer_guid }))
    this.props.dispatch(getAllCustomerClaimsRequest(customer_guid))
  }

  refreshCustomerStatusWithMsisdn(msisdn) {
    this.props.dispatch(getSmsActivityRequest(msisdn));
  }

  componentDidMount() {
    const currentCustomer = this.props.customerData.currentCustomer;
    //this.getMandates();
    if (currentCustomer) {
      // this.props.dispatch(getAllMandatesRequest("233244860672"));
      this.props.dispatch(dependantActions.getCustomerDependantsRequest(currentCustomer.guid));
      this.props.dispatch(actions.getCustomerBeneficiaryRequest(currentCustomer.guid));
      this.props.dispatch(beneficiaryBankDetailsActions.getCustomerBeneficiaryBankDetailsRequest(currentCustomer.guid))

      this.props.dispatch(documentActions.getCustomerDocumentsRequest(currentCustomer.guid));

      if (currentCustomer.web_agent_guid) {
        this.props.dispatch(userActions.getUserRequest(currentCustomer.web_agent_guid));
      }
      this.props.dispatch(getAllCustomerClaimsRequest(currentCustomer.msisdn))
    }

  }



  getPolicies() {
    const currentCustomer = this.props.customerData.currentCustomer;
    this.props.dispatch(getAllPoliciesRequest({ msisdn: currentCustomer.msisdn, guid: currentCustomer.guid }))
    this.props.dispatch(getAllProductsRequest())
  }

  getPayments() {
    const currentCustomer = this.props.customerData.currentCustomer;
    this.props.dispatch(getAllPaymentsRequest({ msisdn: currentCustomer.msisdn, guid: currentCustomer.guid }))
  }

  getPremiums() {
    const currentCustomer = this.props.customerData.currentCustomer;
    this.props.dispatch(getAllPremiumsRequest({ msisdn: currentCustomer.msisdn, guid: currentCustomer.guid }))
  }

  getClaims() {
    const currentCustomer = this.props.customerData.currentCustomer;
    this.props.dispatch(getAllCustomerClaimsRequest(currentCustomer.msisdn))
  }

  updateLanguage() {
    updateCurrentLanguage(
      this.props.globalData.language,
      'Customer',
      this.state.labels
    );
  }

  handleMenuOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose() {
    this.setState({ anchorEl: null });
  };

  handleTabChange(event, value) {
    this.setState({ value });
    this.refreshTabContent(value)
  }

  refreshTabContent(value) {
    const customerGuid = this.props.customerData.currentCustomer.guid;
    const currentCustomer = this.props.customerData.currentCustomer;

    const TAB_INDEXES = {
      DOCUMENTS_TAB: 1,
      CLAIMS_TAB: 5,
    }

    this.refreshCustomer(currentCustomer)
    if (value === TAB_INDEXES.DOCUMENTS_TAB) {
      this.props.dispatch(documentActions.getCustomerDocumentsRequest(customerGuid))
    }

    if (value === TAB_INDEXES.CLAIMS_TAB) {
      this.props.dispatch(getAllCustomerClaimsRequest(currentCustomer.msisdn))
    }
  }

  searchForCustomer(customerData) {
    this.props.dispatch(
      searchActions.searchCustomerByMsisdnRequest(customerData)
    );
  }

  handleSearchClose() {
    this.props.dispatch(searchActions.resetSearchResults());
  }

  selectCustomer(customer) {
    this.props.dispatch(makeCurrentCustomer(customer));
  }

  deletePolicy(policy) {
    policy['customer_msisdn'] = this.props.customerData.currentCustomer.msisdn
    this.props.dispatch(deletePolicyRequest(policy));
  }

  deletePremium(premium) {
    const payload = {
      premiumGuid: premium.guid,
      customerGuid: this.props.customerData.currentCustomer.guid,
    };
    this.props.dispatch(deletePremiumRequest(payload));
  }

  getTabStyle(tabValue) {
    const tabStyles = {
      default_tab: {
        color: 'whitesmoke',
      },
      active_tab: {
        color: "#fff",
        fontWeight: 600,
      }
    }

    const { value } = this.state;
    if (value === tabValue) {
      return tabStyles.active_tab
    } else {
      return tabStyles.default_tab
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }


  refreshCustomer(currentCustomer) {
    this.props.dispatch(
      getCustomer(currentCustomer.guid)
    );
    this.getPolicies()
  }

  toggleEditCustomerDialog() {
    this.setState({ openEditCustomerDialog: !this.state.openEditCustomerDialog })
    this.handleMenuClose()
  }

  toggleBuyPolicyDialog() {
    this.setState({ openBuyPolicyDialog: !this.state.openBuyPolicyDialog })
  }

  toggleMakeClaimDialog() {
    this.setState({ openMakeClaimDialog: !this.state.openMakeClaimDialog })
  }
  getBenefitLabel(benefit, labels) {
    switch (benefit.type.trim()) {
      case 'Hospital':
        return labels.nightlyBenefit;
      case 'Death':
        return labels.fixedBenefit;
      case 'Cashback':
        return labels.cashback;
      case 'Property':
        return labels.propertyAssistanceBenefit;
      case 'Income_Loss':
        return labels.incomeLossBenefit;
      case 'Personal_Accident':
        return labels.personalAccident;
      case 'Medical_Expense':
        return labels.medicalExpense;
      case 'Inpatient':
        return labels.inpatient;
      case 'Maternity':
        return labels.maternity;
      case 'Outpatient':
        return labels.outpatient;
      default:
        return benefit.type;
    }
  }
  getBenefits(policy) {
    const labels = this.state.labels;
    const currencyCode = getPartner().country.currency_code

    return (
      policy.benefits && (

        <>
          <Grid container spacing={2}>
            {policy.benefits.map((benefit, index) => (
              <>
                <Grid item xs={4}>

                  <strong style={{
                    fontFamily: 'Open Sans',
                    fontStyle: "normal",
                    //fontWeight: 400,
                    fontSize: "10px",
                    lineHeight: "16px",
                    letterSpacing: "0.005em",
                    // color: "#A9A9A9"
                  }} key={index} >{this.getBenefitLabel(benefit, labels)}:</strong>&nbsp;
                  {currencyCode} {benefit.amount.toLocaleString()}
                </Grid>

              </>
            ))}

          </Grid>

        </>
      )
    );
  }

  displayCustomerStatus() {
    const { labels, value } = this.state;
    const currentCustomer = this.props.customerData.currentCustomer;
    const customerFullname = currentCustomer ? currentCustomer.first_name + " " + currentCustomer.surname : null;
    const policies = this.props.customerScreenData.policies
    const partner = getPartner();
    const can_purchase_policy_via_ui = partner
      ? (partner.can_purchase_policy_via_ui !== undefined ? partner.can_purchase_policy_via_ui : true)
      : true

    const currenBeneficary = this.props.customerBeneficiary.beneficiary;

    const partnerGuid = getPartnerGuid();

    if (currentCustomer) {
      return (
        <div className="row" style={{ width: '100%', marginTop: "40px" }}>
          <div className="col-sm-12">
            <Paper style={{ width: '100%', minHeight: '700px' }}>
              <div className="row">
                <div className="col-md-6 col-sm-12 col-sx-12">
                  <div style={{ marginLeft: '2%', marginTop: '2%' }}>
                    <div>
                      <Stack direction="row" spacing={2}>
                        <Avatar
                          sx={{ bgcolor: SECONDARY_THEME_COLOR_SHADE_1 }}
                        >
                          {(customerFullname && customerFullname.length > 0) ? customerFullname[0] : null}
                        </Avatar>
                        <div>
                          <h3>{`${customerFullname || ''}   ${currentCustomer.msisdn}`}</h3>
                        </div>
                      </Stack>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-sx-12" >
                  <div style={{ marginTop: '3%' }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="flex-end" spacing={2} style={{ marginRight: '3%' }} >
                      {
                        can_purchase_policy_via_ui &&
                        <Button onClick={this.toggleBuyPolicyDialog.bind(this)} size="small" variant="contained" style={{ backgroundColor: SECONDARY_THEME_COLOR_SHADE_1 }} endIcon={<ShoppingCartIcon />}>
                          {labels.buyPolicy}
                        </Button>
                      }

                      <Button onClick={this.toggleMakeClaimDialog.bind(this)} size="small" variant="contained" style={{ backgroundColor: SECONDARY_THEME_COLOR_SHADE_1 }} startIcon={<NoteAltIcon />}>
                        {labels.makeAClaim}
                      </Button>

                      <MoreVertIcon
                        onClick={this.handleMenuOpen.bind(this)}
                        style={{ float: 'left' }}
                      />
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenuClose.bind(this)}
                      >
                        <MenuItem onClick={this.toggleEditCustomerDialog.bind(this)}>{labels.edit}</MenuItem>
                        <MenuItem onClick={this.refreshCustomer.bind(this, currentCustomer)}>{labels.refresh}</MenuItem>
                        <MenuItem onClick={this.toggleEditCustomerDialog.bind(this)}>{labels.loyaltyOpOut}</MenuItem>
                      </Menu>
                    </Stack>
                  </div>
                </div>
              </div>
              <hr />
              <AppBar position="static" style={{ backgroundColor: PRIMARY_THEME_COLOR_SHADE_2 }}>
                <Tabs
                  value={value}
                  onChange={this.handleTabChange.bind(this)}
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: SECONDARY_THEME_COLOR_SHADE_2
                    }
                  }}              >
                  <Tab label={labels.titleCustomerDetails} {...a11yProps(0)} style={this.getTabStyle(0)} />
                  <Tab label={labels.documents} {...a11yProps(3)} style={this.getTabStyle(1)} />

                  <Tab label={labels.titlePolicies} {...a11yProps(1)} style={this.getTabStyle(2)} />

                  <Tab label={labels.titlePayments} {...a11yProps(2)} style={this.getTabStyle(3)} />

                  <Tab label={labels.titlePremiums} {...a11yProps(2)} style={this.getTabStyle(4)} />

                  <Tab label={labels.claims} {...a11yProps(3)} style={this.getTabStyle(5)} />

                  {
                    partnerHasDebitOrder() &&
                    <Tab label={labels.debitOrder} {...a11yProps(4)} style={this.getTabStyle(6)} />
                  }
                  {
                    isSouthAfricanPartner() && <Tab label="Bank Details" {...a11yProps(7)} style={this.getTabStyle(7)} />
                  }

                  {
                    partnerGuid === "MMI_Ghana" && <Tab label="Mandates" {...a11yProps(6)} style={this.getTabStyle(6)} />
                  }

                </Tabs>
              </AppBar>

              <TabPanel value={value} index={0}>
                {
                  currentCustomer &&
                  <div style={{ paddingBottom: '5%' }}>
                    <Customer currentCustomer={currentCustomer} />
                    {isSouthAfricanPartner() && <BeneficiaryList customerGuid={currentCustomer.guid} />}
                    {isSouthAfricanPartner() && currenBeneficary && <BeneficiaryBankDetailsList />}
                    <SmsActivity smses={this.props.customerStatusKonyanaData.smsActivities} simple={true} />
                    <Dependants
                      dependants={this.props.customerDependants.dependants}
                      showMore
                      customerGuid={currentCustomer.guid}
                      globalData={this.props.globalData}
                      onSave={data => this.props.dispatch(dependantActions.addCustomerDependantRequest({ ...data, customer_guid: currentCustomer.guid }))}
                      onUpdate={data => this.props.dispatch(dependantActions.updateCustomerDependantRequest({ ...data, customer_guid: currentCustomer.guid }))}
                      onDelete={data => this.props.dispatch(dependantActions.deleteCustomerDependantRequest({ guid: data, customer_guid: currentCustomer.guid }))}
                      extendedProductConfigRelationships={this.props.productsData.extendedFamilyProductConfigs
                        ? this.props.productsData.extendedFamilyProductConfigs.nuclear_restricted_relationships
                        : []}

                      showAddDependantProgressAlert={this.props.customerDependants.createDependantLoader}
                      showAddDependantSuccessAlert={this.props.customerDependants.createDependantSuccess}
                      showAddDependantErrorAlert={!!this.props.customerDependants.createDependantError}
                      resetAddDependantSuccessAlert={() => { this.props.dispatch(dependantActions.resetAddCustomerDependantSuccess()) }}
                      resetAddDependantErrorAlert={() => { this.props.dispatch(dependantActions.resetAddCustomerDependantFailed()) }}
                      createDependantError={this.props.customerDependants.createDependantError}


                      showEditDependantProgressAlert={this.props.customerDependants.editDependantLoader}
                      showEditDependantSuccessAlert={this.props.customerDependants.editDependantSuccess}
                      showEditDependantErrorAlert={!!this.props.customerDependants.editDependantError}
                      resetEditDependantSuccessAlert={() => { this.props.dispatch(dependantActions.resetEditCustomerDependantSuccess()) }}
                      resetEditDependantErrorAlert={() => { this.props.dispatch(dependantActions.resetEditCustomerDependantFailed()) }}
                      editDependantError={this.props.customerDependants.editDependantError}

                    />
                  </div>
                }
              </TabPanel>

              <TabPanel value={value} index={1}>
                <CustomerDocuments
                  documents={this.props.customerDocuments.documents}
                  customerGuid={currentCustomer.guid}
                  globalData={this.props.globalData}
                  onSave={data => this.props.dispatch(documentActions.addCustomerDocumentRequest({ ...data, customer_guid: currentCustomer.guid }))}
                  onDelete={data => this.props.dispatch(documentActions.deleteCustomerDocumentRequest(data))}
                  fileTypeEnums={{
                    BirthCertificate: 'BirthCertificate',
                    NationalIdentity: 'NationalIdentity',
                    Other: "Other",
                  }}
                  s3BucketUrl='https://customers-document-received.s3.amazonaws.com'
                  documentsFor='customer'
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Policies
                  policies={policies}
                  showMore={true}
                  deletePolicyHandler={this.deletePolicy.bind(this)}
                  customerGuid={currentCustomer.guid}
                  msisdn={currentCustomer.msisdn}
                  getPolicies={this.getPolicies.bind(this)}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Payments
                  columnWidth="col-md-12"
                  payments={this.props.customerScreenData.payments}
                  getPayments={this.getPayments.bind(this)}
                  showMore={true}
                  globalData={this.props.globalData}
                  dispatch={this.props.dispatch}
                  statusData={this.props.statusData}
                  customerData={this.props.customerData}
                  policies={policies}
                />

              </TabPanel>
              <TabPanel value={value} index={4}>
                <Premiums
                  columnWidth="col-md-12"
                  showMore={true}
                  deletePremiumHandler={this.deletePremium.bind(this)}
                  premiums={this.props.customerScreenData.premiums}
                  getPremiums={this.getPremiums.bind(this)}
                />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <div style={{ overflowY: 'scroll' }}>
                  <Claims
                    columnWidth="col-md-12"
                    showMore={true}
                    claims={this.props.customerScreenData.claims}
                    extended={true}
                  />
                </div>
              </TabPanel>

              {partnerHasDebitOrder() && (
                <TabPanel value={value} index={6}>

                  <DebitOrder
                    newDebitOrderData={this.props.newDebitOrderData}
                    currentCustomerData={this.props.customerData}
                    dispatch={this.props.dispatch}
                    policies={policies}
                    products={this.props.customerScreenData.products}
                    totalPayablePremium={this.props.customerScreenData.total_payable_premium}
                  />
                </TabPanel>
              )}

              <TabPanel value={value} index={7}>
                <div style={{ overflowY: 'scroll' }}>
                  {
                    <CustomerBankDetailsList
                      customerGuid={currentCustomer.guid}
                    />
                  }

                </div>
              </TabPanel>

              {
                partnerGuid === "MMI_Ghana" && (
                  <TabPanel value={value} index={6}>
                    <Mandates
                      msisdn={currentCustomer.msisdn}
                      getMandates={this.getMandates.bind(this)}
                      mandates={this.props.customerScreenData.mandates}
                      mandatesLoader={this.props.customerScreenData.get_mandates_loader}


                    />


                  </TabPanel>

                )
              }


            </Paper>
          </div>
          <Backdrop open={this.state.open} />
        </div >
      );
    }
  }

  getlistPaidProductsRequest() {
    if (this.props.policyPurchaseData.products.length < 1) {
      this.props.dispatch(listPaidProductsRequest());
    }
  }

  buyMobilePolicy(purchaseData) {
    this.props.dispatch(purchasePolicyRequest(purchaseData));
  }
  resetPolicyPurchaseMobile() {
    this.props.dispatch(resetPolicyPurchase());
  }

  getPolicyProductMobile(policy) {
    const productGuid = policy.product_guid;
    this.props.dispatch(getPolicyProductRequest(productGuid));
    this.props.dispatch(getExtendedFamilyProductConfigsRequest(productGuid));
  }
  initiatePaymentMobile(payload) {
    this.props.dispatch(initiatePolicyMobileMoneyPaymentRequest(payload));
  }
  closeMobileMoneyConfirmation() {
    this.props.dispatch(resetMobileMoneyInitiation());
  }




  render() {
    return (
      <div>
        <div className="row"  >

          <DesktopCustomerStatusSimple >
            <RegisterCustomer
              globalData={this.props.globalData}
              dispatch={this.props.dispatch}
              registrationData={this.props.registrationData}
              openRegistrationDialog={this.state.openRegistrationDialog}
              closeRegistrationDialog={() => { this.setState({ openRegistrationDialog: false }) }}
            />

            <CustomerStatusHeader
              open={() => { this.setState({ openRegistrationDialog: true }) }}
            />


            {this.displayCustomerStatus()}

            <br />
          </DesktopCustomerStatusSimple>

          {
            this.props.customerData.currentCustomer && (
              <MobileCustomerStatus
                data={this.props.customerData.currentCustomer}
                customerGuid={this.props.customerData.currentCustomer.guid}
                policies={this.props.customerScreenData.policies}
                policiesLoader={this.props.customerScreenData.get_policies_loader}
                getPolicies={this.getPolicies.bind(this)}
                getBenefits={this.getBenefits.bind(this)}
                payments={this.props.customerScreenData.payments}
                paymentsLoader={this.props.customerScreenData.get_payments_loader}
                getPayments={this.getPayments.bind(this)}
                claims={this.props.customerScreenData.claims}
                claimsLoader={this.props.customerScreenData.get_claims_loader}
                getClaims={this.getClaims.bind(this)}

                getlistPaidProductsRequest={this.getlistPaidProductsRequest.bind(this)}
                policyPurchaseData={this.props.policyPurchaseData}
                products={this.props.policyPurchaseData.products}
                customerData={this.props.customerData}
                buyMobilePolicy={this.buyMobilePolicy.bind(this)}
                resetPolicyPurchaseMobile={this.resetPolicyPurchaseMobile.bind(this)}

                getPolicyProductMobile={this.getPolicyProductMobile.bind(this)}
                productPremium={this.props.policyData.productPremium.premiums}
                popupLoader={this.props.policyData.productPremium.popupLoader}
                initiatePaymentMobile={this.initiatePaymentMobile.bind(this)}
                mobileMoneyTransaction={this.props.policyData.mobileMoneyTransaction}
                mobileMoneyTransactionErrors={this.props.policyData.errors}
                closeMobileMoneyConfirmation={this.closeMobileMoneyConfirmation.bind(this)}
                toggleEditCustomerDialog={this.toggleEditCustomerDialog.bind(this)}
                saveDependants={data => this.props.dispatch(dependantActions.addCustomerDependantRequest({ ...data, customer_guid: this.props.customerData.currentCustomer.guid }))}

                showAddDependantProgressAlert={this.props.customerDependants.createDependantLoader}
                showAddDependantSuccessAlert={this.props.customerDependants.createDependantSuccess}
                showAddDependantErrorAlert={!!this.props.customerDependants.createDependantError}
                resetAddDependantSuccessAlert={() => { this.props.dispatch(dependantActions.resetAddCustomerDependantSuccess()) }}
                resetAddDependantErrorAlert={() => { this.props.dispatch(dependantActions.resetAddCustomerDependantFailed()) }}
                createDependantError={this.props.customerDependants.createDependantError}
                dependants={this.props.customerDependants.dependants}
                dependantsLoader={this.props.customerDependants.loader}
                editDependant={data => this.props.dispatch(dependantActions.updateCustomerDependantRequest({ ...data, customer_guid: this.props.customerData.currentCustomer.guid }))}


                showEditDependantProgressAlert={this.props.customerDependants.editDependantLoader}
                showEditDependantSuccessAlert={this.props.customerDependants.editDependantSuccess}
                showEditDependantErrorAlert={!!this.props.customerDependants.editDependantError}
                resetEditDependantSuccessAlert={() => { this.props.dispatch(dependantActions.resetEditCustomerDependantSuccess()) }}
                resetEditDependantErrorAlert={() => { this.props.dispatch(dependantActions.resetEditCustomerDependantFailed()) }}
                editDependantError={this.props.customerDependants.editDependantError}

                DeleteDependant={data => this.props.dispatch(dependantActions.deleteCustomerDependantRequest({ guid: data, customer_guid:  this.props.customerData.currentCustomer.guid  }))}

              />
            )
          }






          <AlertDialog
            custom
            show={this.props.customerData.loader}
            size="sm"
            style={{ marginTop: '0', top: '30vh' }}
            confirmBtnText={'ok'}
            showCancel={false}
            showConfirm={false}
            cancelBtnText={'cancel'}
            showTitle={false}
            confirmBtnCssClass
          >
            <CircularProgress />
            <h2>Refreshing current customer details</h2>
          </AlertDialog>

          {
            this.props.customerData && this.props.customerData.currentCustomer && this.state.openEditCustomerDialog &&
            <EditCustomer
              currentCustomerData={this.props.customerData}
              closeEditCustomerDialog={this.toggleEditCustomerDialog.bind(this)}

              globalData={this.props.globalData}
              dispatch={this.props.dispatch}
            />
          }

          {
            this.props.customerData && this.props.customerData.currentCustomer && this.state.openBuyPolicyDialog &&
            <BuyPolicy
              globalData={this.props.globalData}
              dispatch={this.props.dispatch}
              registrationData={this.props.registrationData}
              closeBuyPolicyDialog={this.toggleBuyPolicyDialog.bind(this)}
              policyPurchaseData={this.props.policyPurchaseData}
              customerData={this.props.customerData}
            />
          }

          {
            this.props.customerData && this.props.customerData.currentCustomer && this.state.openMakeClaimDialog &&
            <MakeClaim
              claimRequestData={this.props.claimRequestData}
              currentCustomerData={this.props.customerData}
              globalData={this.props.globalData}
              dispatch={this.props.dispatch}
              toggleMakeClaimDialog={this.toggleMakeClaimDialog.bind(this)}
              getPolicies={this.getPolicies.bind(this)}
            />
          }
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  searchData: state.search,
  customerData: state.currentCustomer,
  statusData: state.customerStatus,
  globalData: state.global,
  registrationData: state.customerRegistration,
  policyPurchaseData: state.policyPurchase,
  policyData: state.policyList,
  claimRequestData: state.claimRequest,
  customerBeneficiary: state.customerBeneficiary,
  customerBeneficiarybankdetails: state.customerBeneficiarybankdetails,
  customerDependants: state.customerDependants,
  customerbankdetails: state.customerbankdetails,
  customerDocuments: state.customerDocuments,
  newDebitOrderData: state.newDebitOrder,
  customerScreenData: state.customerScreen,
  productsData: state.newProducts,
  customerStatusKonyanaData: state.customerStatusKonyanaState,
}))(CustomerScreen);
