export const motor_configs = [
  {
    "guid": "123112211-3344-1222",
    "code": "motor_comprehensive_ke",
    "name": "Motor Comprehensive",
    "premium_rates": [1, 2, 3, 4, 5, 6],
    "levies": [
      {
        "name": "Policyholder Compensation Fund (PCF)",
        "rate": 25
      },
      {
        "name": "IRA Levy",
        "rate": 2
      },
    ],

    "additional_benefits": [
      {
        "name": "Political violence and terrorism",
        "rate": 25
      },
      {
        "name": "Personal Accident",
        "rate": 4
      },
      {
        "name": "Courtesy Car",
        "rate": 30000
      }
    ],
    "duties":[
      {
        "name": "Stamp Duty",
        "rate": 40
      },
    ]
  },
  {
    "guid": "123662211-3399-1233",
    "code": "third_party_ke",
    "name": "Third Party",
    "premium_rates": [7500, 10000],
    "levies": [
      {
        "name": "Policyholder Compensation Fund (PCF)",
        "rate": 25
      },
      {
        "name": "IRA Levy",
        "rate": 2
      },
    ],
    "duties":[
      {
        "name": "Stamp Duty",
        "rate": 40
      },
    ]
  },

  
];

export const carsInKenya = [
  {
    make: "Toyota",
    models: [
      "Corolla (Axio, NZE)",
      "Premio",
      "Allion",
      "Harrier",
      "RAV4",
      "Hilux",
      "Land Cruiser (Prado, V8, 70 Series)",
      "Noah/Voxy",
      "Probox/Succeed"
    ]
  },
  {
    make: "Nissan",
    models: [
      "Note",
      "Tiida",
      "X-Trail",
      "Dualis",
      "Juke",
      "Serena",
      "Navara",
      "Patrol"
    ]
  },
  {
    make: "Honda",
    models: ["Fit", "Vezel", "CR-V", "Accord", "HR-V"]
  },
  {
    make: "Mazda",
    models: ["Demio", "Axela", "Atenza", "CX-5", "CX-3"]
  },
  {
    make: "Subaru",
    models: ["Impreza", "Legacy", "Outback", "Forester", "XV"]
  },
  {
    make: "Mitsubishi",
    models: ["Lancer", "Outlander", "Pajero", "Canter", "Colt"]
  },
  {
    make: "Volkswagen",
    models: ["Polo", "Passat", "Tiguan", "Golf", "Amarok"]
  },
  {
    make: "Hyundai",
    models: ["Tucson", "Creta", "Santa Fe", "Elantra"]
  },
  {
    make: "Kia",
    models: ["Picanto", "Rio", "Sportage", "Sorento"]
  },
  {
    make: "Isuzu",
    models: ["D-Max", "N-Series (trucks)", "FRR/FVR (buses)"]
  },
  {
    make: "Suzuki",
    models: ["Alto", "Swift", "Vitara", "Jimny"]
  },
  {
    make: "Land Rover",
    models: ["Defender", "Discovery", "Range Rover (Evoque, Sport, Velar)"]
  },
  {
    make: "Mercedes-Benz",
    models: ["C-Class", "E-Class", "GLC", "Actros (trucks)"]
  },
  {
    make: "Ford",
    models: ["Ranger", "Everest", "EcoSport"]
  }
];

export const RequiredDocs = [
  "Copy of National ID",
  "Copy of driving license",
  "Copy of log book",
  "Copy of valuation report"
]