import * as types from '../../../../lib/actionTypes.es6'

export const initialState = {
  quotes: [],
  errors: null,
  loader: false,

  createdDependant: null,
  createDependantSuccess: false,
  createDependantLoader: false,
  createDependantError: null,
  
  createdQuote: null,
  createQuoteSuccess: false,
  createQuoteLoader: false,
  createQuoteError: {
    error: "this an error",
    message: "hello"
  },

  buyPolicy: null,
  buyPolicySuccess: false,
  buyPolicyLoader: false,
  buyPolicyError: null,


  changeQuote: null,
  changeQuoteSuccess: false,
  changeQuoteLoader: false,
  changeQuoteError: null,
  quoteToReCreate: null,


  get_email_template_by_guid_error: null,
  get_email_template_by_guid_loader: false,
  template: null,

  sendEmail: null,
  sendEmailSuccess: false,
  sendEmailLoader: false,
  sendEmailError: null,

};

export default function IncMotorquoteReducer(state = initialState, action) {
  switch (action.type) {

    case types.CREATE_DEPENDANT_REQUEST:
      return {
        ...state,
        createDependantError: null,
        createDependantLoader: true,
        createDependantSuccess: true,
        createdDependant: action.payload
      };

    case types.CREATE_DEPENDANT_SUCCESS:
      return {
        ...state,
        createdDependant: action.payload,
        createDependantSuccess: true,
        createDependantLoader: false,
        createDependantError: null,
      };


    case types.CREATE_DEPENDANT_FAILURE:
      return { ...state, createDependantLoader: false, createDependantError: action.payload };

    case types.RESET_CREATE_DEPENDANT_SUCCESS:
      return { ...state, createDependantSuccess: false };


    case types.CREATE_QUOTE_REQUEST:
      return {
        ...state,
        createQuoteError: null,
        createQuoteLoader: true,
        createQuoteSuccess: false,
        createdQuote: null
      };

    case types.CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        createdQuote: action.payload,
        createQuoteSuccess: true,
        createQuoteLoader: false,
        createQuoteError: null,
      };

    case types.CREATE_QUOTE_FAILURE:
      return { ...state, createQuoteLoader: false, createQuoteError: action.payload };

    case types.RESET_CREATE_QUOTE_SUCCESS:
      return { ...state, createQuoteSuccess: false };

    case types.RESET_CREATE_QUOTE_ERROR:
      return { ...state, createQuoteError: null };

    case types.VIEW_ALL_CUSTOMER_QUOTES_REQUEST:
      return { ...state, errors: null, loader: true, quotes: [] };

    case types.VIEW_ALL_CUSTOMER_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload,
        loader: false,
        errors: null,
      };

    case types.VIEW_CUSTOMER_QUOTES_FAILURE:
      return { ...state, loader: false, errors: action.payload };

    case types.BUY_POLICY_REQUEST:
      return {
        ...state,
        buyPolicyError: null,
        buyPolicyLoader: true,
        buyPolicySuccess: false,
        buyPolicy: null
      };

    case types.BUY_POLICY_SUCCESS:
      return {
        ...state,
        buyPolicy: action.payload,
        buyPolicySuccess: true,
        buyPolicyLoader: false,
        buyPolicyError: null,
      };

    case types.BUY_POLICY_FAILURE:
      return { ...state, buyPolicyLoader: false, buyPolicyError: action.payload };

    case types.RESET_BUY_POLICY_SUCCESS:
      return { ...state, buyPolicySuccess: false };

    case types.RESET_BUY_POLICY_ERROR:
      return { ...state, buyPolicyError: null };


    case types.CHANGE_QUOTE_REQUEST:
      return {
        ...state,
        changeQuoteError: null,
        changeQuoteLoader: true,
        changeQuoteSuccess: false,
        changeQuote: null
      };

    case types.CHANGE_QUOTE_SUCCESS:
      return {
        ...state,
        changeQuote: action.payload,
        changeQuoteSuccess: true,
        changeQuoteLoader: false,
        changeQuoteError: null,
      };

    case types.CHANGE_QUOTE_FAILURE:
      return { ...state, changeQuoteLoader: false, changeQuoteError: action.payload };

    case types.RESET_CHANGE_QUOTE_SUCCESS:
      return { ...state, changeQuoteSuccess: false };

    case types.RESET_CHANGE_QUOTE_ERROR:
      return { ...state, changeQuoteError: null };

    case types.SET_QUOTATION_TO_RECREATE:
      return { ...state, quoteToReCreate: action.payload };


      case types.GET_EMAIL_TEMPLATE_BY_GUID_REQUEST:
        return { ...state, get_email_template_by_guid_loader: true, get_email_template_by_guid_error: null };
      case types.GET_EMAIL_TEMPLATE_BY_GUID_SUCCESS:
        return { ...state, get_email_template_by_guid_loader: false, get_email_template_by_guid_error: null, template: action.payload };
      case types.GET_EMAIL_TEMPLATE_BY_GUID_FAILURE:
        return { ...state, get_email_template_by_guid_loader: false, get_email_template_by_guid_error: action.payload };


        case types.SEND_EMAIL_REQUEST:
          return {
            ...state,
            sendEmailError: null,
            sendEmailLoader: true,
            sendEmailSuccess: false,
            sendEmail: null
          };
    
        case types.SEND_EMAIL_SUCCESS:
          return {
            ...state,
            sendEmail: action.payload,
            sendEmailSuccess: true,
            sendEmailLoader: false,
            sendEmailError: null,
          };
    
        case types.SEND_EMAIL_FAILURE:
          return { ...state, sendEmailLoader: false, sendEmailError: action.payload };

    default:
      return state;
  }
}
