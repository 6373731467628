import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { useStyle } from "./Style";
import DropzoneS3Uploader from "react-dropzone-s3-uploader";
import { getS3BucketSignedUrlForPolicyDcpDocuments } from '../../../../../lib/requests.es6';
import ProgressState from './ProgressState';
import MenuItem from '@mui/material/MenuItem';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';

const fileTypeEnums = [
  'Copy of National ID',
  'Copy of driving license',
  'Copy of log book',
  'Copy of valuation report'
]
const UploadDocument = (props) => {
  const classes = useStyle();

  const [docType, setDocType] = useState(fileTypeEnums[0]);
  const [docTypeError, setDocTypeError] = useState(false)
  const [s3Url] = useState('https://policy-onboarding-documents.s3.amazonaws.com');
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(()=> {
    props.resetDebitOrdersDcp()
  }, [])

  const handleFinishedUpload = (info) => {

    if (docType === "") {
      setDocTypeError(true)
    } else {

      const payload = {
        policy_guid: props.policy.guid,
        description: docType,
        filename: docType,
        url: info.fileUrl.replace('undefined', info.bucketKey),
      }
      console.log("payload", payload)
      props.DocumentUpload(payload);
      setDocType('');
    }

  }

  const getS3Ur = (file, callback) => getS3BucketSignedUrlForPolicyDcpDocuments(props.policy.guid, file, callback)

  const uploadOptions = {
    getSignedUrl: getS3Ur,
    s3Url: s3Url,
    uploadRequestHeaders: {},
    onProgress: (progress) => setProgress(progress),
    onError: (errorMessage) => setErrorMessage(errorMessage),

  };

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === "docsdescription") {
      setDocType(value);
    }

  }
  // const reset = () => {
  //   if (docType === "") {
  //     setDocTypeError(true)
  //   } else {
  //     setDocType('');
  //     setProgress(0)
  //   }

  // }

  return (
    <>

      <ValidatorForm onSubmit={() => { }} >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>

            <SelectValidator
              labelId="docType"
              id="docType"
              value={docType}
              name='docsdescription'
              type="text"
              label="Document Type"
              onChange={handleChange}
              variant="outlined"
              validators={['required']}
              errorMessages={['Please select gender']}
              style={{ width: "100%" }}
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 300,
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.005em",
                color: "#000000",
              }}
            >
              {
                fileTypeEnums.map((type, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }} value={type}>{type}</MenuItem>))
              }

            </SelectValidator>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={classes.uploadsection}>
              {docTypeError && <p style={{ color: 'red' }}>Please Choose document type</p>}
              <DropzoneS3Uploader
                onFinish={handleFinishedUpload}
                upload={uploadOptions}
                s3Url={s3Url}
                passChildrenProps={false}
                maxSize={1024 * 1024 * 5}
                className={classes.uploadzone}
                {...(!setDocType && { onDrop: () => setDocTypeError('please add document type before uploading') })}
                style={{
                  width: "100%",
                  height: "200px",
                  background: "#FFFFFF",
                  border: "1px dashed #B8B8B8",
                  padding: " 55px 0"
                }}
              >
                <div >
                  <ProgressState progress={progress} errorMessage={errorMessage} />

                </div>
              </DropzoneS3Uploader>
            </div>
          </Grid>

        </Grid>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'left' }}>
                {/* <ButtonInc
                  size="large"
                  onClick={reset}
                  variant="contained"
                  style={{ width: "221px", height: "55px", background: "#FBA92D", marginTop: '3%' }}
                > I'm  Done</ButtonInc> */}
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </>
  )
}

export default UploadDocument