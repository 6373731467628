import { makeStyles } from "@material-ui/styles";


export const useStyle = makeStyles(() => ({
  componentHeading: {
    "& h2": {

      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    }
  },
  productWrapper: {
    // minHeight: "200px",
    marginTop: "30px",
    padding: "0 10px 30px 10px",
    background: "#032A37",
    boxShadow: "0px 2px 8px rgba(99, 99, 99, 0.2)",
    borderRadius: "8px",
    textAlign: "center",
    marginBottom: "50px",
    '&:hover': {
      background: '#FBA92D',
      cursor: 'pointer'
    },
    '&.selected': {
      background: '#FBA92D',
      cursor: 'pointer',
    },
    "& h3": {
      fontFamily: 'Lato',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "15px",
      color: "#FFFFFF",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#FFFFFF",
    }
  },
  bankDeposit: {
    "& h3": {
      fontWeight: 500,
      color: "black",
      fontSize: "28px",
      fontFamily: 'Open Sans',
      textAlign: "center",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#747474",
    }
  },
  mpesapaymentOptions: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    "& h3": {
      fontWeight: 500,
      color: "black",
      fontSize: "28px",
      fontFamily: 'Open Sans',
      textAlign: "center",
    },
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "#747474",
    }
  },
  totalPremium: {
    "& p": {
      fontFamily: 'Open Sans',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      textAlign: "center",
      color: "black",
    },
    "& h2": {
      fontWeight: 500,
      color: "green",
      fontSize: "20px",
      fontFamily: 'Open Sans',
      textAlign: "center",
    }
  },
  termsCondition: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "75%",
    //  margin: "auto",
    "& span": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
    },
  },
  submitBankBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mpesapayment: {
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",

    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      textAlign: "center",
      marginBottom: "30px"
    },
    "& input": {
      borderRadius: "8px",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "12px",
      color: "#000000",
      transformOrigin: "bottom",
    }
  }
}));