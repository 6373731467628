import React from 'react';
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import NavigationWrapper from './Containers/NavigationWrapper/NavigationWrapper'
import { localisedText } from '../../../lib/localisation.es6';
import { getCustomer } from '../../../containers/Customer/actions.es6';
import AccessBankRegisterCustomer from './Containers/RegisterCustomer/index'
import AccessBankEditUser from './Containers/EditUser/index'
import FailedCustomerRegistration from './Containers/FailedCustomerRegistration';
import posthog from 'posthog-js';
import { getPartnerGuid, getUserEmail, getUserFullname } from '../../../lib/access.es6';

class AccessBankCustomerStatus extends React.Component {

	constructor(props) {
		const localizedStrings = new LocalizedStrings(localisedText);
		super(props);
		this.state = {
			labels: localizedStrings,
			openEditCustomerDialog: false,
			openAddPaymentDialog: false,
			openAddCustomerDocumentDialog: false,
			openAddDebitOrderDialog: false,
		};
	}

	componentWillMount() {
		this.updateLanguage();
		let customer_guid = this.props.params.customer_guid
		if (customer_guid) {
			this.setState({ customer_guid: customer_guid })
			this.refreshCustomerStatus(customer_guid);
		}

	}

	componentDidUpdate() {
		this.updateLanguage();
	}

	updateLanguage() {
		const language = this.props.globalData.language;
		let resetLanguage = false;
		if (!language) {
			resetLanguage = true;
		}
		const labels = this.state.labels;
		if (resetLanguage || labels.getLanguage() !== language) {
			labels.setLanguage(language);
			this.setState({});
		}
	}



	refreshCustomerStatus(customer_guid) {
		this.props.dispatch(getCustomer(customer_guid));

	}

	toggleEditCustomerDialog() {
		this.setState({ openEditCustomerDialog: !this.state.openEditCustomerDialog })
	}

	createQuote(customer_guid) {
		hashHistory.push(`admin/create-quote/${customer_guid}`);
		posthog.capture('clicked_create_quote_on_customer_status', {
      email: `${getUserEmail()}`,
      name: `${getUserFullname()}`,
      partner: `${getPartnerGuid()}`,
      timestamp: new Date().toLocaleString(),
  });
	}

	toggleAddPaymentDialog() {
		this.setState({ openAddPaymentDialog: !this.state.openAddPaymentDialog })

	}

	toggleAddCustomerDocumentDialog() {
		this.setState({ openAddCustomerDocumentDialog: !this.state.openAddCustomerDocumentDialog })

	}
	toggleAddDebitOrderDialog() {
		this.setState({ openAddDebitOrderDialog: !this.state.openAddDebitOrderDialog })
	}

	render() {

		if (this.props.customerData.currentCustomer) {
			return (
				<>
					<AccessBankRegisterCustomer
					/>
					<AccessBankEditUser
						openEditCustomerDialog={this.state.openEditCustomerDialog}
						toggleEditCustomerDialog={this.toggleEditCustomerDialog.bind(this)}
					/>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<section style={{
								background: "#FFFFFF",
								borderRadius: "8px",
								height: "auto",
								marginTop: "30px",
								padding: "30px"
							}}>

								<NavigationWrapper
									labels={this.state.labels}
									loading={this.props.customerData.loader}
									customerData={this.props.customerData.currentCustomer}

									toggleEditCustomerDialog={this.toggleEditCustomerDialog.bind(this)}
									createQuote={this.createQuote.bind(this)}


									toggleAddPaymentDialog={this.toggleAddPaymentDialog.bind(this)}
									openAddPaymentDialog={this.state.openAddPaymentDialog}

									openAddCustomerDocumentDialog={this.state.openAddCustomerDocumentDialog}
									toggleAddCustomerDocumentDialog={this.toggleAddCustomerDocumentDialog.bind(this)}

									openAddDebitOrderDialog={this.state.openAddDebitOrderDialog}
									toggleAddDebitOrderDialog={this.toggleAddDebitOrderDialog.bind(this)}

									customer_guid={this.props.customerData.currentCustomer.guid}
								/>

							</section>
						</Grid>

					</Grid>

				</>
			)
		} else if (this.props.customerData.customer_refresh_failed === true) {
			return <FailedCustomerRegistration
				guid={this.props.params.customer_guid}

			/>
		} else {
			return ""
		}

	}
}
export default connect((state) => ({
	customerData: state.currentCustomer,
	QuoteData: state.quoteData,
	globalData: state.global,
}))(AccessBankCustomerStatus);