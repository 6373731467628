import React from 'react'
import { useStyle } from "./Style";
import { Grid, Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SkeletonWrapper from '../../../../containers/NewProductList/components/SkeletonWrapper/SkeletonWrapper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateLocale } from '../../../../lib/utils.es6';
import EditDependant from './EditDependant';
import DeleteDependant from './DeleteDependant';
const ITEM_HEIGHT = 48;


const Dependants = (props) => {
  // React.useEffect(() => {
  //   props.getCustomerDependantsMobile()
  // }, [props.customerGuid])

  // if(!props.dependants){
  //   return
  // }
  const classes = useStyle();
  const [expanded, setExpanded] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDependantAction, setSelectedDependantAction] = React.useState(null)

  const [showEditDependantDialog, setEditDependantDialog] = React.useState(false)
  const [dependantToEdit, setDependantToEdit] = React.useState({})
  const [showDeleteDependantDialog, setDeleteDependantDialog] = React.useState(false)
  const [dependantToDelete, setDependantToDelete] =  React.useState({})

  const open = Boolean(anchorEl);
  const handleClick = (event, dependant) => {
    setAnchorEl(event.currentTarget);
    setSelectedDependantAction(dependant)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowEditDependantPopup = (dependant) => {
    handleClose()
    setEditDependantDialog(true)
    setDependantToEdit(dependant)
  }

  const toggleEditDependant = () => {
    setEditDependantDialog(prevState => !prevState);
  }

  const handleShowDeleteDependantPopup = (dependant) => {
    console.log("dependantToDelete", dependant)
    handleClose()
    setDeleteDependantDialog(true)
    setDependantToDelete(dependant)
  }

  const toggleDeleteDependant = () => {
    setDeleteDependantDialog(prevState => !prevState)
  }


  let dependants = props.dependants || []

  const dataForDisplay = expanded ? dependants : dependants.slice(0, 2)

  if (props.dependantsLoader) {
    return <SkeletonWrapper loading={props.dependantsLoader} />
  }

  if (dataForDisplay.length > 0) {
    return (
      <section className={classes.policyOuter}>
        {
          dataForDisplay.map((dependant, index) => (
            <Grid key={index} container spacing={2} className={classes.policyWrapper}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid container spacing={2} className={classes.policyHeader}>
                      <Grid item xs={2} className={classes.policyIconWrapper}   >
                        <Box className={classes.policyIcons}>
                          <NoteAddOutlinedIcon fontSize="14px" sx={{ color: "#ffffff" }} />
                        </Box>
                      </Grid>
                      <Grid item xs={10} className={classes.policyHeaderContent}>
                        <h6>{dependant.first_name || "N/A"}</h6>
                        <p> First Name</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} className={classes.policyActiveStatusWrapper}>
                    <Box display="flex" justifyContent="flex-end">
                      <ul>
                        {/* <li className={classes.activePill}> <span>Active</span></li> */}
                        <li className={classes.actionbtnpolicy}>
                          <div>
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              //onClick={handleClick}
                              onClick={(event) => handleClick(event, dependant)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            {
                              selectedDependantAction && selectedDependantAction.guid === dependant.guid && <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                slotProps={{
                                  paper: {
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5,
                                      width: '20ch',
                                    },
                                  },
                                }}
                              >

                                <MenuItem style={{
                                  cursor: "pointer",
                                  fontFamily: "Open Sans",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "15px",
                                  color: "#000000",
                                }}
                                  onClick={() => handleShowEditDependantPopup(dependant)}>
                                  Edit Dependant
                                </MenuItem>

                                <MenuItem style={{
                                  cursor: "pointer",
                                  fontFamily: "Open Sans",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "15px",
                                  color: "#000000",
                                }} 
                                onClick={() => handleShowDeleteDependantPopup(dependant)}
                                >
                                
                                  Delete Dependant
                                </MenuItem>


                              </Menu>
                            }

                          </div>
                        </li>
                      </ul>
                    </Box>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Middle Name</p>
                    <h6> {dependant.middle_name || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Last Name </p>
                    <h6>{dependant.last_name || "N/A"}
                    </h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Date Of Birth</p>
                    <h6> {formatDateLocale(dependant.date_of_birth) || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Phone Number</p>
                    <h6>{dependant.msisdn || "N/A"}
                    </h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>National ID</p>
                    <h6> {dependant.national_id || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p> Created At</p>
                    <h6>{formatDateLocale(dependant.created_at) || "N/A"}
                    </h6>
                  </Grid>
                  <div className={classes.policyHeaderBorder}></div>
                </Grid>
                <Grid container spacing={2} className={classes.policyContentWrapper}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={6}>
                    <p>Relationship</p>
                    <h6>  {dependant.relationship || "N/A"}</h6>
                  </Grid>
                  <Grid item xs={5}>
                    <p>Gender</p>
                    <h6> {dependant.gender || "N/A"}</h6>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          ))
        }

        <Grid container spacing={2} className={classes.showMoreBtn}>
          <Grid item xs={12} >
            <Button variant="outlined" endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />} onClick={() => setExpanded(!expanded)}>
              {expanded ? `Show Less Dependants` : `Show More Dependants`}
            </Button>

          </Grid>
        </Grid>

        {
          showEditDependantDialog &&
          <EditDependant
            toggleEditDependant={toggleEditDependant}
            showEditDependantDialog={showEditDependantDialog}
            setEditDependantDialog={setEditDependantDialog}
            dependantToEdit={dependantToEdit}
            customerGuid={props.customerGuid}
            editDependant={props.editDependant}

            showEditDependantProgressAlert={props.showEditDependantProgressAlert}
            showEditDependantSuccessAlert={props.showEditDependantSuccessAlert}
            showEditDependantErrorAlert={props.showEditDependantErrorAlert}
            resetEditDependantSuccessAlert={props.resetEditDependantSuccessAlert}
            resetEditDependantErrorAlert={props.resetEditDependantErrorAlert}
            editDependantError={props.editDependantError}
          />
        }

        {
          showDeleteDependantDialog && <DeleteDependant
          showDeleteDependantDialog={showDeleteDependantDialog}
          toggleDeleteDependant={toggleDeleteDependant}
          DeleteDependant={props.DeleteDependant}
          dependantToDelete={dependantToDelete}
          />
        }

      </section>
    )
  } else {
    return (<>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <h1 style={{
            color: "#000000",
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            marginTop: "100px",
          }}> No dependants found please add dependant</h1>

        </Grid>
      </Grid>

    </>)
  }
}

export default Dependants