import React, { useState } from 'react'
import { hashHistory } from 'react-router';
import { useStyle } from "./Style";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Viewquote from '../ViewQuote/Viewquote';
import Buypolicy from '../BuyPolicy/Buypolicy';
import Viewpolicy from '../ViewPolicy/Viewpolicy';
import PaymentDetails from '../PaymentsDetails/PaymentDetails';
import UploadDocument from '../UploadDocument/UploadDocument';
import { CreateQuoteSwitch as Createquote } from '../CreateQuoteSwitch';
import posthog from 'posthog-js';
import { getPartnerGuid, getUserEmail, getUserFullname } from '../../../../../lib/access.es6';

const GenerateQuote = (props) => {
  const classes = useStyle();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [acceptQuote, setAcceptQuote] = useState(false)


  let steps = ["Create Quote", "View Quote", "Buy Policy", "Add Payment Details", "Upload Document", "View Policy"];




  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (!props.createdQuote) return

    if (isLastStep()) {
      //hashHistory.push('admin/customer_status');

      const customer_guid = props.customerData.guid
      hashHistory.push('admin/customer_status/' + customer_guid);

      props.resetAllAlerts()
      hashHistory.push(`admin/policies_dashboard`);
    }

    const createQuoteStepIndex = 0
    const viewQuoteStepIndex = 1
    const buyPolicyStepIndex = 2
    const paymentDetailsIndex = 3
    const uploadDocumentsIndex = 4
    const viewPolicyStepIndex = 5


    if (activeStep === createQuoteStepIndex && props.createdQuote !== null) moveToNext();
    if (activeStep === viewQuoteStepIndex && props.createdQuote !== null) moveToNext();
    if (activeStep === paymentDetailsIndex && props.buyPolicyData !== null) moveToNext();
    if (activeStep === uploadDocumentsIndex && props.buyPolicyData !== null) moveToNext();
    if (activeStep === buyPolicyStepIndex && props.buyPolicyData !== null) moveToNext();
    if (activeStep === viewPolicyStepIndex && props.buyPolicyData !== null) moveToNext();

    // moveToNext()
    function moveToNext() {
      const newActiveStep = isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,

        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {


    // setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});

  };


  const getGenerateQuoteCompnent = () => {
    let createQuoteStepIndex;
    let viewQuoteStepIndex;
    let paymentDetailsIndex
    let uploadDocumentsIndex
    let buyPolicyStepIndex;
    let viewPolicyStepIndex;

    if (steps.length === 6) {
      createQuoteStepIndex = 0
      viewQuoteStepIndex = 1
      buyPolicyStepIndex = 2
      paymentDetailsIndex = 3
      uploadDocumentsIndex = 4
      viewPolicyStepIndex = 5
    } else {
      createQuoteStepIndex = 0
      viewQuoteStepIndex = 1
      buyPolicyStepIndex = 2
      paymentDetailsIndex = 3
      uploadDocumentsIndex = 4
      viewPolicyStepIndex = 5
    }

    if (activeStep === createQuoteStepIndex) return {
      component: <Createquote
        dependant={props.dependant}
        createQuote={props.createQuote}
        createDependant={props.createDependant}
        showAddDependantProgressAlert={props.showAddDependantProgressAlert}
        showAddDependantSuccessAlert={props.showAddDependantSuccessAlert}
        showAddDependantErrorAlert={props.showAddDependantErrorAlert}
        resetAddDependantSuccessAlert={props.resetAddDependantSuccessAlert}

        createQuoteError={props.createQuoteError}
        showAddQuoteProgressAlert={props.showAddQuoteProgressAlert}
        showAddQuoteSuccessAlert={props.showAddQuoteSuccessAlert}
        showAddQuoteErrorAlert={props.showAddQuoteErrorAlert}
        resetAddQuoteSuccessAlert={props.resetAddQuoteSuccessAlert}
        resetAddQuoteErrorAlert={props.resetAddQuoteErrorAlert}
        handleNext={handleNext}
        principal={props.customerData}
        autoFillPrincipal={props.autoFillPrincipal}
        toggleAutoFillPrincipal={props.toggleAutoFillPrincipal}
        quoteToReCreate={props.quoteToReCreate}
        products={props.products}

    
      />

    }
    if (activeStep === viewQuoteStepIndex) return {
      component: <Viewquote
        createdQuote={props.createdQuote}
        acceptQuote={acceptQuote}
        setAcceptQuote={setAcceptQuote}
      />
    }

    if (activeStep === buyPolicyStepIndex) return {
      component: <Buypolicy
        agentUsersData={props.agentUsersData}
        createdQuote={props.createdQuote}
        buyPolicy={props.buyPolicy}
        handleNext={handleNext}


        buyPolicyError={props.buyPolicyError}
        showBuyPolicyProgressAlert={props.showBuyPolicyProgressAlert}
        showBuyPolicySuccessAlert={props.showBuyPolicySuccessAlert}
        showBuyPolicyErrorAlert={props.showBuyPolicyErrorAlert}
        resetBuyPolicySuccessAlert={props.resetBuyPolicySuccessAlert}
        resetBuyPolicyErrorAlert={props.resetBuyPolicyErrorAlert}

        changeQuote={props.changeQuote}
        policies={props.policies}
        changeQuoteError={props.changeQuoteError}
        changeQuoteProgressAlert={props.changeQuoteProgressAlert}
        changeQuoteSuccessAlert={props.changeQuoteSuccessAlert}
        changeQuoteErrorAlert={props.changeQuoteErrorAlert}
        resetChangeQuoteSuccessAlert={props.resetChangeQuoteSuccessAlert}
        resetChangeQuoteErrorAlert={props.resetChangeQuoteErrorAlert}

        quoteToReCreate={props.quoteToReCreate}
      />
    }


    if (activeStep === paymentDetailsIndex) return {
      component: <PaymentDetails
        policy={props.buyPolicyData}
        createdQuote={props.createdQuote}
        customerData={props.customerData}
        addDebitOrder={props.addDebitOrder}
        handleNext={handleNext}

        emailTemplateData={props.emailTemplateData}
        showAddDebitOrderProgressAlert={props.showAddDebitOrderProgressAlert}
        showAddDebitOrderSuccessAlert={props.showAddDebitOrderSuccessAlert}
        showAddDebitOrderErrorAlert={props.showAddDebitOrderErrorAlert}
        createDebitOrderError={props.createDebitOrderError}
        sendEmailRequest={props.sendEmailRequest}
        sendEmailError={props.sendEmailError}
        showSendEmailProgressAlert={props.showSendEmailProgressAlert}
        showSendEmailSuccessAlert={props.showSendEmailSuccessAlert}
        showSendEmailErrorAlert={props.showSendEmailErrorAlert}
        sendEmail={props.sendEmail}
      />
    }
    if (activeStep === uploadDocumentsIndex) return {
      component: <UploadDocument
        policy={props.buyPolicyData}
        handleNext={handleNext}
        DocumentUpload={props.DocumentUpload}
        customer_guid={props.customerData.guid}
        resetDebitOrdersDcp={props.resetDebitOrdersDcp}
      />
    }

    if (activeStep === viewPolicyStepIndex) return {
      component: <Viewpolicy
        buyPolicyData={props.buyPolicyData}
        customerData={props.customerData}
      />
    }

    return null
  }


  const renderQuoteComponent = () => {
    let quoteComponent = getGenerateQuoteCompnent();

    return <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <>
          {quoteComponent.component}
        </>
      </Box>
    </div>
  }

  const viewAllQuotes = (url) => {
    hashHistory.push(url)
  }

  const getNextLabel = () => {
    if (activeStep === 0) return 'View Qoute'
    if (activeStep === 1) return 'Buy Policy'
    if (activeStep === 2) return 'Add Bank Details'
    // if (activeStep === 3) return 'Upload Document'
    if (activeStep === 3) {
      posthog.capture('uploaded_document', {
        email: `${getUserEmail()}`,
        name: `${getUserFullname()}`,
        partner: `${getPartnerGuid()}`,
        timestamp: new Date().toLocaleString(),
      });
      return 'Upload Document';
    }

    if (activeStep === 4) {
      posthog.capture('view_policy', {
        email: `${getUserEmail()}`,
        name: `${getUserFullname()}`,
        partner: `${getPartnerGuid()}`,
        timestamp: new Date().toLocaleString(),
      });
      return 'View Policy';
    } else {
      posthog.capture('send_for_approval', {
        email: `${getUserEmail()}`,
        name: `${getUserFullname()}`,
        partner: `${getPartnerGuid()}`,
        timestamp: new Date().toLocaleString(),
      });
      return 'View Policy  Details';
    }

    // if (activeStep === 4) return 'View Policy'
    // return 'Send For Approval'
  }

  const isDisabled = () => {

    if('View Policy'===getNextLabel()) return false
    if('Send For Approval'===getNextLabel()) return false
    if ('Buy Policy'===getNextLabel() ) { 
      return !acceptQuote && activeStep === 1
    }
    return true

  }

  return (
    <>
      <Grid item xs={12} sm={9} md={9} >
        <div className={classes.generatequotewrapper} >
          <Box sx={{ width: "100%" }}>
            <div className={classes.stepperlabelwrapper}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]} sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: '#75A85E', // circle color (COMPLETED)
                    },
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#032a37', // circle color (ACTIVE)
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: '#fff', // circle's number (ACTIVE)
                    },
                  }}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div>
              {allStepsCompleted() ? (
                <>
                  <div className={classes.completebtn} >
                    <p >
                      All steps completed
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleReset}
                    >Generate Another Quote</Button>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => viewAllQuotes('admin/customer-status')}
                      className={classes.viewquote}
                    >View Quotes</Button>
                  </div>

                </>
              ) : (
                <>
                  <div className={classes.quotewrapper}>

                    {renderQuoteComponent()}
                  </div>
                  <div className={classes.quotenavigationbtn} style={{display: isDisabled() ? 'none' : 'block' }}>
                    <Button
                      disabled={true}
                      onClick={handleBack}
                      className={classes.backbtn}
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ display: activeStep === 0 ? "none" : "initial" }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleComplete}
                      style={{ display: activeStep === 0 ? "none" : "initial" }}
                      //disabled={activeStep === uploadDocumentsIndex && !props.areDocumentsUploaded} // Disable button if on the upload documents step and documents are not uploaded
                      disabled={ isDisabled() }
                      // disabled={!acceptQuote && activeStep === 1}
                    >
                      {completedSteps() === totalSteps() - 1
                        ? "View Policy"
                        : getNextLabel()}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Box>
        </div>
      </Grid>
    </>
  )
}

export default GenerateQuote