import { Dialog } from '@material-ui/core';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'

const DeleteDependant = (props) => {


 const deleteDependant = () => {
   props.DeleteDependant(props.dependantToDelete.guid);
   props.toggleDeleteDependant()
 }
  return (
    <>

      <Dialog
        open={props.showDeleteDependantDialog}
        onClose={props.toggleDeleteDependant}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Confirm delete of dependant
        </DialogTitle>
        <DialogContent>Are you sure you want to delete a dependant?</DialogContent>
        <DialogActions>
          {/* {confirmDeleteActions.map((item, index) => (
            <div key={index}> {item}</div>
          ))} */}
          <Button style={{
            backgroundColor: 'green',
            color: 'white',
            margin: '2px',
            height: '50px',
            padding: '0 30px',
          }} onClick={props.toggleDeleteDependant}>
            No
          </Button>,
          <Button style={{
            backgroundColor: 'orangered',
            color: 'white',
            margin: '2px',
            height: '50px',
            padding: '0 30px',
          }}
            onClick={deleteDependant}
            
            >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteDependant