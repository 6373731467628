import React, { useState } from "react";
import { Paper, Chip } from "@mui/material";
import BoxInc from "../../../shared-ui/BoxInc/Index";
import TableInc from "../../../shared-ui/TableInc";
import AlertDialog from "../../../components/AlertDialog/index.es6";
import { formatDateToReadable } from "../../../lib/utils.es6";

const Benefits = ({ claim }) => {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, 
    // setErrorMessage
  ] = useState("");
  const benefits = claim.benefits;

  const handleCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const handleCloseErrorAlert = () => {
    setShowErrorAlert(false);
  };

  // const getErrorMessage = (error) => {
  //   if (error.response && error.response.data) {
  //     return error.response.data.message || error.response.data.error || "An unknown error occurred";
  //   }
  //   return error.message || "Failed to update benefit";
  // };

  return (
    <BoxInc
      title="Benefits"
      expanded
      style={{ marginTop: "40px" }}
      actionButtons={[]}
    >
      <div id="benefits" component-id="benefits">
        {showSuccessAlert && (
          <AlertDialog
            success
            title="Success!"
            onConfirm={handleCloseSuccessAlert}
            confirmBtnText="OK"
          >
            Benefit applied successfully
          </AlertDialog>
        )}

        {showErrorAlert && (
          <AlertDialog
            error
            title="Error!"
            onConfirm={handleCloseErrorAlert}
            confirmBtnText="OK"
          >
            {errorMessage}
          </AlertDialog>
        )}

        <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
          <TableInc
            title="Extended members relationship"
            columns={[
              {
                title: "Benefit",
                field: "benefit",
                render: (rowData) => (
                  <div>
                    <strong>Product Name:</strong> {rowData.product_name}
                    <br />
                    <strong>Benefit Type:</strong> {rowData.type}
                    <br />
                    {rowData.night && (
                      <>
                        <strong>Nights:</strong> {rowData.night}
                        <br />
                      </>
                    )}
                    <strong>Nightly Benefit:</strong> {rowData.amount_benefit}
                    <br />
                    {(rowData.start_date || rowData.end_date) && (
                      <>
                        <strong>Period:</strong>{" "}
                        {formatDateToReadable(rowData.start_date)}
                        {" - "}
                        {formatDateToReadable(rowData.end_date)}
                      </>
                    )}
                  </div>
                ),
                editable: "never",
              },
              {
                title: "Total",
                field: "total",
                render: (rowData) => rowData.night
                  ? rowData.night * rowData.amount_benefit
                  : rowData.amount_benefit,
                editable: "never",
              },
              {
                title: "Benefit Applied",
                field: "approved",
                lookup: { true: "Applied", false: "Not Applied" },
                editable: "never",
                render: (rowData) =>
                  rowData.approved ? (
                    <Chip
                      sx={{
                        color: "#fff",
                        bgcolor: "#75A85E",
                      }}
                      label="Applied"
                    />
                  ) : (
                    <Chip
                      sx={{
                        color: "#fff",
                        bgcolor: "red",
                      }}
                      label="Not Applied"
                    />
                  ),
              },
            ]}
            data={benefits}
            options={{
              pageSize: benefits.length,
              showTitle: false,
              actionsColumnIndex: -1,
              addRowPosition: "first",
              search: false,
            }}
            /** 
            editable={
              (isReadOnlyStatus(claim.status) === false) && {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const updatedBenefits = {
                      ...newData,
                      approved: newData.approved === "Applied" ? true : false,
                    };
                    const payload = {
                      ...claim,
                      benefits: updatedBenefits
                    };

                    updateClaim(payload)
                      .then((response) => {
                        setShowSuccessAlert(true);
                        refetchClaim();
                        resolve();
                      })
                      .catch((error) => {
                        const message = getErrorMessage(error);
                        setErrorMessage(message);
                        setShowErrorAlert(true);
                        reject();
                      });
                  }),
              }
            }
            */
          />
        </Paper>
      </div>
    </BoxInc>
  );
};

export default Benefits;
