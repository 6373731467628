import * as types from "../../lib/actionTypes.es6";

export const initialState = {
  error: null,
  loader: false,
  dependants: [],

  dependant: null,

  createdDependant: null,
  createDependantSuccess: false,
  createDependantLoader: false,
  createDependantError: null,


  dependantToEdit: null,
  dependantToDelete: null,

  editedDependant: null,
  editDependantSuccess: false,
  editDependantLoader: false,
  editDependantError: null,


  deleteDependantSuccess: false,
  deleteDependantLoader: false,
  deleteDependantError: null,
  dependantToDeleteGuid: null,
};

export default function customerDependantsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMER_DEPENDANT_REQUEST:
      return { ...state, loader: true, error: null, dependant: null };
    case types.GET_CUSTOMER_DEPENDANT_SUCCESS:
      return { ...state, dependant: action.payload, loader: false };
    case types.GET_CUSTOMER_DEPENDANT_FAILURE:
      return { ...state, loader: false, error: action.payload };

    case types.RESET_GET_CUSTOMER_DEPENDANT:
      return { ...state, loader: false, dependant: null };

    case types.GET_CUSTOMER_DEPENDANTS_REQUEST:
      return { ...state, loader: true, error: null, dependants: null };
    case types.GET_CUSTOMER_DEPENDANTS_SUCCESS:
      return { ...state, dependants: action.payload, loader: false };
    case types.GET_CUSTOMER_DEPENDANTS_FAILURE:
      return { ...state, loader: false, error: action.payload };

    case types.ADD_CUSTOMER_DEPENDANT_REQUEST:
      return { ...state, createDependantLoader: true, createDependantError: null, createdDependant: null };
    case types.ADD_CUSTOMER_DEPENDANT_SUCCESS:
      return { ...state, dependant: action.payload, createDependantLoader: false, createDependantSuccess: true };
    case types.ADD_CUSTOMER_DEPENDANT_FAILURE:
      return { ...state, createDependantLoader: false, createDependantError: action.payload };

    case types.RESET_CREATE_CUSTOMER_DEPENDANTS_SUCCESS:
      return { ...state, createDependantSuccess: false };
    case types.RESET_CREATE_CUSTOMER_DEPENDANTS_ERROR:
      return { ...state, createDependantError: null };


    case types.UPDATE_CUSTOMER_DEPENDANT_REQUEST:
      return { ...state, editDependantLoader: true, editDependantError: null, dependant: null };

    case types.UPDATE_CUSTOMER_DEPENDANT_SUCCESS:
      return { ...state, editedDependant: action.payload, editDependantLoader: false, editDependantSuccess: true };

    case types.UPDATE_CUSTOMER_DEPENDANT_FAILURE:
      return { ...state, editDependantLoader: false, editDependantError: action.payload };

    case types.SET_CUSTOMER_DEPENDANTS_TO_EDIT:
      return { ...state, dependantToEdit: action.payload };

    case types.RESET_CUSTOMER_DEPENDANTS_TO_EDIT:
      return { ...state, dependantToEdit: null };

    case types.RESET_EDIT_CUSTOMER_DEPENDANTS_SUCCESS:
      return { ...state, editDependantSuccess: false };

    case types.RESET_EDIT_CUSTOMER_DEPENDANTS_ERROR:
      return { ...state, editDependantError: null };


    case types.DELETE_CUSTOMER_DEPENDANT_REQUEST:
      return { ...state, loader: true, error: null, dependant: null };
    case types.DELETE_CUSTOMER_DEPENDANT_SUCCESS:
      return { ...state, loader: false };
    case types.DELETE_CUSTOMER_DEPENDANT_FAILURE:
      return { ...state, loader: false, error: action.payload };

    case types.SET_CUSTOMER_DEPENDANTS_TO_DELETE:
      return { ...state, dependantToDelete: action.payload };

    case types.RESET_CUSTOMER_DEPENDANTS_TO_DELETE:
      return { ...state, dependantToDelete: null };


    case types.RESET_DELETE_CUSTOMER_DEPENDANTS_SUCCESS:
      return { ...state, deleteDependantSuccess: false };

    case types.RESET_DELETE_CUSTOMER_DEPENDANTS_ERROR:
      return { ...state, deleteDependantError: null };

    default:
      return state;
  }
}







