import React from 'react'
import Grid from "@material-ui/core/Grid";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
} from '@mui/material';
import { useStyle } from "./Style";
import ButtonInc from '../../../../../../shared-ui/ButtonInc';
const Declarations = (props) => {
  const classes = useStyle();


  const handleChange = (event) =>{
    const { name } = event.target;
    if(name === "declinedProposal"){
      props.setDeclinedProposal(event.target.checked)
    }
    if(name === "increasedPremium"){
      props.setIncreasedPremium(event.target.checked)
    }
    if(name === "policyCancelled"){
      props.setPolicyCancelled(event.target.checked)
    }
    if(name === "defectiveCondition"){
      props.setDefectiveCondition(event.target.checked)
    }
    if(name === "drivingOffense"){
      props.setDrivingOffense(event.target.checked)
    }
  }



  return (
    <>
      <Grid container spacing={2} >
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.detailstitle}>
            <h3 style={{
              textAlign: "center",
              fontSize: "18px"
            }}>Check Relevant Declarations</h3>
            {/* {
                  props.currentRelationShip === 'Principal'
                    ? <h3>Lets add Main member first</h3>
                    : <h3>Add a {props.currentRelationShip} member</h3>

                } */}
          </div>
        </Grid>
      </Grid>

      <Box sx={{ p: 3, maxWidth: 1000, mx: 'auto', my: 4 }}>
        <Box display="flex" justifyContent="space-between" className={classes.detailstitle}>
          <Box flex={1} mr={2}>
            <h3 >
              Anyone else who will drive this vehicle
            </h3>
            <div className={classes.leftChecklist}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.declinedProposal}
                      onChange={handleChange}
                      name="declinedProposal"
                    />
                  }
                  label="Ever had another insurer decline their proposal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.increasedPremium}
                      onChange={handleChange}
                      name="increasedPremium"
                    />
                  }
                  label="Required an increased premium or imposed special conditions"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.policyCancelled}
                      onChange={handleChange}
                      name="policyCancelled"
                    />
                  }
                  label="Ever had their policy cancelled or declined for renewal"
                />
              </FormGroup>
            </div>

          </Box>

          <Divider orientation="vertical" flexItem />

          <Box flex={1} ml={2} className={classes.detailstitle}>
            <h3>
              Anyone else who will drive this vehicle
            </h3>
            <div className={classes.rightChecklist}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.defectiveCondition}
                      onChange={handleChange}
                      name="defectiveCondition"
                    />
                  }
                  label="Suffer from defective vision or hearing or any physical, mental infirmity or disease"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.drivingOffense}
                      onChange={handleChange}
                      name="drivingOffense"
                    />
                  }
                  label="Convicted in the past 5 years for any offense related to driving any motor vehicle"
                />
              </FormGroup>
            </div>

          </Box>
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={4}></Grid>
        <Grid item xs={12} md={4} sm={4}>
          {props.dependants.length > 0 &&
            <div className={classes.createquote}>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                onClick={() => props.handleCreateQuote()}
              >
                Create Quote
              </ButtonInc>

            </div>
          }
        </Grid>
        <Grid item xs={12} md={4} sm={4}></Grid>
      </Grid>



    </>
  )
}

export default Declarations