export const Valuers =  [
  {
      "id": 1,
      "valuer_name": "Solvit  Valuers",
      "country_code": "KE"
  },
  {
      "id": 2,
      "valuer_name": "Links Valuers",
      "country_code": "KE"
  },
  {
      "id": 3,
      "valuer_name": "Capital Alliance Valuers",
      "country_code": "KE"
  },
  {
      "id": 4,
      "valuer_name": "Regent Valuers",
      "country_code": "KE"
  },
  {
      "id": 5,
      "valuer_name": "Internal Valuer (Assessment Centre)",
      "country_code": "KE"
  },
  {
      "id": 6,
      "valuer_name": "Other",
      "country_code": "KE"
  }
]