import React from "react";
import { connect } from "react-redux";
import LocalizedStrings from 'react-localization';
import { hashHistory } from 'react-router';
import PolicyHeader from './Components/PolicyHeader/PolicyHeader.js'
import NavigationWrapper from "./Components/NavigationWrapper/NavigationWrapper";
import { getPolicyProcessingDcpRequest, policyActionRequest, setPolicyToApprove, resetPolicyToApprove, policyRejectActionRequest, addPolicyNotesRequest, cancelPolicyActionRequest, deletepolicyDocumentRequest, setpolicyDocumentsToDelete, resetPolicyDocumentToDelete, resetHardDeletepolicyDocumentSuccess, resetHardDeletepolicyDocumentError, getPaymentsByPolicyGuidRequest } from "./Redux/actions";
import ApprovePolicy from "./Components/ApprovePolicy/ApprovePolicy.js";
import RejectPolicy from "./Components/RejectPolicy/RejectPolicy.js";
import { getAllProductsRequest } from "../../../containers/CustomerScreen/actions.es6.js";
import { editDebitOrderzRequest, getDebitOrderzRequest, hardDeleteDebitOrderzRequest, resetDebitOrderzToDelete, resetEditDebitOrderzError, resetEditDebitOrderzSuccess, resetHardDeleteDebitOrderzError, resetHardDeleteDebitOrderzSuccess, setDebitOrderzToDelete, setDebitOrderzToEdit } from "../../../containers/CustomerScreen/components/DebitOrder/state/actions.js";
import { getCustomer } from "../../../containers/Customer/actions.es6.js";
import * as documentActions from '../../../containers/DocumentGeneration/actions.es6';
import DownloadDocument from "../../../components/DownloadDocument/DownloadDocument.js";
import { localisedText } from "../../../lib/localisation.es6.js";
import CancelPolicy from "./Components/PolicyDetails/Components/CancelPolicy/CancelPolicy.js";
import PolicyNotes from "./Components/PolicyDetails/Components/PolicyNotes/PolicyNotes.js";
import PolicyDocuments from "./Components/PolicyDetails/Components/PolicyDocuments/PolicyDocuments.js";
import { createDebitOrdersDcpRequest, uploadPolicyDcpDocumentRequest } from "../../../containers/Policyv2/Redux/actions.js";
import MakePayment from "./Components/PolicyDetails/Components/MakePayment/MakePayment.js";
import AddDebitOrders from "./Components/PolicyDetails/Components/DebitOrders/AddDebitOrders.js";
import { getAllAcessBankCustomerPremiumsRequest } from "../CustomerStatus/Containers/Premiums/Redux/actions.js";
import { getAccessBankCustomerClaimsRequest } from "../CustomerStatus/Containers/Claims/redux/actions.js";
import DeleteDocument from "./Components/PolicyDetails/Components/DeleteDocument/DeleteDocument.js";
import { getAllPoliciesRequest } from "../CustomerStatus/Redux/actions.js"
import DeleteDebitOrder from "../CustomerStatus/Containers/DebitOrders/Components/DeleteDebitOrder/DeleteDebitOrder.js";
import { setQuotationToReCreate } from "../PolicyAdmin/Redux/actions.js";
import EditDebitOrder from "./Components/PolicyDetails/Components/EditDebitOrder/EditDebitOrder.js";
import { reloadPage } from "../../../lib/utils.es6.js";
import UpdateBeneficiary from "./Components/PolicyDetails/Components/UpdateBeneficiary/UpdateBeneficiary.js";
class PoliciesProcessing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openRejectPolicyDialog: false,
      labels: new LocalizedStrings(localisedText),
      openCancelPolicyDialog: false,
      openPolicyNoteDialog: false,
      openPolicyDocumentDialog: false,
      openAddPaymentDialog: false,
      openAddDebitOrderDialog: false,
      openEditDebitOrderDialog: false,
      openUpdateBeneficaryyDialog: false,
      updateAction: null,
    };
  }

  componentDidMount() {
    let policy_number = this.props.params.policy_number
    this.getPolicy(policy_number)
  }
  getPolicy(policy_number){
    this.props.dispatch(getPolicyProcessingDcpRequest({
      policy_number: policy_number,
    }));
  }

  componentWillMount() {
    this.props.dispatch(getAllProductsRequest())
  }

  toggleOpenRejectPolicyDialog() {
    this.setState({ openRejectPolicyDialog: !this.state.openRejectPolicyDialog })
  }

  toggleOpenCancelPolicyDialog() {
    this.setState({ openCancelPolicyDialog: !this.state.openCancelPolicyDialog })
  }

  toggleUpdateBeneficaryDialog() {
    this.setState({ openUpdateBeneficaryyDialog: !this.state.openUpdateBeneficaryyDialog })
  }

  toggleOpenPolicyNoteDialog() {
    this.setState({ openPolicyNoteDialog: !this.state.openPolicyNoteDialog })

  }

  toggleOpenPolicyDocumentDialog() {
    this.setState({ openPolicyDocumentDialog: !this.state.openPolicyDocumentDialog })

  }
  toggleAddPaymentDialog() {
    this.setState({ openAddPaymentDialog: !this.state.openAddPaymentDialog })
  }

  toggleAddDebitOrderDialog() {
    this.setState({ openAddDebitOrderDialog: !this.state.openAddDebitOrderDialog })
  }

  toggleEditDebitOrderDialog() {
    this.setState({ openEditDebitOrderDialog: !this.state.openEditDebitOrderDialog })
  }

  getStatus() {
    const { updateAction } = this.state

    const updateActions = {
      Approve: 'Approve',
      Discard: 'Discard',
      ReConfirm: 'ReConfirm',
    }

    let status = "";

    switch (updateAction) {
      case updateActions.Approve:
        status = "Unpaid"
        break;

      case updateActions.Discard:
        status = "Discarded"
        break;

      case updateActions.ReConfirm:
        status = "Un_Confirmed"
        break;

      default:
        break;
    }

    return status

  }

  approvePolicy() {
    let status = this.getStatus()
    console.log('status is : ', status);

    this.props.dispatch(policyActionRequest({
      policy_guid: this.props.PolicyProcessingData.policyProcessingDcp.guid,
      body: {
        "status": status,
      }
    }));
  }

  rejectPolicy() {
    this.props.dispatch(policyRejectActionRequest({
      policy_guid: this.props.PolicyProcessingData.policyProcessingDcp.guid,
      body: {
        "status": "Rejected",
        "additional_details": {}
      }
    }));
  }

  sendForReConfirm() {
    this.props.dispatch(policyRejectActionRequest({
      policy_guid: this.props.PolicyProcessingData.policyProcessingDcp.guid,
      body: {
        "status": "Un_Confirmed",
        "additional_details": {}
      }
    }));
  }

  discard() {
    this.props.dispatch(policyRejectActionRequest({
      policy_guid: this.props.PolicyProcessingData.policyProcessingDcp.guid,
      body: {
        "status": "Discarded",
        "additional_details": {}
      }
    }));
  }

  setPolicyToApprove(policy) {
    this.props.dispatch(setPolicyToApprove(policy))
    console.log("policy to approve", policy)
  }

  resetPolicyToApprove(policy) {
    this.props.dispatch(resetPolicyToApprove(policy))
  }

  getAllPayments() {
    if (this.props.PolicyProcessingData.policyProcessingDcp) {

      const policyGuid = this.props.PolicyProcessingData.policyProcessingDcp.guid
      const policy_number = this.props.PolicyProcessingData.policyProcessingDcp.policy_number

      this.props.dispatch(getPaymentsByPolicyGuidRequest({ guid: policyGuid, policy_number: policy_number }));

    }
  }

  getAllDebitOrders(payload) {
    const policy_guid = this.props.PolicyProcessingData.policyProcessingDcp.guid
    this.props.dispatch(getDebitOrderzRequest({ policy_guid }));
  }

  handleAddPolicyNotes(payload) {
    this.props.dispatch(addPolicyNotesRequest(payload));
  }

  cancelPolicy(policy) {
    this.props.dispatch(cancelPolicyActionRequest(policy)
    );
  }

  DocumentUpload(doc) {
    const policyGuid = this.props.PolicyProcessingData.policyProcessingDcp.guid
    if (policyGuid) {
      this.props.dispatch(uploadPolicyDcpDocumentRequest({ ...doc, policyGuid }))
    }
  }

  addDebitOrder(payload) {
    this.props.dispatch(createDebitOrdersDcpRequest(payload));
  }

  editDebitOrder(payload) {
    this.props.dispatch(editDebitOrderzRequest(payload));
  }
  resetAfterSucesfulyEdit(){
    this.props.dispatch(resetEditDebitOrderzSuccess());
    reloadPage()
  }
  resetAfterFailedEdit(){
    this.props.dispatch(resetEditDebitOrderzError());
    reloadPage()
  }

  getCustomerDetails() {
    const customer_guid = this.props.PolicyProcessingData.policyProcessingDcp.customer_guid;
    if (customer_guid) {
      this.props.dispatch(getCustomer(customer_guid))
    }
  }
  getPremiums() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllAcessBankCustomerPremiumsRequest({ guid: currentCustomer.guid }))
    }
  }
  getClaims() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAccessBankCustomerClaimsRequest({ guid: currentCustomer.guid }))
    }
  }
  deleteDocument() {
    this.props.dispatch(deletepolicyDocumentRequest({
      policy_guid: this.props.PolicyProcessingData.policyProcessingDcp.guid,
      policy_doc_guid: this.props.PolicyProcessingData.documentsToDelete.guid,

    }
    ));
  }
  setDocumentToDelete(document) {
    this.props.dispatch(setpolicyDocumentsToDelete(document));
    console.log("doc to delete", document)
  }
  resetDocumentToDelete() {
    this.props.dispatch(resetPolicyDocumentToDelete(document))

  }
  getCustomerPolicies() {
    const currentCustomer = this.props.customerData.currentCustomer;
    if (currentCustomer) {
      this.props.dispatch(getAllPoliciesRequest({ guid: currentCustomer.guid }))
    }
  }

  deleteDebitOrder(debitOrder) {
    this.props.dispatch(hardDeleteDebitOrderzRequest(debitOrder));
  }
  setDebitOrderToDelete(debitOrder) {
    this.props.dispatch(setDebitOrderzToDelete(debitOrder));
    console.log("debits, ", debitOrder)
  }
  setDebitOrderToEdit(debitOrder) {
    this.props.dispatch(setDebitOrderzToEdit(debitOrder));
    console.log("debits, ", debitOrder)
  }
  resetDebitOrderToDelete(debitOrder) {
    this.props.dispatch(resetDebitOrderzToDelete(debitOrder));
  }

  handleCreateQuote(customer_guid) {
    hashHistory.push(`admin/create-quote/${customer_guid}`)
  }

  setQuotationToReCreate(policy) {
    this.props.dispatch(setQuotationToReCreate(policy))
    this.handleCreateQuote(policy.customer_guid)
  }


  render() {
    const templateDocuments = this.props.documentGenerationData.templateDocuments;
    const updateActions = {
      Approve: 'Approve',
      Discard: 'Discard',
      ReConfirm: 'ReConfirm',
    }

    if (this.props.PolicyProcessingData.policyProcessingDcp) {
      return (
        <>
          <PolicyHeader
            policy={this.props.PolicyProcessingData.policyProcessingDcp}
            toggleOpenRejectPolicyDialog={this.toggleOpenRejectPolicyDialog.bind(this)}
            policyActiveState={this.props.PolicyProcessingData.policyProcessingDcp.active}
            policyNumber={this.props.PolicyProcessingData.policyProcessingDcp.policy_number}
            plan={this.props.PolicyProcessingData.policyProcessingDcp.quotation.product_name}
            setPolicyToApprove={this.setPolicyToApprove.bind(this)}
            approvePolicy={this.approvePolicy.bind(this)}
            discard={this.discard.bind(this)}
            sendForReConfirm={this.sendForReConfirm.bind(this)}
            setUpdatePolicyAction={(updateAction) => this.setState({ updateAction })}
            resetUpdatePolicyAction={() => this.setState({ updateAction: null })}
            updateActions={updateActions}
            updateAction={this.state.updateAction}
          />

          <ApprovePolicy
            policy={this.props.PolicyProcessingData.policyProcessingDcp}
            policyNumber={this.props.PolicyProcessingData.policyProcessingDcp.policy_number}
            showApprovePolicyProgressAlert={this.props.PolicyProcessingData.policyActionLoader}
            showApprovePolicySuccessAlert={this.props.PolicyProcessingData.policyActionsuccess}
            showApprovePolicyErrorAlert={!!this.props.PolicyProcessingData.policyActionError}
            approvePolicyError={this.props.PolicyProcessingData.policyActionError}
            updateAction={this.state.updateAction}
            approvePolicy={this.approvePolicy.bind(this)}

          />

          <RejectPolicy
            policy_guid={this.props.PolicyProcessingData.policyProcessingDcp.guid}
            openRejectPolicyDialog={this.state.openRejectPolicyDialog}
            toggleOpenRejectPolicyDialog={this.toggleOpenRejectPolicyDialog.bind(this)}
            rejectPolicy={this.rejectPolicy.bind(this)}
            handleAddPolicyNotes={this.handleAddPolicyNotes.bind(this)}
            policy={this.props.PolicyProcessingData.policyProcessingDcp}
            policyNumber={this.props.PolicyProcessingData.policyProcessingDcp.policy_number}
            showRejectPolicyProgressAlert={this.props.PolicyProcessingData.policyRejectActionLoader}
            showRejectPolicySuccessAlert={this.props.PolicyProcessingData.policyRejectActionsuccess}
            showRejectPolicyErrorAlert={!!this.props.PolicyProcessingData.policyRejectActionError}
            rejectPolicyError={this.props.PolicyProcessingData.policyRejectActionError}
          />

          <CancelPolicy
            policy={this.props.PolicyProcessingData.policyProcessingDcp}

            openCancelPolicyDialog={this.state.openCancelPolicyDialog}
            toggleOpenCancelPolicyDialog={this.toggleOpenCancelPolicyDialog.bind(this)}

            cancelPolicy={this.cancelPolicy.bind(this)}

            showCancelPolicyProgressAlert={this.props.PolicyProcessingData.policyNotesLoader}
            showCancelPolicySuccessAlert={this.props.PolicyProcessingData.policyNotesSuccess}
            showCancelPolicyErrorAlert={!!this.props.PolicyProcessingData.policyNotesError}
            cancelPolicyError={this.props.PolicyProcessingData.policyNotesError}

          />

          <UpdateBeneficiary
            policy={this.props.PolicyProcessingData.policyProcessingDcp}
            openUpdateBeneficiaryDialog={this.state.openUpdateBeneficaryyDialog}
            toggleUpdateBeneficaryDialog={this.toggleUpdateBeneficaryDialog.bind(this)}
            getPolicy={this.getPolicy.bind(this)}
          />

          <PolicyNotes
            policy_guid={this.props.PolicyProcessingData.policyProcessingDcp.guid}
            openPolicyNoteDialog={this.state.openPolicyNoteDialog}
            toggleOpenPolicyNoteDialog={this.toggleOpenPolicyNoteDialog.bind(this)}
            handleAddPolicyNotes={this.handleAddPolicyNotes.bind(this)}

            showPolicyNoteProgressAlert={this.props.PolicyProcessingData.policyNotesLoader}
            showPolicyNoteSuccessAlert={this.props.PolicyProcessingData.policyNotesSuccess}
            showPolicyNoteErrorAlert={!!this.props.PolicyProcessingData.policyNotesError}
            PolicyNoteError={this.props.PolicyProcessingData.policyNotesError}
          />
          <PolicyDocuments
            policy_guid={this.props.PolicyProcessingData.policyProcessingDcp.guid}

            openPolicyDocumentDialog={this.state.openPolicyDocumentDialog}
            toggleOpenPolicyDocumentDialog={this.toggleOpenPolicyDocumentDialog.bind(this)}
            DocumentUpload={this.DocumentUpload.bind(this)}
          />

          <MakePayment
            policyActiveState={this.props.PolicyProcessingData.policyProcessingDcp.active}
            policy_number={this.props.PolicyProcessingData.policyProcessingDcp.policy_number}
            policy_guid={this.props.PolicyProcessingData.policyProcessingDcp.guid}
            toggleAddPaymentDialog={this.toggleAddPaymentDialog.bind(this)}
            openAddPaymentDialog={this.state.openAddPaymentDialog}
          />
          <AddDebitOrders
            openAddDebitOrderDialog={this.state.openAddDebitOrderDialog}
            toggleAddDebitOrderDialog={this.toggleAddDebitOrderDialog.bind(this)}
            policy={this.props.PolicyProcessingData.policyProcessingDcp}
            customer={this.props.customerData}

            addDebitOrder={this.addDebitOrder.bind(this)}

            showAddDebitOrderProgressAlert={
              this.props.QuoteData.createDebitOrderLoader
            }
            showAddDebitOrderSuccessAlert={
              this.props.QuoteData.createDebitOrderSuccess
            }
            showAddDebitOrderErrorAlert={
              !!this.props.QuoteData.createDebitOrderError
            }
            createDebitOrderError={
              this.props.QuoteData.createDebitOrderError
            }

          />
          <DownloadDocument
            labels={this.state.labels}
            identifier="Policies"
            entityDocument={
              this.props.documentGenerationData.entityDocument
            }
            entity={this.state.policyToDownload}
            resetEntity={() => this.setState({ policyToDownload: null })}
            templates={templateDocuments}
            getTemplates={() => this.props.dispatch(documentActions.getTemplateDocumentsRequest('Policy'))}
            getTemplatesLoader={this.props.documentGenerationData.getTemplateDocumentloader}
            isEmptyTemplatesResponse={this.props.documentGenerationData.isEmptyTemplates}
            downloadDocument={guid => {
              this.props.dispatch(
                documentActions.downloadDocumentRequest({
                  entity_guid: this.state.policyToDownload.guid,
                  template_guid: guid,
                })
              );
            }}
            resetGetTemplateDocuments={() => {
              this.props.dispatch(documentActions.resetGetTemplateDocuments());
            }}
            resetDownloadDocument={() => {
              this.props.dispatch(documentActions.resetDocumentEntity());
            }}
            showDownloadDocumentProgressAlert={
              this.props.documentGenerationData.downloadDocumentLoader
            }
            showDownloadDocumentSuccessAlert={
              this.props.documentGenerationData.downloadDocumentSuccess
            }
            showDownloadDocumentErrorAlert={
              !!this.props.documentGenerationData.downloadDocumentError
            }
            downloadDocumentError={
              this.props.documentGenerationData.downloadDocumentError
            }
            resetDownloadDocumentSuccessAlert={() => {
              this.props.dispatch(
                documentActions.resetDownloadDocumentSuccess()
              );
            }}
            resetDownloadDocumentErrorAlert={() => {
              this.props.dispatch(
                documentActions.resetDownloadDocumentError()
              );
            }}

            goToTemplatesPage={() => {
              hashHistory.push('admin/view_doc_templates');
            }}
          />

          <DeleteDocument
            deleteDocument={this.deleteDocument.bind(this)}
            DocumentToDelete={this.props.PolicyProcessingData.documentsToDelete}
            resetDocumentToDelete={this.resetDocumentToDelete.bind(this)}
            showDeleteDocumentProgressAlert={this.props.PolicyProcessingData.hardDeleteExpenseLoader}
            showDeleteDocumentSuccessAlert={this.props.PolicyProcessingData.hardDeleteDocumentSuccess}
            showDeleteDocumentErrorAlert={!!this.props.PolicyProcessingData.deleteDocumentError}
            deleteDocumentError={this.props.PolicyProcessingData.deleteClaimDocumentError}

            resetDeleteDocumentSuccessAlert={() => {
              this.props.dispatch(resetHardDeletepolicyDocumentSuccess())
            }}
            resetDeleteDocumentErrorAlert={() => { this.props.dispatch(resetHardDeletepolicyDocumentError()) }}
          />

          <DeleteDebitOrder
            debitOrder={this.props.newDebitOrderData.debitOrderToDelete}
            deleteDebitOrder={this.deleteDebitOrder.bind(this)}
            resetDebitOrderToDelete={this.resetDebitOrderToDelete.bind(this)}
            showDeleteDebitOrderProgressAlert={
              this.props.newDebitOrderData.hardDeleteDebitOrderLoader
            }
            showDeleteDebitOrderSuccessAlert={
              this.props.newDebitOrderData.hardDeleteDebitOrderSuccess
            }
            showDeleteDebitOrderErrorAlert={
              !!this.props.newDebitOrderData.hardDeleteDebitOrderError
            }
            deleteDebitOrderError={
              this.props.newDebitOrderData.hardDeleteDebitOrderError
            }
            resetDeleteDebitOrderSuccessAlert={() => {
              this.props.dispatch(resetHardDeleteDebitOrderzSuccess());
            }}
            resetDeleteDebitOrderErrorAlert={() => {
              this.props.dispatch(resetHardDeleteDebitOrderzError());
            }}


          />

          <EditDebitOrder
            toggleEditDebitOrderDialog={this.toggleEditDebitOrderDialog.bind(this)}
            openEditDebitOrderDialog={this.state.openEditDebitOrderDialog}

            debitOrder={this.props.newDebitOrderData.debitOrderToEdit}
            customer={this.props.customerData}
            policy={this.props.PolicyProcessingData.policyProcessingDcp}
            editDebitOrder={this.editDebitOrder.bind(this)}

            resetAfterSucesfulyEdit={this.resetAfterSucesfulyEdit.bind(this)}
            resetAfterFailedEdit={this.resetAfterFailedEdit.bind(this)}

            loader={this.props.newDebitOrderData.editDebitOrderLoader}
            debitOrderEditedSuccessfully={this.props.newDebitOrderData.editDebitOrderSuccess}
            debitEditedFailed={this.props.newDebitOrderData.editDebitOrderFailed}
            errors={this.props.newDebitOrderData.editDebitOrderError}
          />
          <section style={{
            background: "#FFFFFF",
            borderRadius: "8px",
            height: "auto",
            marginTop: "30px",
            padding: "30px"
          }}>
            <NavigationWrapper
              policy={this.props.PolicyProcessingData.policyProcessingDcp}
              getAllPayments={this.getAllPayments.bind(this)}
              getAllDebitOrders={this.getAllDebitOrders.bind(this)}
              payments={this.props.PolicyProcessingData.policiesPaymentByGuid}
              loader={this.props.PolicyProcessingData.get_PaymentsByPolicyGuid_loader}
              getCustomerDetails={this.getCustomerDetails.bind(this)}
              customer={this.props.customerData}
              newDebitOrderData={this.props.newDebitOrderData.debitOrders}
              debitOrderloader={this.props.newDebitOrderData.getDebitOrderLoader}


              templateDocuments={this.props.documentGenerationData.templateDocuments}
              setPolicyToDownloadDocumentFor={(policy) => this.setState({ policyToDownload: policy })}


              toggleOpenCancelPolicyDialog={this.toggleOpenCancelPolicyDialog.bind(this)}
              toggleUpdateBeneficaryDialog={this.toggleUpdateBeneficaryDialog.bind(this)}
              toggleOpenPolicyDocumentDialog={this.toggleOpenPolicyDocumentDialog.bind(this)}
              toggleOpenPolicyNoteDialog={this.toggleOpenPolicyNoteDialog.bind(this)}
              toggleAddPaymentDialog={this.toggleAddPaymentDialog.bind(this)}
              toggleUpgradeDialog={() => this.setState({ openUpgradeDialog: !this.state.openUpgradeDialog })}

              toggleAddDebitOrderDialog={this.toggleAddDebitOrderDialog.bind(this)}

              policyGuid={this.props.PolicyProcessingData.policyProcessingDcp.guid}

              getPremiums={this.getPremiums.bind(this)}
              premiums={this.props.AccessBankPremiumData.premiums}
              premiumsloader={this.props.AccessBankPremiumData.get_premiums_loader}

              getClaims={this.getClaims.bind(this)}
              claims={this.props.AccessBankClaimsData.claims}
              claimsloader={this.props.AccessBankClaimsData.get_claims_loader}

              setDocumentToDelete={this.setDocumentToDelete.bind(this)}

              policyActiveState={this.props.PolicyProcessingData.policyProcessingDcp.active}

              getCustomerPolicies={this.getCustomerPolicies.bind(this)}
              setDebitOrderToDelete={this.setDebitOrderToDelete.bind(this)}

              setQuotationToReCreate={this.setQuotationToReCreate.bind(this)}

              setDebitOrderToEdit={this.setDebitOrderToEdit.bind(this)}
              toggleEditDebitOrderDialog={this.toggleEditDebitOrderDialog.bind(this)}
              openEditDebitOrderDialog={this.state.openEditDebitOrderDialog}
              debitOrderToEdit={this.props.newDebitOrderData.debitOrderToEdit}

              customer_guid={this.props.PolicyProcessingData.policyProcessingDcp.customer_guid}

            />
          </section>
        </>
      )
    } else {
      return "loading"
    }


  }

}
export default connect((state) => ({
  customerData: state.currentCustomer,
  AccessBankPremiumData: state.AccessBankPremiumData,
  AccessBankClaimsData: state.AccessBankClaimsData,
  documentGenerationData: state.documentGeneration,
  PoliciesDashboardData: state.PoliciesDashboardData,
  PolicyProcessingData: state.PolicyProcessingData,
  customerScreenData: state.customerScreen,
  newDebitOrderData: state.newDebitOrder,
  QuoteData: state.quoteData,
  globalData: state.global,
}))(PoliciesProcessing);
