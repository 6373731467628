import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import ButtonInc from "../../../../shared-ui/ButtonInc";
import MultipleSelectChip from "../../../PartnerStatus/components/Edit/MultipleSelectChip";
import { updateClaim } from "../../lib/api";
import { isReadOnlyStatus, updateStatus } from "../../lib/helpers";
import AlertDialog from "../../../../components/AlertDialog/index.es6";

const PersonalAccidentForm = ({
  claim,
  metaData,
  conditions,
  hospitals,
  rejectionReason,
  refetchClaim,
}) => {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setFormData({
      event_date: claim.event_date || "",
      personal_accident_total_amount:
        claim.personal_accident_total_amount || "",
      high_risk: claim.high_risk || false,
      doc_channel: claim.doc_channel || [],
      accidental:
        claim.accidental !== undefined ? claim.accidental.toString() : "",
      birth_certificate_valid: claim.birth_certificate_valid || false,
      verify_by: claim.verify_by,
      documentation_received_time:
        claim.documentation_received_time !== undefined
          ? claim.documentation_received_time.toString()
          : "",
      comments: claim.comments || "",
      received_files: claim.received_files,
      status: claim.status,
    });
  }, [claim]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log({ name, value, type });

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleVerificationChange = (newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      verify_by: newValue.join(","),
    }));
  };

  const handleDocChannelChange = (event) => {
    const { value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      doc_channel: [value],
    }));
  };

  const handleCloseSuccessAlert = () => {
    setShowSuccessAlert(false);
  };

  const handleCloseErrorAlert = () => {
    setShowErrorAlert(false);
  };

  const getErrorMessage = (error) => {
    if (error.response && error.response.data) {
      return (
        error.response.data.message ||
        error.response.data.error ||
        "An unknown error occurred"
      );
    }
    return error.message || "Failed to update claim";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedFormData = updateStatus(formData);

    const payload = {
      ...claim,
      ...updatedFormData,
      verify_by: updatedFormData.verify_by,
      documentation_received_time: updatedFormData.documentation_received_time,
    };

    new Promise((resolve, reject) => {
      setIsSubmitting(true);

      updateClaim(payload)
        .then((response) => {
          setShowSuccessAlert(true);
          refetchClaim();
          resolve();
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          setErrorMessage(message);
          setShowErrorAlert(true);
          reject();
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  };

  const formatDatetimeLocal = (datetime) => {
    const date = new Date(datetime);
    const isoString = date.toISOString();
    return isoString.slice(0, 16); // Ensures format YYYY-MM-DDTHH:MM
  };

  const verification = [
    "Hospital_Invoice",
    "Doctor_Letter",
    "Hospital_Records",
    "Conversation_with_doctor",
    "Other_Verification",
  ];
  // const verification = metaData ? metaData.verify_by['Personal_Accident'] : ["Hospital_Invoice", "Doctor_Letter", "Hospital_Records", "Conversation_with_doctor", "Other_Verification"];
  const isReadOnly = isReadOnlyStatus(formData.status);
  console.log("formdata: ", formData);
  console.log("isReadOnly: ", isReadOnly);
  const verify_by = formData.verify_by ? formData.verify_by.split(",") : [];

  return (
    <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
      {isSubmitting && (
        <AlertDialog
          custom
          show={true}
          size="sm"
          style={{ marginTop: "0", top: "30vh" }}
          confirmBtnText={"ok"}
          showCancel={false}
          showConfirm={false}
          cancelBtnText={"cancel"}
          showTitle={false}
          confirmBtnCssClass
        >
          <CircularProgress />
          <h2>Updating Claim </h2>
        </AlertDialog>
      )}

      {showSuccessAlert && (
        <AlertDialog
          success
          title="Success!"
          onConfirm={handleCloseSuccessAlert}
          confirmBtnText="OK"
        >
          Claim details updated successfully
        </AlertDialog>
      )}

      {showErrorAlert && (
        <AlertDialog
          error
          title="Error!"
          onConfirm={handleCloseErrorAlert}
          confirmBtnText="OK"
        >
          {errorMessage}
        </AlertDialog>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date of Event"
              type="date"
              name="event_date"
              value={formData.event_date || ""}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
              disabled={isReadOnly}
              format="YYYY-MM-DD HH:MM"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Claim Total Amount"
              type="number"
              name="personal_accident_total_amount"
              value={formData.personal_accident_total_amount || ""}
              onChange={handleChange}
              required
              disabled={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Document Received Via</InputLabel>
              <Select
                name="doc_channel"
                value={
                  formData.doc_channel && formData.doc_channel.length > 0
                    ? formData.doc_channel[0]
                    : ""
                }
                onChange={handleDocChannelChange}
                disabled={isReadOnly}
              >
                <MenuItem value="">Please select</MenuItem>
                <MenuItem value="whatsapp">WhatsApp</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Accidental</InputLabel>
              <Select
                name="accidental"
                value={
                  formData.accidental === "false"
                    ? "false"
                    : formData.accidental === "true"
                    ? "true"
                    : ""
                }
                onChange={handleChange}
                disabled={isReadOnly}
              >
                <MenuItem value="">Please select</MenuItem>
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <MultipleSelectChip
              fieldLabel="Verification Received"
              fields={[...verify_by]}
              setFields={handleVerificationChange}
              constantFields={verification}
              multiple={true}
              displayLabel={false}
              style={{ width: "100%", marginLeft: "0px" }}
              disabled={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.birth_certificate_valid || false}
                  onChange={handleChange}
                  name="birth_certificate_valid"
                />
              }
              label="Birth Certificate Valid"
              disabled={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.high_risk || false}
                  onChange={handleChange}
                  name="high_risk"
                  disabled={isReadOnly}
                />
              }
              label="High Risk"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="All Documentation Complete Date"
              type="datetime-local"
              name="documentation_received_time"
              value={
                formData.documentation_received_time
                  ? formatDatetimeLocal(formData.documentation_received_time)
                  : ""
              }
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Comment"
              name="comments"
              value={formData.comments || ""}
              onChange={handleChange}
              multiline
              rows={4}
              disabled={isReadOnly}
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "center" }}>
            <ButtonInc
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || isReadOnly}
            >
              Update Claim details
            </ButtonInc>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default PersonalAccidentForm;
