import React, { useState } from "react";
import ButtonInc from '../../../../../../../shared-ui/ButtonInc';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@mui/material";
import AlertDialog from "../../../../../../../components/AlertDialog/index.es6";
import ModalInc from "../../../../../../../shared-ui/ModalInc";
import { updateBeneficiary } from "../../../../../../../lib/requests.es6";
import { SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from '@mui/material/MenuItem';
import { RELATIONSHIP_POOL } from '../../../../../../../containers/NewProductList/PoductConstants';

export default function UpdateBeneficiary(props) {
  if (!props.policy) {
    return "";
  }

  const [formData, setFormData] = useState({
    beneficiary_name: props.policy.beneficiary_name || "",
    beneficiary_msisdn: props.policy.beneficiary_msisdn || "",
    beneficiary_date_of_birth: props.policy.beneficiary_date_of_birth || "",
    beneficiary_relationship: props.policy.additional_details && props.policy.additional_details.beneficiary_relationship ? 
                            props.policy.additional_details.beneficiary_relationship :
                            props.policy.beneficiary_relationship || "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      await updateBeneficiary({
        policy_guid: props.policy.guid,
        ...formData
      });
      setShowSuccessAlert(true);
    } catch (error) {
      setErrorMessage(error.message || "Failed to update beneficiary");
      setShowErrorAlert(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSuccess = () => {
    props.toggleUpdateBeneficaryDialog();
    props.getPolicy(props.policy.policy_number);
  };

  const handleError = () => {
    setShowErrorAlert(false);
  };

  return (
    <React.Fragment>
      <AlertDialog
        custom
        show={isSubmitting}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        showConfirm={false}
        showCancel={false}
        showTitle={false}
      >
        <CircularProgress />
        <h2>Updating beneficiary details...</h2>
      </AlertDialog>

      <AlertDialog
        success
        show={showSuccessAlert}
        size="sm"
        title="Beneficiary Updated"
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={handleSuccess}
        confirmBtnText="OK"
        showCancel={false}
        showConfirm={false}
      >
        <div>
          <ButtonInc 
            variant="contained" 
            onClick={handleSuccess} 
            style={{ background: 'green' }}
          >
            OK
          </ButtonInc>
        </div>
      </AlertDialog>

      <AlertDialog
        show={showErrorAlert}
        danger
        title="Error Updating Beneficiary"
        onConfirm={handleError}
        confirmBtnText="Try again"
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {errorMessage}
      </AlertDialog>

      <ModalInc
        modalTitle="Update Beneficiary Details"
        subTitle="Update the beneficiary information below"
        open={props.openUpdateBeneficiaryDialog}
        onClose={props.toggleUpdateBeneficaryDialog}
        fullWidth
      >
        <ValidatorForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextValidator
                name="beneficiary_name"
                label="Beneficiary Name"
                value={formData.beneficiary_name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Beneficiary name is required']}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextValidator
                name="beneficiary_msisdn"
                label="Phone Number"
                value={formData.beneficiary_msisdn}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                validators={['required']}
                errorMessages={['Phone number is required']}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextValidator
                name="beneficiary_date_of_birth"
                label="Date of Birth"
                type="date"
                value={formData.beneficiary_date_of_birth}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                validators={['required']}
                errorMessages={['Date of birth is required']}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectValidator
                labelId="select-relationship"
                id="select-relationship"
                value={formData.beneficiary_relationship}
                name="beneficiary_relationship"
                type="text"
                label="Beneficiary Relationship"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select beneficiary relationship']}
                fullWidth
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.005em",
                  color: "#000000",
                }}
              >
                {Object.entries(RELATIONSHIP_POOL).map(([key, value]) => (
                  <MenuItem
                    key={key}
                    value={value}
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "16px",
                      letterSpacing: "0.005em",
                      color: "#000000",
                    }}
                  >
                    {value}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                    style={{ marginTop: "20px", float: "right" }}
                  >
                    Update Beneficiary
                  </ButtonInc>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ButtonInc
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ marginTop: "20px" }}
                    onClick={props.toggleUpdateBeneficaryDialog}
                  >
                    Go back
                  </ButtonInc>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </ModalInc>
    </React.Fragment>
  );
} 