import axios from 'axios';
import {
  countryCode,
  getAuthorizationToken,
  getPartnerGuid,
  getRefreshToken, getUserGuid,
} from './access.es6';
import {
  getEnvironmentInformation,
  ignoreMaxDaysAllowedAfterDischargeOrDeath,
} from './utils.es6';

const querystring = require('query-string');

let EnvironmentalInformation = null;
let BASE_URL;
let CENTRAL_AUTHENTICATION_BASE_URL;
let AUTH_BASE_URL;

EnvironmentalInformation = getEnvironmentInformation();

if (EnvironmentalInformation) {
  BASE_URL = EnvironmentalInformation.servicesURLs.api;
  CENTRAL_AUTHENTICATION_BASE_URL = EnvironmentalInformation.servicesURLs.centralAuthentication;
  AUTH_BASE_URL = EnvironmentalInformation.servicesURLs.authApplication;
} else {
  console.warn(
    `Environment ${window.location.origin} configuarations could not be determined`
  );
}

function addPartnerQuery(config) {
  let url = new URL(config.url);

  const excludedPath = 'api/partners/selected';

  if (url.pathname.includes(excludedPath)) {
    return config;
  }

  if (!url.searchParams.has('partner')) {
    url.searchParams.set('partner', getPartnerGuid());
  }

  config.url = url.toString();
  return config;
}

const crudApiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Language': 'en',
  },
});

crudApiInstance.interceptors.request.use((config) => {
  return addPartnerQuery(config);
});

const bulkSmsInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Language': 'en',
  },
});
bulkSmsInstance.interceptors.request.use((config) => {
  return addPartnerQuery(config);
});

const reportsInstance = axios.create({
  baseURL: BASE_URL,
  responseType: 'arraybuffer',
});
reportsInstance.interceptors.request.use((config) => {
  return addPartnerQuery(config);
});

const loyaltyInstance = axios.create({
  baseURL: `${BASE_URL}api/loyaltyrenewal`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Language': 'en',
    'Access-Control-Allow-Origin': '*',
  },
});
loyaltyInstance.interceptors.request.use((config) => {
  return addPartnerQuery(config);
});

const centralAuthenticationInstance = axios.create({
  baseURL: CENTRAL_AUTHENTICATION_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});

const customerApplicationInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});
customerApplicationInstance.interceptors.request.use((config) => {
  return addPartnerQuery(config);
});

const paymentServiceInstance = axios.create({
  baseURL: BASE_URL,

  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
});
paymentServiceInstance.interceptors.request.use((config) => {
  return addPartnerQuery(config);
});

const authInstance = axios.create({
  baseURL: AUTH_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Language': 'en',
  },
});



export function registerCustomer(args) {
  // TODO: This should be part of the UI (i.e. current partner)
  args.partner_guid = getPartnerGuid();
  args.registration_channel = 'WebClient';
  // add the Authorization header
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/customers/register?partner=${getPartnerGuid()}`, args);
}

export function loginUser(args) {
  const username = encodeURIComponent(args.username);
  const password = encodeURIComponent(args.password);

  return authInstance.post(`oauth/token?grant_type=password&scope=all&username=${username}&password=${password}`,
    querystring.stringify({
      username,
      password,
      grant_type: 'password'
    }), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      'Authorization': `Basic ${btoa(`${EnvironmentalInformation.credentials.clientID}:${EnvironmentalInformation.credentials.clientSecret}`)}`,
    }
  });
}

export function getStarted(args) {
  return crudApiInstance.post(`/public/create_outbound_email`, args.payload);
}

export function logoutUser() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    "api/users/logout",
    { data: {} }
  );
}


export function getUserInformation() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.post(`/auth/realms/${EnvironmentalInformation.credentials.realm}/protocol/openid-connect/userinfo`);
}

export function getUser(userId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.post(`/auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${userId}`);
}

export function getUserPartners(args) {
  // TODO: Remove this when done
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post('/api/partners/selected', args);
}


export function refreshSession() {
  const refreshToken = getRefreshToken();
  const urlQueryParams = querystring.stringify({
    refresh_token: refreshToken,
    client_id: EnvironmentalInformation.credentials.clientID,
    client_secret: EnvironmentalInformation.credentials.clientSecret,
    grant_type: 'refresh_token'
  });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  return centralAuthenticationInstance.post(
    `/auth/realms/${EnvironmentalInformation.credentials.realm}/protocol/openid-connect/token`,
    urlQueryParams,
    { headers }
  );
}

export function recoverPassword(userName) {
  return crudApiInstance.put(`forgotpassword/${userName}`, { data: {} });
}


export function listCustomers() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/customers?partner=${getPartnerGuid()}`, {
    data: {},
  });
}

export function purchasePolicy(args) {
  args.channel = 'WebClient';
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/products/buy?partner=${getPartnerGuid()}`, args);
}

export function searchCustomerByMsisdn(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(
    `api/customers/find?msisdn=${args.msisdn
    }&partner=${getPartnerGuid()}&size=${args.size}&page=${args.page}`,
    { data: {} }
  );
}

export function searchCustomerByName(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(
    `api/customers/find?name=${args.name}&partner=${getPartnerGuid()}&size=${args.size
    }&page=${args.page}`,
    { data: {} }
  );
}

export function searchCustomerByNationalId(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(
    `api/customers/find?national_id=${args.national_id}&partner=${getPartnerGuid()}&size=${args.size
    }&page=${args.page}`,
    { data: {} }
  );
}

export function searchCustomerByExternalId(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(
    `api/customers/find?external_identifier=${args.external_identifier}&partner=${getPartnerGuid()}&size=${args.size
    }&page=${args.page}`,
    { data: {} }
  );
}

export function searchCustomerByPolicyNumber(args) {


  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    `api/policies/complex/policy_number?policy_number=${args.policy_number}&partner=${getPartnerGuid()}`,
    { data: {} }
  );
}

export function submitClaim(args) {
  args.payload.ussd_initiated = false;
 
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  
  let params = `?partner=${getPartnerGuid()}`;

  if (args.ignoreMaxDaysAllowed) {
    params = `?partner=${getPartnerGuid()}&${ignoreMaxDaysAllowedAfterDischargeOrDeath}=${args.ignoreMaxDaysAllowed
      }`;
  }
  return crudApiInstance.post(`/api/claims${params}`, args.payload);
}

export function getclaimEnum() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/metadata/claim?partner=${getPartnerGuid()}`);
}

export function updateClaim(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;


  let params = "";
  if (args.ignoreMaxDays) {
    params = `?${ignoreMaxDaysAllowedAfterDischargeOrDeath}=${args.ignoreMaxDays
      }`;
  }

  args.editor_guid = getUserGuid();

  return crudApiInstance.put(`/api/claims/${args.guid}${params}`, args);
}

export function revertExpiredClaim(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/claims/revert_expired/${args.guid}`);
}

export function getOpenClaims() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/claims/open?partner=${getPartnerGuid()}`);
}

export function getPayableClaims() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/claims/payable?partner=${getPartnerGuid()}`);
}

export function getClosedClaims() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/claims/closed?partner=${getPartnerGuid()}`);
}

export function getClaimsByStatus(status) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const url = `/api/claims?partner=${getPartnerGuid()}&status=${status}`;
  return crudApiInstance.get(url);
}

export function getClaimAssessments(claimGuid) {
  console.log('getClaimAssessments', claimGuid);
  
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const url = `/api/assessment?partner=${getPartnerGuid()}&claim_guid=${claimGuid}`;
  return crudApiInstance.get(url);
}

export function searchClaim(criteria) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  let params = '';
  if (criteria.guid) {
    params += `&guid=${criteria.guid}`;
  }
  if (criteria.status) {
    params += `&status=${criteria.status}`;
  }
  if (criteria.type) {
    params += `&type=${criteria.type}`;
  }
  if (criteria.page >= 0 && criteria.size) {
    params += `&page=${criteria.page}`;
    params += `&size=${criteria.size}`;
  }
  const url = `/api/claims?partner=${getPartnerGuid()}${params}`;
  return crudApiInstance.get(url);
}

export function getSummaryClaims() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/claims/summary?partner=${getPartnerGuid()}`);
}

export function deletePolicy(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(
    `api/policies/${args}?partner=${getPartnerGuid()}`,
    { data: {} }
  );
}

export function getCustomerInfo(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(
    `api/customers/${args}?partner=${getPartnerGuid()}`,
    { data: {} }
  );
}

export function getCurrentProfile(guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.get(`auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${guid}`, { data: {} });
}

export function resetPassword(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.put(`auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${args.guid}/execute-actions-email?lifespan=300`, ["UPDATE_PASSWORD"]);

}

export function updateCurrentProfile(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const userGuid = args.userGuid || args.guid;
  return crudApiInstance.put(`auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${userGuid}`, args);
}
export function updateUserRolesAndPartners(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const id = args.id;
  return centralAuthenticationInstance.put(`auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${id}`, args);
}

export function assignUserRoleGroup(args) {
  console.log('assignUserRoleGroup', args);
  
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const userId = args.userId;
  const roleId = args.roleId;
  return centralAuthenticationInstance.put(`auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${userId}/groups/${roleId}`);
}

export function revokeUserRoleGroup(args) {
  console.log('revokeUserRoleGroup', args);
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const userId = args.userId;
  const roleId = args.roleId;
  return centralAuthenticationInstance.delete(`auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${userId}/groups/${roleId}`);
}

export function updateCustomer(args) {
  // TODO: This should be part of the UI (i.e. current partner)
  args.partner_guid = getPartnerGuid();
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const customerGuid = args.guid;
  return customerApplicationInstance.put(`api/customers/${customerGuid}`, args);
}

export function updatePolicy(args) {
  // TODO: This should be part of the UI (i.e. current partner)
  args.partner_guid = getPartnerGuid();
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const policyGuid = args.guid;
  return crudApiInstance.put(`api/policies/${policyGuid}`, args);
}

export function listPaidProducts() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    `api/products?type=paid&partner=${getPartnerGuid()}&active_only=${true}`,
    { data: {} }
  );
}

export function getReport(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post('/api/reports', args);
}

export function getUsers() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.get(`/auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users?q=partners:${getPartnerGuid()}&enabled=true&max=250`);
}

export function getSmsTemplates(arg) {
  let params = '';
  if (arg.page >= 0 && arg.size) {
    params += `&page=${arg.page}`;
    params += `&size=${arg.size}`;
  }
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    `/api/sms_templates?partner=${getPartnerGuid()}${params}`
  );
}

export function getSupportedParameters(partner) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/metadata/sms_template?partner=${partner}`);
}
export function createSmsTemplate(smsTemplate) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/sms_templates`, smsTemplate);
}

export function updateSmsTemplate(smsTemplate) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(
    `/api/sms_templates/${smsTemplate.guid}`,
    smsTemplate
  );
}

export function deleteSmsTemplate(smsTemplateGuid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/sms_templates/${smsTemplateGuid}`);
}

export function createUser(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.post(`/auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users`, args);
}

export function getCountries() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/countries`);
}

export function createCountry(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post('/api/countries', args);
}

export function updateCountry(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/countries/${args.code}`, args);
}

export function deleteCountry(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/countries/${args}`);
}

export function getPartners() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get('/api/partners', { data: {} });
}

export function createPartner(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post('/api/partners', args);
}

export function getPartner(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/partners/${args}`, { data: {} });
}

export function getProducts(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  let url = `/api/products?partner=${args.partnerGuid}`;
  if (args.type) {
    url += `&type=${args.type}`;
  }

  if (args.activeOnly) {
    url += `&active_only=${args.activeOnly}`;
  }

  return crudApiInstance.get(url, { data: {} });
}

export function createProduct(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/products?partner=${getPartnerGuid()}`, args);
}

export function createQuote(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/quotations/create?partner=${getPartnerGuid()}`, args);
}


export function changeQuote(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/policies/complex/change_quotation?policy_guid=${args.policy_guid}&quotation_guid=${args.quotation_guid}&customer_guid=${args.customer_guid}&start_date=${args.start_date}&partner=${getPartnerGuid()}`, args);
}

export function updateQuote(args){
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/policies/complex/update_quotation?policy_guid=${args.policy_guid}&quotation_guid=${args.quotation_guid}&customer_guid=${args.customer_guid}&partner=${getPartnerGuid()}`, args);
}

export function buyPolicy(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/policies/complex/purchase?partner=${getPartnerGuid()}`, args);
}

export function getCustomerPolicies(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/policies/complex/customer?customer_guid=${args.guid}&partner=${getPartnerGuid()}`);
}


export function getCustomerQuotes(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/quotations/customer?customer_guid=${args.guid}&partner=${getPartnerGuid()}`);
}


export function getComplexCustomerPremiums(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/policies/complex/premiums?customer_guid=${args.guid}&partner=${getPartnerGuid()}`);
}

export function getSmsActivities(msisdn) {
  axios.defaults.headers.common.Authorization = `Bearer None`;
  return crudApiInstance.get(`api/sms/customer/${msisdn}?partner=${getPartnerGuid()}`);
}

export function cancelV2Policy(policy_guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/policies/complex?policy_guid=${policy_guid}&partner=${getPartnerGuid()}`);
}

export function updateBeneficiary(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/policies/complex/beneficiary?policy_guid=${args.policy_guid}&partner=${getPartnerGuid()}`, args);
}

export function selectPolicies(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/quotations/event?customer_guid=${args.customer_guid}&policy_guid=${args.policy_guid}&event_date=${args.event_date}&partner=${getPartnerGuid()}`);
}

export function initiateClaimdcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/claims/complex/initiate_claim?partner=${getPartnerGuid()}`, args);
}

export function getCustomerClaimsDcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/claims/complex/customer?customer_guid=${args.guid}&partner=${getPartnerGuid()}`);
}

export function getClaimsDcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const partnerGuid = getPartnerGuid();
  let url = `api/claims/complex/statuses?partner=${partnerGuid}`
  if (args.tag) {
    url += `&tag=${args.tag}&page=${args.page}&size=${args.size}`;
  }

  const result = crudApiInstance.post(url, args);

  const paginatedClaimPartners = ['ngsugguid', 'kymbritam']

  if (!paginatedClaimPartners.includes(partnerGuid)) {
    return result.then(response => {
      return {
        data: response.data.data
      };
    });
  }
  return result;
  //console.log("result", result)
}
export function getClaimsDcpFiltered(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const partnerGuid = getPartnerGuid();
  let url = `api/claims/complex?partner=${partnerGuid}`


  // 'https://claim-admin-dcp.staging.aspin-inclusivity.com/api/claims/complex?partner=nnnn&customer_guid=111&facility=kenyatta&facility_code=KNH&type=hospital&status=approved&tag=hospital&start_date=22%2F10%2F2024&end_date=30%2F10%2F24&page=1&size=10' 


  if (args.tag) {
    url += `&tag=${args.tag}&page=${args.page}&size=${args.size}&customer_guid=${args.customer_guid}&facility=${args.facility}&facility_code=${args.facility_code}&type=${args.type}&status=${args.status}&start_date=${args.start_date}&end_date=${args.end_date}`;


   
  }

  const result = crudApiInstance.get(url, args);

  const paginatedClaimPartners = ['ngsugguid', 'kymbritam']

  if (!paginatedClaimPartners.includes(partnerGuid)) {
    return result.then(response => {
      return {
        data: response.data.data
      };
    });
  }
  return result;
  //console.log("result", result)
}


export function getPoliciesDcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/policies/complex/status?status=${args.status}&partner=${getPartnerGuid()}`, args);
}

export function getPoliciesDcpByBranch(args) {

  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  let url = `api/policies/complex/status?status=${args.status}&partner=${getPartnerGuid()}`;
  if (args.branch) {
    url += `&branch=${args.branch}`;
  }
  return crudApiInstance.get(url, args);
}

export function getPaymentsDcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/payments/status/${getPartnerGuid()}?status=${args.status}`, args);

}

export function claimBulkApprove(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  if(args.status === 'decision_pending'){
  return crudApiInstance.post(`/api/claims/complex/bulk_approval_selected?partner=${getPartnerGuid()}&claim_action=approve`, args);
  }
  return crudApiInstance.post(`/api/claims/complex/bulk_approval_paid_selected?partner=${getPartnerGuid()}`, args);
}


export function createExpense(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/claims/complex/benefit_usages?claim_guid=${args.claim_guid}&partner=${getPartnerGuid()}`, args);
}

export function deleteExpense(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/claims/complex/benefit_usages?claim_guid=${args.claim_guid}&benefit_usage_guid=${args.expense_guid}&partner=${getPartnerGuid()}`);
}

export function editExpense(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/claims/complex/expenses?expense_guid=${args.expense_guid}&partner=${getPartnerGuid()}`, args);
}

export function addClaimDocument(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/claims/complex/documents?claim_guid=${args.claim_guid}&partner=${getPartnerGuid()}`, args);

}

export function addBulkPolicyOnboarding(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/bulk-policy-onboarding?partner=${getPartnerGuid()}`, args);
}

export function deleteClaimDocument(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/claims/complex/documents?claim_guid=${args.claim_guid}&claim_doc_guid=${args.claim_doc_guid}&partner=${getPartnerGuid()}`);
}

export function claimActions(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;

  if(args.claim_action === 'fail_confirm'){
    return crudApiInstance.post(`api/claims/complex/actions/${args.claim_action}?claim_guid=${args.claim_guid}&partner=${getPartnerGuid()}`, {failed_confirmation_reason: args.reason});
  }
  if(args.claim_action === 'discard'){
    return crudApiInstance.post(`api/claims/complex/actions/${args.claim_action}?claim_guid=${args.claim_guid}&partner=${getPartnerGuid()}`, {failed_confirmation_reason: 'discarding it'});
  }

  return crudApiInstance.post(`api/claims/complex/actions/${args.claim_action}?claim_guid=${args.claim_guid}&partner=${getPartnerGuid()}`, args.body);
}

export function policyActions(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/policies/complex?policy_guid=${args.policy_guid}&partner=${getPartnerGuid()}`, args.body);
}

export function paymentApproveAction(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/payments/${args.guid}?partner=${getPartnerGuid()}`, args.body);

}

export function paymentRejectAction(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/payments/${args.guid}?partner=${getPartnerGuid()}`, args.body);

}

export function policyRejectActions(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/policies/complex?policy_guid=${args.policy_guid}&partner=${getPartnerGuid()}`, args.body);
}

export function claimNotes(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/claims/complex?claim_guid=${args.claim_guid}&partner=${getPartnerGuid()}`, args);
}

export function claimFacilityNotes(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/claims/complex?claim_guid=${args.claim_guid}&partner=${getPartnerGuid()}`, args);
}

export function policyNotes(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  if(!args.type) args['type'] = 'General'
  return crudApiInstance.post(`api/policies/complex/notes?policy_guid=${args.policy_guid}&partner=${getPartnerGuid()} `, args);
}

export function getClaimProcessingDcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/claims/complex/claim?guid=${args.guid}&partner=${getPartnerGuid()}`);
}

export function getPolicyProcessingDcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/policies/complex/policy_number?policy_number=${args.policy_number}&partner=${getPartnerGuid()}`);
}

export function getAudits(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const page = args.page || 0;
  const size = args.size || 100;
  return crudApiInstance.get(`api/audit_events?partner_guid=${getPartnerGuid()}&entity_guid=${args.entity_guid}&page=${page}&size=${size}`);
}

export function getPaymentProcessingDcp(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/payments/${args.guid}?partner=${getPartnerGuid()}`);

}

export function   getPaymentByPolicyGuid(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/payments?partner=${getPartnerGuid()}&policy_guid=${args.guid}&policy_number=${args.policy_number}`);
}

export function getPreauthDetails(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/claim/preauthorization?guid=${args.guid}&partner=${getPartnerGuid()}`);

}

export function getLous(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/claim/preauthorization/customer?customer_guid=${args.customer_guid}&partner=${getPartnerGuid()}`);
}

export function getBenefitUsageBalances(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/claims/complex/benefit_usages/balances?dependant_guid=${args.dependant_guid}&partner=${getPartnerGuid()}`);
}

export function createLou(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/claim/preauthorization/create?partner=${getPartnerGuid()} `, args);
}


export function editLou(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/claim/preauthorization?partner=${getPartnerGuid()} `, args);
}

export function deleteLou(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/claim/preauthorization?preauthorization_guid=${args.guid}&partner=${getPartnerGuid()} `);
}


export function createHospital(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/configs/hospitals?partner=${getPartnerGuid()} `, args);
}

export function deleteHospital(id) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/configs/hospitals?id=${id}&partner=${getPartnerGuid()}`);
}

export function getHospitals() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/configs/hospitals?partner=${getPartnerGuid()}`);
}

export function searchHospitals(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/configs/hospitals/search?query=${args.query}&partner=${getPartnerGuid()}`);
}

export function createDiagnosis(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/configs/health-diagnosis?partner=${getPartnerGuid()} `, args);
}

export function deleteDiagnosis(id) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/configs/health-diagnosis?id=${id}&partner=${getPartnerGuid()}`);
}

export function getDiagnosis() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/configs/health-diagnosis?partner=${getPartnerGuid()}`);
}

export function searchDiagnosis(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/configs/health-diagnosis/search?query=${args.query}&partner=${getPartnerGuid()}`);
}

export function getPolicyDependents(national_id) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/policies/complex/dependants?national_id=${national_id}&partner=${getPartnerGuid()}`);
}

export function editProduct(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/products/${args.product_guid}?partner=${getPartnerGuid()}`, args);
}


export function editPremium(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/product_premiums/${args.guid}?partner=${getPartnerGuid()}`, args);
}

export function editBenefit(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  let benefit_path_var = '';
  if (args.benefitType === 'cashback') {
    benefit_path_var = 'cashback_indemnity';
  }
  if (args.benefitType === 'fixed') {
    benefit_path_var = 'fixed_indemnity';
  }
  if (args.benefitType === 'variable') {
    benefit_path_var = 'variable_indemnity';
  }
  return crudApiInstance.put(`/api/${benefit_path_var}/${args.guid}?partner=${getPartnerGuid()}`, args);
}

export function createPremium(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/product_premiums?partner=${getPartnerGuid()}`, args);
}

export function hardDeletePremium(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/product_premiums/${args.guid}?partner=${getPartnerGuid()}`);
}

export function createPremiumConfig(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/extended_membership_premium?partner=${getPartnerGuid()}`, args);
}
export function getFeatureFlags() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/feature-flags?partner=${getPartnerGuid()}`);
}

export function addFeatureFlag(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/feature-flags?partner=${getPartnerGuid()}`, args);
}

export function updateFeatureFlag(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/feature-flags/${args.name}?partner=${getPartnerGuid()}`, args);
}

export function deleteFeatureFlag(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/feature-flags/${args.name}?partner=${getPartnerGuid()}`, args);
}

export function updateExtendedConfig(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/membership_configs/${args.guid}?partner=${getPartnerGuid()}`, args);
}
export function createExtendedConfig(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/membership_configs?partner=${getPartnerGuid()}`, args);
}

export function getPremiumConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/extended_membership_premium/${args}/all?partner=${getPartnerGuid()}`);
}

export function createFixedIndemnityBenefit(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/fixed_indemnity?partner=${getPartnerGuid()}`, args);
}

export function createVairableIndemnityBenefit(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/variable_indemnity?partner=${getPartnerGuid()}`, args);
}

export function createCashbackIndemnityBenefit(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/cashback_indemnity?partner=${getPartnerGuid()}`, args);
}

export function createBenefit(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post('/api/product_benefits', args);
}

export function hardDeleteBenefit(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;;
  return crudApiInstance.delete(`/api/${args.benefitType}/delete/${args.benefit.guid}?partner=${getPartnerGuid()}`);
}

export function downloadDocument(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/documents/${args.template_guid}/download?partner=${getPartnerGuid()}&entity_guid=${args.entity_guid}`, { responseType: 'blob' });
}

export function getPolicyDocumentTemplate() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/templates?partner=${getPartnerGuid()}&type=LOU`);
}

export function getProduct(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/products/${args}?partner=${getPartnerGuid()}`, { data: {} });
}

export function getPartnerConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/partners/partners_config/${getPartnerGuid()}`);
}

export function getPartnerConfigsClaim(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/partners/partners_config_claim/${getPartnerGuid()}`);
}

export function getPartnerConfigsGroup(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/partners/partners_config_group/${getPartnerGuid()}`);
}
export function updatePartner(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/partners/${getPartnerGuid()}`, args);
}

export function updatePartnerConfig(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/partners/partners_config/${getPartnerGuid()}`, args);
}

export function updatePartnerConfigClaim(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/partners/partners_config_claim/${getPartnerGuid()}`, args);
}

export function updatePartnerConfigGroup(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/partners/partners_config_group/${getPartnerGuid()}`, args);
}


export function updateGroupProductConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/products/group_product_config/${args.id}`, args);
}

export function addGroupProductConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/products/group_product_config/${args.product_guid}`, args);
}


export function getGroupProductConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/products/group_product_config/${args}`);
}

export function getExtendedFamilyProductConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/extended_membership_config/${args}/all?partner=${getPartnerGuid()}`);
}

export function updateExtendedFamilyProductConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/extended_membership_config/${args.guid}?partner=${getPartnerGuid()}`, args);
}

export function addExtendedFamilyProductConfigs(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/membership_configs?partner=${getPartnerGuid()}`, args);
}

export function createPartnerConfigGroup(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/products/group_product_config/${args.guid}`, args);
}


export function getProductEnums(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/metadata/product?partner=${getPartnerGuid()}`, {
    data: {},
  });
}

export function createProductPremium(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post('/api/product_premiums', args);
}

export function getProductPremiums(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/product_premiums/product/${args}`, {
    data: {},
  });
}

export function getProductPremiumEnums(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/metadata/product_premium?partner=${getPartnerGuid()}`, {
    data: {},
  });
}

export function getRoles() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.get(`/auth/admin/realms/${EnvironmentalInformation.credentials.realm}/groups`);
}

export function getCurrentRoles(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.get(`/auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${args.id}/groups`);
}

export function getCurrentUserPartners(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.get(`/auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${args.id}`);
}

export function getLanguages() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get('/api/languages', { data: {} });
}

export function deleteUser(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return centralAuthenticationInstance.delete(`auth/admin/realms/${EnvironmentalInformation.credentials.realm}/users/${args.id}`);
}

export function deletePremium(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/premiums/${args}`, { data: {} });
}

export function addMissingPayment(args) {
  // use correct API host when API part is done /api/wallet_money_transactions
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/payments?partner=${getPartnerGuid()}`, args);
}

export function updatePayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/payments/${args.paymentGuid}?partner=${getPartnerGuid()}`, args);
}

export function addSingleBundledPayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put('/api/premiums/add_with_prerequisite', args);
}

export function addSingleNonBundledPayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put('/api/premiums/add_single_non_bundled', args);
}

export function addMultipleBundledPayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(
    `/api/premiums/add_bulk_bundle_payment?partner=${args.partner}`,
    args.file,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
}

export function addMultipleNonBundledPayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(
    `/api/premiums/add_bulk_non_bundled_payments?partner=${args.partner}`,
    args.file,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
}

export function sendRecurringPaymentRecommendationSms(payload) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/customers/${payload.customerGuid}/send_recurring_sms`);
}

export function changeUserPassword(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/users/changepassword/${args.guid}`, args);
}

export function changeLoyaltyRegistration(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put('/api/customers/loyalty_registration', args);
}

export function pullCustomerKyc(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const customerGuid = args.guid;
  return crudApiInstance.put(
    `/api/customers/${customerGuid}/request_kyc?send_sms=false`,
    { data: {} }
  );
}


export function getSignedUrlForAnyBucket(file, callback, bucketName) {
    axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
    crudApiInstance
      .put(`/api/configs/s3_signed_url/upload/${bucketName}?partner=${getPartnerGuid()}`)
      .then((response) => {
        callback(response.data);
      });
}


export function getS3BucketSignedUrl(filename, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .put(
      `/api/loyalty_eligibility/upload?partner=${getPartnerGuid()}`
    )
    .then(response => {
      callback(response.data);
    });
}

export function getS3BucketSignedUrlForGroupUploadResources(filename, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .post(
      `/api/groups/uploads?partner=${getPartnerGuid()}&filename=${filename.name}`
    )
    .then(response => {
      callback(response.data);
    });
}

export function getS3BucketSignedUrlForClaims(file, callback, claim_guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .get(
      `/api/claims/upload/${claim_guid}?filename=${file.name}`
    )
    .then(response => {
      callback(response.data);
    });
}

export function getS3BucketSignedUrlForPolicyDcpDocuments(policy_guid, file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .get(
      `api/policies/complex/documents/signed_url?policy_guid=${policy_guid}&filename=${file.name}&partner=${getPartnerGuid()}`

    )
    .then(response => {
      callback(response.data);
    });
}

export function getS3BucketSignedUrlForProofOfPaymentsDocuments(paymentPayload, file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;

  crudApiInstance.post(`/api/payments?partner=${getPartnerGuid()}`, paymentPayload).then(response => {
    localStorage.setItem('payment_guid', response.data.guid)

    //callback(response.data);
    crudApiInstance
      .get(
        `api/payments/documents/signed_url?policy_guid=${paymentPayload.policy_guid}&payment_guid=${response.data.guid}&filename=${file.name}&partner=${getPartnerGuid()}`
      )
      .then(response => {
        callback(response.data);
      });

  });


}


export function uploadPolicyDocument(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/policies/complex/documents?policy_guid=${args.policy_guid}&partner=${getPartnerGuid()}`, args);

}


export function getS3BucketSignedUrlForCustomerFile(filename, callback, customerGuid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  customerApplicationInstance
    .get(
      `api/customers/files/upload/${customerGuid}?filename=${filename.name}&partner=${getPartnerGuid()}`
    )
    .then(response3 => {
      const url = response3.data;
      callback({ "signedUrl": url.signedUrl });
    })
    .then(
      getAllCustomerFiles(customerGuid)
    )
}

export function getS3BucketSignedUrlForBulksms(file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  bulkSmsInstance
    .get(
      `/api/sms_batch/upload?filename=${file.name}`
    )
    .then(response => {
      callback(response.data);
    });
}

export function getAllCustomerFiles(customerGuid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/customers/files/${customerGuid}/all?partner=${getPartnerGuid()}`);
}

export function getAllPolicies(args) {
 // axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  //return crudApiInstance.get(`api/policies/customer/${args.msisdn}?partner=${getPartnerGuid()}&guid=${args.guid}`);
  
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/policies/customer/guid/${args.guid}?partner=${getPartnerGuid()}`);
}

export function getAllPayments(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/payments/all/${args.guid}?partner=${getPartnerGuid()}`);
}

export function getAllPremiums(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/premiums/customer/${args.msisdn}?partner=${getPartnerGuid()}`);
}


export function getAllMandates(msisdn) {
  axios.defaults.headers.common.Authorization = `Bearer None` ;
   return crudApiInstance.get(`retrieve-mandate/details/${msisdn}?partner=${getPartnerGuid()}`);
}


// TODO: Pull the country code from the user information
export function getClaimsHospital() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/claim_hospitals?country=${countryCode()}`, {
    data: {},
  });
}

export function getClaimRejectionReason(arg) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const language = arg.language;
  return crudApiInstance.get(
    `/api/claim_rejection_reasons?language=${language}`,
    { data: {} }
  );
}


export function getClosedCallback(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const page = args.page ? args.page : 1;
  const page_size = args.page_size ? args.page_size : 10;
  return crudApiInstance.get(`/api/claim_callbacks?open=false&partner=${getPartnerGuid()}&page=${page}&size=${page_size}`);
}

export function getOpenCallback(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const page = args.page ? args.page : 1;
  const page_size = args.page_size ? args.page_size : 10;
  return crudApiInstance.get(`/api/claim_callbacks?open=true&partner=${getPartnerGuid()}&page=${page}&size=${page_size}`);
}

export function getAllCashBacks() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/cashback?partnerGuid=${getPartnerGuid()}`
  );
}

export function markCashbackAsPaid(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/cashback/mark_as_paid?id=${args}&partnerGuid=${getPartnerGuid()}`
  );
}

export function markCashbackAsUnpaid(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/cashback/mark_as_unpaid?id=${args}&partnerGuid=${getPartnerGuid()}`
  );
}

export function getReportBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/premium_received_report?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date
    }`
  );
}

export function getReportPaymentBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/payment_received_report?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date
    }`
  );
}

export function getDailyReportBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `/api/reports/daily_stats_report?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date
    }`
  );
}


export function getPoliciesReportBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/all_created_policies?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date
    }`
  );
}

export function getClaimFinalisationReportBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/claim_finalisation_report?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date
    }`
  );
}

export function getReportRegistrationBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/registered_customers?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function getReportPayableClaimsBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/claims_payable_report?type=${args.type
    }&partner=${getPartnerGuid()}`
  );
}

export function getReportSuspendedPoliciesBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/policy_suspended?type=${args.type}&partner=${getPartnerGuid()}`
  );
}

export function getReportPreAuthorizationBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/preauthorization?type=${args.type}&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date}`
  );
}

export function getReportClaimDeathBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/death-claim-report?type=${args.type}&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date}`
  );
}

export function getReportClaimFinalizationDeathBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/death-claim-report?type=${args.type}&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date}&status='Approved'`
  );
}

export function getReportClaimHospitalBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/hospital-claim-report?type=${args.type}&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date}`
  );
}

export function getReportClaimFinalizationHospitalBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/hospital-claim-report?type=${args.type}&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date}&status='Approved'`
  );
}
export function getReportClaimReimbursementBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/reimbursement?type=${args.type}&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date}`
  );
}


export function getReportClaimFinalizationReimbursementBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/reimbursement?type=${args.type}&partner=${getPartnerGuid()}&startDate=${args.start_date}&endDate=${args.end_date}&status='Approved'}`
  );
}

export function getReportOpenClaimBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/all_open_claims_report?type=${args.type
    }&partner=${getPartnerGuid()}`
  );
}

export function getReportRefundBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/refunds_report?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function uploadTigoLoyaltyEligibility(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(
    `api/loyalty_eligibility/local_upload_csv?partner=${getPartnerGuid()}`,
    args
  );
}

export function renewLoyalty() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(
    `/api/loyalty_eligibility/renew_loyalty?partner=${getPartnerGuid()}`,
    { data: {} }
  );
}

export function createDebitOrderz(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return paymentServiceInstance.post(
    `/api/debit_orders?partner=${getPartnerGuid()}`,
    args
  );
}

export function deleteDebitOrderz(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return paymentServiceInstance.delete(
    `/api/debit_orders/${args.guid}?partner=${getPartnerGuid()}`,
    args
  );
}

export function editDebitOrderz(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return paymentServiceInstance.post(
    `/api/debit_orders?partner=${getPartnerGuid()}`,
    args
  );
}

export function getDebitOrderz(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  if(args.policy_guid){
    return paymentServiceInstance.get(
      `api/debit_orders/policy?partner=${getPartnerGuid()}&policy_guid=${args.policy_guid}`,
    );
  }
  return paymentServiceInstance.get(
    `api/debit_orders?partner=${getPartnerGuid()}&msisdn=${args.msisdn}`,

  );
}


export function createDebitOrder(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(
    `/api/debit_orders?partner=${getPartnerGuid()}`,
    args
  );
}

export function getAllBanks() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    `/api/banks/country/${countryCode()}`
  );
}



export function getDebitOrderTransactions() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    `/api/debit_order_transactions?partner=${getPartnerGuid()}`,
    { data: {} }
  );
}

export function updateDebitOrderTransactions(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(
    `/api/debit_order_transactions/process?partner=${getPartnerGuid()}&status=${args.status
    }`,
    args.transactions
  );
}

export function getPendingDebitOrderTransactions() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    `/api/debit_order_transactions/pending?partner=${getPartnerGuid()}`,
    { data: {} }
  );
}

export function getFailedDebitOrderTransactions() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(
    `/api/debit_order_transactions/failed?partner=${getPartnerGuid()}`,
    { data: {} }
  );
}

export function preRegisterGroup(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/groups/pre_register?partner=${getPartnerGuid()}`, args);
}

export function registerGroup(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  // return crudeInstance.post(`/api/groups/register?partner=${getPartnerGuid()}`, args);
  return crudApiInstance.post(`/api/groups/v2/register?partner=${getPartnerGuid()}&group_id=${groupId}`);
}

export function getActualGroupQuote(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/v2/quotation?group_id=${args}&partner=${getPartnerGuid()}`);
}

export function updateGroup(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/groups/pre_register?groupId=${args.groupId}&partner=${getPartnerGuid()}`, args);
}

export function getGroups(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/user?partner=${getPartnerGuid()}&page=${args.page}&size=${args.size}`);
}
export function getGroup(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/group/${groupId}?partner=${getPartnerGuid()}`);
}
export function deleteGroup(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/groups/${groupId}?partner=${getPartnerGuid()}`);
}
export function getAllGroups(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups?partner=${getPartnerGuid()}&page=${args.page}&size=${args.size}`);
}

export function addGroupPayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/group-payments/create`, args);
}


export function getCurrentGroupMembers(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/${args.groupId}/customers?partner=${getPartnerGuid()}&page=${args.page}&size=${args.size}`);
}

export function getCurrentGroupMembersForAdmins(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/${args.groupId}/members?partner=${getPartnerGuid()}&page=${args.page}&size=${args.size}`);
}

export function getGroupMembersForCSV(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/${groupId}/members?partner=${getPartnerGuid()}`)
}

export function getCurrentGroupPolicies(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/${args.groupId}/policies?partner=${getPartnerGuid()}&page=${args.page}&size=${args.size}`);
}


export function getCurrentGroupPremiums(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/${groupId}/premiums`);
}

export function getCurrentGroupPayments(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/group-payments/${groupId}`);
}

export function getCurrentGroupClaims(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/${groupId}/claims`);
}

export function getCurrentGroupClaimCallbacks(groupId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/groups/callbacks?group_id=${groupId}`);
}

export function createGroupClaimCallBack(msisdn) {
  return crudApiInstance.post(`public/groups/call_back?partner=britamguid&msisdn=${msisdn}`);
}

export function getGroupProducts(args) {

  return crudApiInstance.get(`/public/groups/products?partner=${args}`);
}

export function getPolicyProduct(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/products/${args}?partner=${getPartnerGuid()}`);
}

export function getAllProducts() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/products?partner=${getPartnerGuid()}`);
}


export function getAllCustomerClaims(msisdn) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/claims/customer/${msisdn}?partner=${getPartnerGuid()}`);
}

export function getTotalPayablePremium(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/policies/${args}/premium_rate?partner=${getPartnerGuid()}`);
}

export function initiatePolicyMobileMoneyPayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;

  // eslint-disable-next-line camelcase
  const partnerGuid = getPartnerGuid();
  return paymentServiceInstance.post(
    `/api/payments/direct?partner=${partnerGuid}`,
    args
  );
}

export function claimUploadCallback(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  if (args.customerFileUpload) {
    return customerApplicationInstance.post(
      `api/customers/files?partner=${getPartnerGuid()}`, {
      "type": args.type,
      "url": args.signedUrl,
      "customer_guid": args.customerGuid,
      "filename": "foobar",
    }
    );
  }
  return crudApiInstance.put('/api/claims/upload_callback', args);
}

export function createClaimFile(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/claims/${args.claim_guid}/files`, args);
}

export function claimFileDelete(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`/api/claims/files/${args.guid}`);
}

export function claimCallbackDelete(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/claim_callbacks/close/${args.claimGuid}`, args.reason);
}

export function getVasAgentReportBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/vas_agents?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function getReportAgentWebBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/web_agents?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function getClaimInfo(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/claims/${args}`);
}

export function retryFailedClaimPayment(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(
    `/api/mobile_money_transactions/retry_failed_claim_payout/${args}`
  );
}

export function updateClaimPaymentOutcome(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put('/api/mobile_money_transactions/outcome', args);
}

export function getClaimConditionList() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get('/api/claim_conditions', { data: {} });
}

export function uploadBulkSms(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return bulkSmsInstance.post('/api/sms_batch', args);
}

export function downloadBulkSms(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return bulkSmsInstance.get(`/api/sms_batch/download/${args}?partner=${getPartnerGuid()}`);
}

export function getBulkSmsSummary(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return bulkSmsInstance.get(`/api/sms_batch/${args}?partner=${getPartnerGuid()}`);
}


export function mapColumnsToFields(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;

  return loyaltyInstance.post(`/upload?partner=${getPartnerGuid()}`, args,);
}


export function getPreviousLoyaltyStatus(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;

  return loyaltyInstance.get(`/report?partner=${getPartnerGuid()}&report_id=${args.guid}`);
}


export function getPreviousLoyaltiesStatus() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  axios.defaults.headers.get['engine-user-token'] = getAuthorizationToken();

  return loyaltyInstance.get(`/reports?partner=${getPartnerGuid()}`);
}

export function stopBlast(blastId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return bulkSmsInstance.put(`/api/sms_batch/interrupt/${blastId}?partner=${getPartnerGuid()}`);
}

export function getBatchProgress(blastId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return bulkSmsInstance.get(`/api/sms_batch/progress/${blastId}?partner=${getPartnerGuid()}`);
}

export function getReportPaidClaimBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/claims_paid_report?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function getReportClaimNotificationBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/claim_notification_report?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function getReportClaimRegisterBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/claims_register?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function getReportPremiumRegisterBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/premium_register?type=${args.type
    }&partner=${getPartnerGuid()}&startDate=${args.startDate}&endDate=${args.endDate
    }`
  );
}

export function getReportPremiumDueBlob(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(
    `api/reports/premium_due?type=${args.type}&partner=${getPartnerGuid()}`
  );
}

export function addRemittances(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(
    `/api/remittance/add_bulk_remittances?partner=${args.partner}`,
    args.file,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
}

//
export function getTempMembers(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/groups/temp/all?page=${args.page}&size=${args.size}&groupId=${args.groupId}&partner=${getPartnerGuid()}`);
}

// add
export function addPrincipal(principal) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/groups/temp/add/principal?partner=${getPartnerGuid()}`, principal);
}

export function addDependant(dependant) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/groups/temp/add/dependant?partner=${getPartnerGuid()}`, dependant);
}

// edit
export function editDependant(dependant) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/groups/temp//edit/dependant/id/${dependant.id}?partner=${getPartnerGuid()}`, dependant);
}

export function editPrincipal(principal) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/groups/temp/edit/principal/id/${principal.id}?partner=${getPartnerGuid()}`, principal);
}

// delete
export function deletePrincipal(principalId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/groups/temp/delete/principal/id/${principalId}?partner=${getPartnerGuid()}`);
}

export function deleteDependant(dependantId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/groups/temp/delete/dependant/id/${dependantId}?partner=${getPartnerGuid()}`);
}

export function uploadExcel(payload) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/groups/temp/upload?partner=${getPartnerGuid()}`, payload);
}


export function getCustomerDependant(dependantId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/dependants/${dependantId}?partner=${getPartnerGuid()}`);
}

export function getCustomerDependants(customerId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/dependants/${customerId}/all?partner=${getPartnerGuid()}`);
}

export function addCustomerDependant(dependant) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.post(`api/dependants/${dependant.customer_guid}?partner=${getPartnerGuid()}`, dependant);
}

export function updateCustomerDependant(dependant) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.put(`api/dependants/${dependant.guid}?partner=${getPartnerGuid()}`, dependant);
}

export function deleteCustomerDependant(dependantGuid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.delete(`api/dependants/${dependantGuid}?partner=${getPartnerGuid()}`);
}

export function getCustomerBeneficiary(customer_guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/customers/${customer_guid}/beneficiary?partner=${getPartnerGuid()}`);
}

export function addCustomerBeneficiary(beneficiary) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.post(`api/beneficiaries?partner=${getPartnerGuid()}`, beneficiary);
}
export function deleteCustomerbeneficiary(beneficiary) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.delete(`api/beneficiaries/${beneficiary.guid}?partner=${getPartnerGuid()}`);
}

export function updateCustomerBeneficiary(beneficiary) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.put(`api/beneficiaries/${beneficiary.guid}?partner=${getPartnerGuid()}`, beneficiary);
}

// export function getCustomerBeneficiaryBankDetails(beneficiary_guid) {
//   axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
//   return customerApplicationInstance.get(`api/beneficiaries/${beneficiary_guid}/bank_details?partner=${getPartnerGuid()}`);
// }

export function getCustomerBeneficiaryBankDetails(customer_guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/bank_details/${customer_guid}/beneficiary_customer?partner=${getPartnerGuid()}`);
}

export function addCustomerBeneficiaryBankDetails(bankdetails) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.post(`api/bank_details?partner=${getPartnerGuid()}`, { ...bankdetails, customer_guid: null });
}
export function deleteCustomerbeneficiaryBankDetails(bankdetails) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.delete(`api/bank_details/${bankdetails.customer_guid}?partner=${getPartnerGuid()}`);
}

export function updateCustomerBeneficiaryBankDetails(bankdetails) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.put(`api/bank_details/${bankdetails.guid}?partner=${getPartnerGuid()}`, bankdetails);
}


export function getCustomerBankDetails(customer_guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/customers/${customer_guid}/bank_details?partner=${getPartnerGuid()}`);
}

export function addCustomerBankDetails(bankdetails) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.post(`api/bank_details?partner=${getPartnerGuid()}`, bankdetails);
}
export function deleteCustomerBankDetails(bankdetails) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.delete(`api/bank_details/${bankdetails.guid}?partner=${getPartnerGuid()}`);
}

export function updateCustomerBankDetails(bankDetails) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.put(`api/bank_details/${bankDetails.guid}?partner=${getPartnerGuid()}`, bankDetails);
}


export function getCustomerDocument(dependantId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/customers/files/${dependantId}?partner=${getPartnerGuid()}`);
}

export function getCustomerDocuments(customerId) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.get(`api/customers/files/${customerId}/all?partner=${getPartnerGuid()}`);
}

export function addCustomerDocument(document) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.post(`api/customers/files?partner=${getPartnerGuid()}`, document);
}

export function updateCustomerDocument(document) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.put(`api/customers/files/${document.guid}?partner=${getPartnerGuid()}`, document);
}

export function deleteCustomerDocument(documentGuid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return customerApplicationInstance.delete(`api/customers/files/${documentGuid}?partner=${getPartnerGuid()}`);
}

export function deletePolicyDocument(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/policies/complex/documents?policy_guid=${args.policy_guid}&policy_doc_guid=${args.policy_doc_guid}&partner=${getPartnerGuid()}`);
}

export function getS3BucketSignedUrlForCustomerDocuments(customerGuid, file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  customerApplicationInstance
    .get(
      `api/customers/files/upload/${customerGuid}?partner=${getPartnerGuid()}&filename=${file.name}`
    )
    .then(response => {
      callback(response.data);
    });
}

export function getS3BucketSignedUrlForClaimDcpDocuments(claim_guid, file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .get(
      `api/claims/complex/documents/signed_url?claim_guid=${claim_guid}&filename=${file.name}&partner=${getPartnerGuid()}`
    )
    .then(response => {
      callback(response.data);
    });
}

export function getS3BucketSignedUrlForBulkPolicyOnboarding(file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .get(
      `api/bulk-policy-onboarding/signed_url?filename=${file.name}&partner=${getPartnerGuid()}`
    )
    .then(response => {
      callback(response.data);
    });
}


export function getS3BucketSignedUrlForEmailTemplates(file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .get(
      `api/email/templates/upload/signed_url?partner=${getPartnerGuid()}&filename=${file.name}`
    )
    .then(response => {
      callback({
        "signedUrl": response.data.signed_url,
        "bucketKey": response.data.filename
      });
    });
}

// export function getS3BucketSignedUrlForEmailTemplates(templatePayload, file, callback){

//   crudApiInstance.get(
//     `api/email/templates/upload/signed_url?partner=${getPartnerGuid()}&filename=${file.name}` )
//    .then(response => {
//     callback({
//       "signedUrl": response.data.signed_url,
//       "bucketKey": response.data.filename
//     });
//   });

// axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
// crudApiInstance
//   .get(
//     `api/email/templates/upload/signed_url?partner=${getPartnerGuid()}&filename=${file.name}`
//   )
//   .then(response => {
//     callback({
//       "signedUrl": response.data.signed_url,
//       "bucketKey": response.data.filename
//     });
//   });
// }

export function getS3BucketSignedUrlForBulkPayments(file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .get(
      `/api/bulk_payments/upload/signed_url?filename=${file.name}&partner=${getPartnerGuid()}`
    )
    .then(response => {
      callback({
        "signedUrl": response.data.signed_url,
        "bucketKey": response.data.filename
      });
    });
}

export function saveClaimDcpDocument(doc) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/claims/complex/documents?claim_guid=${doc.claim_guid}&partner=${getPartnerGuid()}`, doc);
}

export function saveBulkPolicyOnboarding(doc) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/bulk-policy-onboarding?partner=${getPartnerGuid()}`, doc);
}

export function getBulkPoliciesOnboarding(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const page = args.page ? args.page : 1;
  const page_size = args.page_size ? args.page_size : 10;
  return crudApiInstance.get(`api/bulk-policy-onboarding/batches?partner=${getPartnerGuid()}&page=${page}&page_size=${page_size}`);
}

export function getFailedBulkPoliciesBatches(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const page = args.page ? args.page : 1;
  const page_size = args.page_size ? args.page_size : 10;
  return crudApiInstance.get(`api/bulk-policy-onboarding/failed_batch_entries?batch_guid=${args.batch_guid}&partner=${getPartnerGuid()}&page=${page}&page_size=${page_size}`);
}

// bulk payments
export function getBulkPaymentsBatches(payload) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const page = payload.page ? payload.page : 1;
  const size = payload.page_size ? payload.page_size : 10;
  return crudApiInstance.get(`api/bulk_payments?partner=${getPartnerGuid()}&page=${page}&size=${size}`);
}

export function addBulkPayments(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/bulk_payments?partner=${getPartnerGuid()}`, args);
}

export function saveBulkPayments(doc) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/bulk_payments?partner=${getPartnerGuid()}`, doc);
}

export function getFailedBulkPaymentsBatches(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  const page = args.page ? args.page : 1;
  const size = args.page_size ? args.page_size : 10;
  return crudApiInstance.get(`api/bulk_payments/${args.batch_guid}/transactions?partner=${getPartnerGuid()}&size=${size}&page=${page}`);
}

export function getTemplateDocuments(type) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;

  let query = `partner=${getPartnerGuid()}`;
  if (type) {
    query += `&type=${type}`;
  }

  return crudApiInstance.get(`api/templates?${query}`);
}

export function getEmailTemplates(type) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;

  let query = `partner=${getPartnerGuid()}`;
  if (type) {
    query += `&type=${type}`;
  }

  return crudApiInstance.get(`api/email/templates?${query}`);
}

export function addGuide(guide) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`/api/knowledge-base?partner=${getPartnerGuid()}`, guide);
}


export function editGuide(guide) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/knowledge-base/${guide.guid}?partner=${getPartnerGuid()}`, guide);
}

export function deleteGuide(guid) {
  console.log('deleteGuide: ', guid);
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/knowledge-base/${guid}?partner=${getPartnerGuid()}`);
}

export function getGuide(guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/knowledge-base/${guid}?partner=${getPartnerGuid()}`);
}

export function getGuides() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/knowledge-base?partner=${getPartnerGuid()}`);
}

export function addTemplateDocument(template) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/templates?partner=${getPartnerGuid()}`, template);
}

export function addEmailTemplate(template) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/email/templates?partner=${getPartnerGuid()}`, template);

}

export function addReportTemplate(template) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.post(`api/reports?partner=${getPartnerGuid()}`, template);

}

export function getReportsTemplates() {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/reports?partner=${getPartnerGuid()}`);
}

export function generateReport(args){


  const reportId = args.id

  // Remove 'id' from query parameters
  delete args.id;

  let queryLsit =[]
  Object.keys(args).forEach(k=>queryLsit.push(`${k}=${args[k]}`));
  queryLsit.join('&')

  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return reportsInstance.get(`api/reports/${reportId}/generate?partner=${getPartnerGuid()}&${queryLsit.join('&')}`)
 
}



export function editTemplateDocument(template) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`api/templates/${template.guid}?partner=${getPartnerGuid()}`, template);
}

export function deleteTemplateDocument(documentGuid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/templates/${documentGuid}?partner=${getPartnerGuid()}`);
}

export function editReportTemplate(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.put(`/api/reports/${args.id}?partner=${getPartnerGuid()}`, args);
}

export function deleteReportTemplate(id) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.delete(`api/reports/${id}?partner=${getPartnerGuid()}`);
}

export function getS3BucketSignedUrlForPartnerTemplateDocuments(file, callback) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  crudApiInstance
    .get(
      `api/templates/upload/signed_url?partner=${getPartnerGuid()}&filename=${file.name}`
    )
    .then(response => {
      console.log(response.data.signed_url);
      callback({
        "signedU66rl": response.data.signed_url,
        "bucketKey": response.data.filename
      });
    });
}

export function getCustomerRegistrationStatusByGuid(guid) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/customers/registration_status_by_guid?customer_guid=${guid}&partner=${getPartnerGuid()}`)
}


export function getElasticSearchResults(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`/api/search?partner=${getPartnerGuid()}&search=${args.query}&doc_type=${args.document_type}&page=${args.page}&size=${args.size}`);
 // return crudApiInstance.get(`/api/search?partner=${getPartnerGuid()}&search=${args.query}&doc_type=${args.document_type}&page=${args.page}&size=${args.size}`);
  
}

export function getEmailTemplateByGuid(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  return crudApiInstance.get(`api/email/templates/${args.guid}?partner=${getPartnerGuid()}`);
}


export function sendEmail(args) {
  axios.defaults.headers.common.Authorization = `Bearer ${getAuthorizationToken()}`;
  // https://email-service.staging.aspin-inclusivity.com/api/emails/send?partner_guid=incl_motor&email_template_guid=965cfd73-04b3-43d4-9534-d6ffd25860b5&email_recipients=matelierick@gmail.com&entity_guid=bdi_ke
  return crudApiInstance.post(`/api/emails/send?partner_guid=${getPartnerGuid()}&email_template_guid=${args.email_template_guid}&email_recipients=${args.email_recipients}&entity_guid=${args.entity_guid}&email_code=${args.email_code}`, args);
}