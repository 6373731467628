import React, { useState, useEffect } from 'react'
import ModalInc from '../../../ModalInc'
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { CircularProgress, Grid, InputAdornment, MenuItem } from '@mui/material';
import { getInternationalCallingCode, getLocalNumberLength, getPartner, isSouthAfricanPartner, isVAlidSouthAfricanNationalId } from '../../../../lib/access.es6';
import ButtonInc from '../../../ButtonInc';
import { formatDate } from '../../../../lib/utils.es6';
import AlertDialog from '../../../../components/AlertDialog/index.es6';

const relationships = [
  { value: 'Principal', gender: 'N/A' },
  { value: 'Spouse', gender: 'N/A' },
  { value: 'Spouse', gender: 'Female' },
  { value: 'Child', gender: 'N/A' },
  { value: 'Parent', gender: 'N/A' },
  { value: 'Parent_In_Law', gender: 'N/A' },
  { value: 'Beneficiary_Adult', gender: 'N/A' },
  { value: 'Husband', gender: 'Male' },
  { value: 'Wife', gender: 'Female' },
  { value: 'Daughter', gender: 'Female' },
  { value: 'Son', gender: 'Male' },
  { value: 'Mother', gender: 'Female' },
  { value: 'Father', gender: 'Male' },
  { value: 'Mother_In_Law', gender: 'Female' },
  { value: 'Father_In_Law', gender: 'Male' },
  { value: 'Uncle', gender: 'Male' },
  { value: 'Aunt', gender: 'Female' },
  { value: 'Sister', gender: 'Female' },
  { value: 'Granddaughter', gender: 'Female' },
  { value: 'Grandchild', gender: 'N/A' },
  { value: 'Sister_In_Law', gender: 'Female' },
  { value: 'Other', gender: 'N/A' },
  { value: 'Grand_Child', gender: 'N/A' },
  { value: 'Daughter_In_Law', gender: 'Female' },
  { value: 'Fiance', gender: 'N/A' },
  { value: 'Step_Father', gender: 'Male' },
  { value: 'Step_Child', gender: 'N/A' },
  { value: 'Step_Mother', gender: 'Female' },
  { value: 'Son_In_Law', gender: 'Male' },
  { value: 'Secondary_Spouse', gender: 'Female' },
  { value: 'Next_Of_Kin', gender: 'N/A' },
  { value: 'Wider_Child', gender: 'N/A' },
];

const AddDependantMobile = (props) => {


  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [telephone, setTelephone] = useState('')
  const [dateofbirth, setDateofbirth] = useState('')
  const [nationalID, setNationalID] = useState('')
  const [relationship, setRelationship] = useState()
  const [gender, setGender] = useState('')

  //validations
  useEffect(() => {

    const partner = getPartner();
    const nationalIdRegex = partner ? partner.country.national_id_regex : '';
    const pattern = new RegExp(nationalIdRegex);

    ValidatorForm.addValidationRule('isValidPhoneNumber', value => {
      return value.length === getLocalNumberLength();
    });

    ValidatorForm.addValidationRule('isValidNationalId', value => {
      if (!isSouthAfricanPartner()) {
        return pattern.test(value);
      }
      const result = isVAlidSouthAfricanNationalId(value);
      if (result.isValid) {
        this.setState({
          dateOfBirth: formatDate(new Date(result.dob)),
          gender: result.gender,
          SAIdCheckError: result.error,
        });
        return true;
      }
      this.setState({ SAIdCheckError: result.error });
      return false;
    });


    return function cleanup() {
      ValidatorForm.removeValidationRule('isValidPhoneNumber');
      ValidatorForm.removeValidationRule('isValidNationalId');

    };


  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "firstName") {
      setFirstName(value)
    }
    if (name === "lastName") {
      setLastName(value)
    }
    if (name === "telephone") {
      setTelephone(value)
    }
    if (name === "dateofbirth") {
      setDateofbirth(value)
    }
    if (name === "relationship") {
      setRelationship(value)
    }
    if (name === "gender") {
      setGender(value)
    }
    if (name === "nationalID") {
      setNationalID(value)
    }

  }
  const handleSubmit = () => {
    const payload = {
      "first_name": firstName,
      "last_name": lastName,
      "date_of_birth": dateofbirth,
      "national_id": nationalID,
      "gender": gender,
      "relationship": relationship,
      "customer_guid": props.customerGuid,
      "msisdn": telephone,
    }
    props.saveDependants(payload)
    console.log("payload12", payload)
  }
  const handleDependantCreatedSuccessfully = () => {
    props.resetAddDependantSuccessAlert()
    props.toggleAdDependantForm()
    props.setSelectPage('Dependants')
  }
  const handleDependantCreateError = () => {
    props.resetAddDependantErrorAlert()
  }


  return (
    <>
      <AlertDialog
        custom
        show={props.showAddDependantProgressAlert}
        size="sm"
        style={{ marginTop: '0', top: '30vh' }}
        confirmBtnText="ok"
        showCancel={false}
        showConfirm={false}
        cancelBtnText="cancel"
        showTitle={false}
        confirmBtnCssClass
      >
        <CircularProgress />
        <h2>Adding Dependant </h2>
      </AlertDialog>

      <AlertDialog
        success
        show={!!props.showAddDependantSuccessAlert}
        size="sm"
        title="Dependant added"
        style={{ marginTop: '0', top: '30vh' }}
        onConfirm={() => {
          handleDependantCreatedSuccessfully();
        }}
        confirmBtnText="ok"
        showCancel={false}
        showConfirm
        cancelBtnText="cancel"
        confirmBtnCssClass
      ></AlertDialog>

      <AlertDialog
        show={!!props.showAddDependantErrorAlert}
        danger
        title="Error adding Dependant"
        onConfirm={() => handleDependantCreateError()}
        confirmBtnText="ok"
        confirmBtnCssClass
        showCancel={false}
        style={{ marginTop: '0', top: '30vh' }}
      >
        {props.createDependantError
          ? props.createDependantError.message
          : ''}
      </AlertDialog>

      <ModalInc
        modalTitle="Add dependant"
        subTitle="Fill the below fields to add a dependant"
        open={props.showAddDependantForm}
        onClose={props.toggleAdDependantForm}
        fullWidth
      >
        <ValidatorForm onSubmit={() => handleSubmit()} >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextValidator
                name='firstName'
                id="firstName"
                label="First Name*"
                value={firstName}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
                validators={['required']}
                errorMessages={["please input first name"]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                name='lastName'
                id="lastName"
                label="Last Name*"
                value={lastName}
                onChange={handleChange}
                fullWidth
                type='text'
                variant="outlined"
                validators={['required']}
                errorMessages={["please input last name"]}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                name='telephone'
                id="telephone"
                label="Telephone*"
                value={telephone}
                onChange={handleChange}
                fullWidth
                type='number'
                variant="outlined"
                validators={['isValidPhoneNumber']}
                errorMessages={[`Please enter a valid ${getLocalNumberLength()} digit phone number`]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{
                      paddingTop: "32px",
                    }}>
                      {getInternationalCallingCode()}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                name='dateofbirth'
                id="Date-of-birth"
                label="Date of birth"
                value={dateofbirth}
                onChange={handleChange}
                fullWidth
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please enter date of birth']}
              />
            </Grid>
            <Grid item xs={12}>

              {isSouthAfricanPartner() ? (
                <TextValidator
                  name='nationalID'
                  id="nationalID"
                  label="National ID*"
                  value={nationalID}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  validators={['required', 'isValidNationalId']}
                  errorMessages={[
                    'Please enter national id', 'Invalid national Id',
                  ]}
                />
              ) : (
                <TextValidator
                  name='nationalID'
                  id="nationalID"
                  label="National ID*"
                  value={nationalID}
                  onChange={handleChange}
                  fullWidth
                  type='number'
                  variant="outlined"
                  validators={['required']}
                  errorMessages={["please input national id"]}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <SelectValidator
                name='relationship'
                labelId="select-relationship"
                id="select-relationship"
                value={relationship}
                label="Relationship"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select relationship']}
                style={{ width: "100%" }}
              >
                {
                  relationships.map((relationship, index) => (
                    <MenuItem key={index} value={relationship.value}>{relationship.value}</MenuItem>
                  )
                  )
                }

              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              <SelectValidator
                name='gender'
                labelId="select-gender"
                id="select-gender"
                value={gender}
                label="Gender"
                onChange={handleChange}
                variant="outlined"
                validators={['required']}
                errorMessages={['Please select gender']}
                style={{ width: "100%" }}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>

              </SelectValidator>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} >
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                type='submit'
                style={{ marginTop: "20px", float: "right" }}
              >
                Add Dependant
              </ButtonInc>
            </Grid>
            <Grid item xs={6}>
              <ButtonInc
                variant="contained"
                color="primary"
                size="large"
                style={{ marginTop: "20px" }}
                onClick={props.toggleAdDependantForm}
              >
                Cancel
              </ButtonInc>
            </Grid>
          </Grid>
        </ValidatorForm>

      </ModalInc>

    </>
  )
}

export default AddDependantMobile