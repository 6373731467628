export const carMakes = [
  {
      "id": 1,
      "title": "TOYOTA"
  },
  {
      "id": 2,
      "title": "SUBARU"
  },
  {
      "id": 3,
      "title": "Acura"
  },
  {
      "id": 4,
      "title": "AUDI"
  },
  {
      "id": 5,
      "title": "ADDIS"
  },
  {
      "id": 6,
      "title": "ASTON MARTIN"
  },
  {
      "id": 7,
      "title": "ALFA ROMEO"
  },
  {
      "id": 8,
      "title": "ASHOK LEYLAND"
  },
  {
      "id": 10,
      "title": "BAW"
  },
  {
      "id": 11,
      "title": "BENTLY"
  },
  {
      "id": 12,
      "title": "BERLIET"
  },
  {
      "id": 13,
      "title": "BEDFORD"
  },
  {
      "id": 14,
      "title": "BMW"
  },
  {
      "id": 15,
      "title": "BRILLIANCE"
  },
  {
      "id": 16,
      "title": "BUICK"
  },
  {
      "id": 17,
      "title": "BYD"
  },
  {
      "id": 18,
      "title": "CADILLAC"
  },
  {
      "id": 19,
      "title": "CATERPILLAR"
  },
  {
      "id": 20,
      "title": "CHEVROLET"
  },
  {
      "id": 21,
      "title": "CHANGAN"
  },
  {
      "id": 22,
      "title": "CHEROKEE"
  },
  {
      "id": 23,
      "title": "CHANGFENG"
  },
  {
      "id": 24,
      "title": "CHANA TRUCKS"
  },
  {
      "id": 25,
      "title": "CHRYSLER"
  },
  {
      "id": 26,
      "title": "CITROEN"
  },
  {
      "id": 27,
      "title": "CHERRY"
  },
  {
      "id": 28,
      "title": "CEMENT TANKER"
  },
  {
      "id": 29,
      "title": "DAF"
  },
  {
      "id": 30,
      "title": "DAEWOO"
  },
  {
      "id": 31,
      "title": "DAIHATSU"
  },
  {
      "id": 32,
      "title": "DATSUN"
  },
  {
      "id": 33,
      "title": "DACIA"
  },
  {
      "id": 34,
      "title": "DELOREAN"
  },
  {
      "id": 35,
      "title": "DEMAG"
  },
  {
      "id": 36,
      "title": "DODGE"
  },
  {
      "id": 37,
      "title": "DONGFENG"
  },
  {
      "id": 38,
      "title": "DEER PICK UP"
  },
  {
      "id": 39,
      "title": "DUMP"
  },
  {
      "id": 40,
      "title": "EAGLE"
  },
  {
      "id": 41,
      "title": "ECONOMOLINE"
  },
  {
      "id": 42,
      "title": "FERRARI"
  },
  {
      "id": 43,
      "title": "FIAT"
  },
  {
      "id": 44,
      "title": "FORD"
  },
  {
      "id": 45,
      "title": "FOLON"
  },
  {
      "id": 46,
      "title": "FOLARND"
  },
  {
      "id": 47,
      "title": "FOTON"
  },
  {
      "id": 48,
      "title": "FRAJEND"
  },
  {
      "id": 49,
      "title": "FREIGHT"
  },
  {
      "id": 50,
      "title": "GAC"
  },
  {
      "id": 51,
      "title": "GOLDEN DRAGON"
  },
  {
      "id": 52,
      "title": "GEO"
  },
  {
      "id": 53,
      "title": "GEELY"
  },
  {
      "id": 54,
      "title": "GMC"
  },
  {
      "id": 55,
      "title": "GREAT WALL"
  },
  {
      "id": 56,
      "title": "HIGER"
  },
  {
      "id": 57,
      "title": "HONDA"
  },
  {
      "id": 58,
      "title": "HOWO SINO"
  },
  {
      "id": 59,
      "title": "HOVER"
  },
  {
      "id": 60,
      "title": "HUMMER"
  },
  {
      "id": 61,
      "title": "HYUNDAI"
  },
  {
      "id": 62,
      "title": "HYSTER"
  },
  {
      "id": 63,
      "title": "INFINITI"
  },
  {
      "id": 64,
      "title": "INTERNATIONAL"
  },
  {
      "id": 65,
      "title": "INNOSON"
  },
  {
      "id": 66,
      "title": "IPI"
  },
  {
      "id": 67,
      "title": "ISUZU"
  },
  {
      "id": 68,
      "title": "IVECO"
  },
  {
      "id": 69,
      "title": "JAGUAR"
  },
  {
      "id": 70,
      "title": "JAC"
  },
  {
      "id": 71,
      "title": "JEEP"
  },
  {
      "id": 72,
      "title": "JINBEI"
  },
  {
      "id": 73,
      "title": "JINCHENG"
  },
  {
      "id": 74,
      "title": "JK MOTORS"
  },
  {
      "id": 75,
      "title": "JMC"
  },
  {
      "id": 76,
      "title": "KAMA"
  },
  {
      "id": 77,
      "title": "KANGO"
  },
  {
      "id": 78,
      "title": "KENWORTH"
  },
  {
      "id": 79,
      "title": "KIA"
  },
  {
      "id": 80,
      "title": "KINLOO"
  },
  {
      "id": 81,
      "title": "KING LONG"
  },
  {
      "id": 82,
      "title": "KOBELCO"
  },
  {
      "id": 83,
      "title": "LAMBORGHINI"
  },
  {
      "id": 84,
      "title": "LANCIA"
  },
  {
      "id": 85,
      "title": "LAND ROVER"
  },
  {
      "id": 86,
      "title": "LEXUS"
  },
  {
      "id": 87,
      "title": "LIEBHERR"
  },
  {
      "id": 88,
      "title": "LIFAN"
  },
  {
      "id": 89,
      "title": "LINCOLN"
  },
  {
      "id": 90,
      "title": "LONCIN"
  },
  {
      "id": 91,
      "title": "LOTUS"
  },
  {
      "id": 92,
      "title": "MACK"
  },
  {
      "id": 93,
      "title": "MAN"
  },
  {
      "id": 94,
      "title": "MESARATI"
  },
  {
      "id": 95,
      "title": "MAZDA"
  },
  {
      "id": 96,
      "title": "MAYBACH"
  },
  {
      "id": 97,
      "title": "MERCEDES BENZ"
  },
  {
      "id": 98,
      "title": "MERCURY"
  },
  {
      "id": 99,
      "title": "MG"
  },
  {
      "id": 100,
      "title": "MONTRACON"
  },
  {
      "id": 101,
      "title": "MINI"
  },
  {
      "id": 102,
      "title": "MITSUBISHI"
  },
  {
      "id": 103,
      "title": "NEXEN"
  },
  {
      "id": 104,
      "title": "NISSAN"
  },
  {
      "id": 105,
      "title": "ZOTYE"
  },
  {
      "id": 106,
      "title": "OLDSMOBILE"
  },
  {
      "id": 107,
      "title": "OPEL"
  },
  {
      "id": 108,
      "title": "PETEBILT"
  },
  {
      "id": 109,
      "title": "PEUGEOT"
  },
  {
      "id": 110,
      "title": "PLYMOUTH"
  },
  {
      "id": 111,
      "title": "PONTIAC"
  },
  {
      "id": 112,
      "title": "POLARSUN"
  },
  {
      "id": 113,
      "title": "PORSCHE"
  },
  {
      "id": 114,
      "title": "PROTON"
  },
  {
      "id": 115,
      "title": "QINGQI"
  },
  {
      "id": 116,
      "title": "QLINK"
  },
  {
      "id": 117,
      "title": "RENAULT"
  },
  {
      "id": 118,
      "title": "REXTON"
  },
  {
      "id": 119,
      "title": "ROLLS-ROYCE"
  },
  {
      "id": 120,
      "title": "ROVER"
  },
  {
      "id": 121,
      "title": "SAAB"
  },
  {
      "id": 122,
      "title": "SATURN"
  },
  {
      "id": 123,
      "title": "SCION"
  },
  {
      "id": 124,
      "title": "SCANIA"
  },
  {
      "id": 125,
      "title": "SINOKI SUPRA "
  },
  {
      "id": 126,
      "title": "SKODA"
  },
  {
      "id": 127,
      "title": "SMART"
  },
  {
      "id": 128,
      "title": "SSANGYONG"
  },
  {
      "id": 129,
      "title": "STEYR"
  },
  {
      "id": 130,
      "title": "SUZUKI"
  },
  {
      "id": 131,
      "title": "TATA"
  },
  {
      "id": 133,
      "title": "TRACTOR"
  },
  {
      "id": 134,
      "title": "VAUXHALL "
  },
  {
      "id": 135,
      "title": "VOLKSWAGEN"
  },
  {
      "id": 136,
      "title": "VOLVO"
  },
  {
      "id": 137,
      "title": "WULLING"
  },
  {
      "id": 138,
      "title": "XIÂ¿AN"
  },
  {
      "id": 140,
      "title": "YUTONG"
  },
  {
      "id": 141,
      "title": "YUEJIN"
  },
  {
      "id": 142,
      "title": "YUGO"
  },
  {
      "id": 143,
      "title": "HIGH SPPED TRAILERS"
  },
  {
      "id": 144,
      "title": "EICHER"
  },
  {
      "id": 145,
      "title": "FAW"
  },
  {
      "id": 146,
      "title": "HINO"
  },
  {
      "id": 147,
      "title": "LEYLAND"
  },
  {
      "id": 148,
      "title": "MAHINDRA"
  },
  {
      "id": 149,
      "title": "SHACMAN"
  }
]