export const LOGIN_REQUESTED = 'login-requested';
export const LOGIN__SUCCEEDED = 'login-succeeded';
export const LOGIN__FAILED = 'login-failed';
export const LOGIN__COMPLETED = 'login-completed';

export const PURCHASE_POLICY_REQUEST = 'purchase-entity-request';
export const PURCHASE_POLICY_SUCCESS = 'purchase-entity-success';
export const PURCHASE_POLICY_FAILURE = 'purchase-entity-failure';
export const PURCHASE_POLICY_RESET = 'purchase-entity-reset';

export const UPDATE_POLICY_REQUEST = 'update-entity-request';
export const UPDATE_POLICY_FAILURE = 'update-entity-failure';

export const CLAIM_SUBMISSION_REQUEST = 'claim-submission-request';
export const CLAIM_SUBMISSION_SUCCESS = 'claim-submission-success';
export const CLAIM_SUBMISSION_FAILURE = 'claim-submission-failure';
export const CLAIM_SUBMISSION_RESET = 'claim-submission-reset';

export const CURRENT_PROFILE_REQUEST = 'current-profile-request';
export const CURRENT_PROFILE_SUCCESS = 'current-profile-success';
export const CURRENT_PROFILE_FAIL = 'current-profile-failure';

export const CLAIM_REJECTION_REASON_REQUEST = 'claim-rejection-reason-request';
export const CLAIM_REJECTION_REASON_SUCCESS = 'claim-rejection-reason-success';
export const CLAIM_REJECTION_REASON_FAILURE = 'claim-rejection-reason-failure';

export const CURRENT_PROFILE_UPDATE_REQUEST = 'current-profile-update-request';
export const CURRENT_PROFILE_UPDATE_SUCCESS = 'current-profile-update-success';
export const CURRENT_PROFILE_UPDATE_FAIL = 'current-profile-update-failure';

export const MAKE_CURRENT_CUSTOMER = 'make-current-customer';
export const SEARCH_CUSTOMER_BY_MSISDN_SUCCESS =
  'search-customer-by-msisdn-success';

export const PAID_PRODUCT_LIST_REQUEST = 'paid-roduct-list-request';
export const PAID_PRODUCT_LIST_SUCCESS = 'paid-product-list-success';
export const PAID_PRODUCT_LIST_FAIL = 'paid-product-list-failure';

export const UPDATE_CUSTOMER_REQUEST = 'customer-update-requested';
export const UPDATE_CUSTOMER_SUCCESS = 'customer-update-succeeded';
export const UPDATE_CUSTOMER_FAIL = 'customer-update-failed';
export const UPDATE_CUSTOMER_RESET = 'customer-update-reset';
export const EDIT_CUSTOMER_DISPLAY_ERROR = 'edit-customer-display-error';

export const UPDATE_LANGUAGE = 'update-current-language';

// TODO: Test all use of the actions below before renaming the string to lower case
export const REFRESH_CUSTOMER_REQUEST = 'REFRESH_CUSTOMER_REQUEST';
export const REFRESH_CUSTOMER_FAILED = 'REFRESH_CUSTOMER_FAILED';
export const USER_LOGOUT = 'USER_LOGOUT';
// TODO: Rename this action, it does *both* search by msisdn & name
export const SEARCH_CUSTOMER_BY_MSISDN_REQUEST =
  'SEARCH_CUSTOMER_BY_MSISDN_REQUEST';

export const CHANGE_CURRENT_CUSTOMER = 'CHANGE_CURRENT_CUSTOMER';

export const REGISTER_CUSTOMER_REQUEST = 'REGISTER_CUSTOMER_REQUEST';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const REGISTER_CUSTOMER_FAIL = 'REGISTER_CUSTOMER_FAIL';
export const SET_REGISTRATION_ERROR = 'SET_REGISTRATION_ERROR';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';

export const SESSION_EXPIRED_ALERT = 'SESSION_EXPIRED_ALERT';
export const SESSION_REFRESH_REQUEST = 'SESSION_REFRESH_REQUEST';
export const SESSION_REFRESH_SUCCESS = 'SESSION_REFRESH_SUCCESS';
export const SESSION_REFRESH_FAIL = 'SESSION_REFRESH_FAIL';

export const DELETE_POLICY_REQUEST = 'DELETE_POLICY_REQUEST';
export const DELETE_POLICY_SUCCESS = 'DELETE_POLICY_SUCCESS';
export const DELETE_POLICY_FAIL = 'DELETE_POLICY_FAIL';

// TODO: END of capitalise strings

export const REPORT_REQUEST = 'report-request';
export const REPORT_REQUEST_SUCCESS = 'report-request-success';
export const REPORT_REQUEST_ERROR = 'report-request-failure';
export const REPORT_REQUEST_RESET = 'report-request-reset';

export const DAILY_REPORT_REQUEST = 'daily-report-request';
export const DAILY_REPORT_REQUEST_SUCCESS = 'daily-report-request-success';
export const DAILY_REPORT_REQUEST_ERROR = 'daily-report-request-failure';
export const DAILY_REPORT_REQUEST_RESET = 'daily-report-request-reset';

export const REPORT_CLAIM_FINALISATION_REQUEST =
  'report-claim-finalisation-request';
export const REPORT_CLAIM_FINALISATION_SUCCESS =
  'report-claim-finalisation-success';
export const REPORT_CLAIM_FINALISATION_ERROR =
  'report-claim-finalisation-failure';
export const REPORT_CLAIM_FINALISATION_RESET =
  'report-claim-finalisation-reset';

export const CLAIM_SEARCH_REQUEST = 'claim-search-request';
export const CLAIM_SEARCH_SUCCESS = 'claim-search-success';
export const CLAIM_SEARCH_FAILURE = 'claim-search-failure';
export const CLAIM_SEARCH_RESET = 'claim-search-reset';

export const CLAIM_STATUS_REQUEST = 'claim-status-request';
export const CLAIM_STATUS_SUCCESS = 'claim-status-success';
export const CLAIM_STATUS_FAILURE = 'claim-status-failure';

export const CLAIM_TYPES_SUCCESS = 'claim-types-success';
export const CLAIM_STATUSES_SUCCESS = 'claim-statuses-success'; // NOTE: Can be confused with the above 'CLAIM_STATUS_SUCCESS'

export const CHANGE_LOYALTY_REGISTRATION_REQUEST =
  'change-loyalty-registration-request';
export const CHANGE_LOYALTY_REGISTRATION_FAILURE =
  'change-loyalty-registration-failure';

export const DELETE_PREMIUM_REQUEST = 'delete-premium-request';
export const DELETE_PREMIUM_SUCCESS = 'delete-premium-success';
export const DELETE_PREMIUM_FAILURE = 'delete-premium-failure';

export const ADD_MISSING_PAYMENT_REQUEST = 'add-missing-payment-request';
export const ADD_MISSING_PAYMENT_SUCCESS = 'add-missing-payment-success';
export const ADD_MISSING_PAYMENT_FAIL = 'add-missing-payment-fail';
export const ADD_MISSING_PAYMENT_RESET = 'add-missing-payment-reset';

export const ADD_SINGLE_PAYMENT_REQUEST = 'add-single-payment-request';
export const ADD_SINGLE_PAYMENT_SUCCESS = 'add-single-payment-success';
export const ADD_SINGLE_PAYMENT_FAIL = 'add-single-payment-fail';
export const ADD_PAYMENT_RESET = 'add-payment-reset';

export const ADD_MULTIPLE_PAYMENT_REQUEST = 'add-multiple-payment-request';
export const ADD_MULTIPLE_PAYMENT_SUCCESS = 'add-multiple-payment-success';
export const ADD_MULTIPLE_PAYMENT_FAIL = 'add-multiple-payment-fail';

export const CLAIM_CALLBACK_REQUEST = 'claim-callback-request';
export const CLAIM_CALLBACK_SUCCESS = 'claim-callback-success';
export const CLAIM_CALLBACK_FAILURE = 'claim-callback-failure';

export const GET_CLAIM_CALLBACKS_CLOSED_REQUEST = 'get-closed-claim-callback-request';
export const GET_CLAIM_CALLBACKS_CLOSED_SUCCESS = 'get-closed-claim-callback-success';
export const GET_CLAIM_CALLBACKS_CLOSED_FAILURE = 'get-closed-claim-callback-failure';

export const FORGOT_PASSWORD_REQUEST = 'forgot-password-request';
export const FORGOT_PASSWORD_SUCCESS = 'forgot-password-success';
export const FORGOT_PASSWORD_FAILURE = 'forgot-password-failure';
export const FORGOT_PASSWORD_RESET = 'forgot-password-reset';

export const OPEN_EDIT_CUSTOMER_MODAL = 'open-edit-customer-modal';
export const CLOSE_EDIT_CUSTOMER_MODAL = 'close-edit-customer-modal';

export const REPORT_OPEN_CLAIM_REQUEST = 'report-open-claim-request';
export const REPORT_OPEN_CLAIM_SUCCESS = 'report-open-claim-success';
export const REPORT_OPEN_CLAIM_FAILURE = 'report-open-claim-failure';
export const REPORT_OPEN_CLAIM_RESET = 'report-open-claim-reset';

export const REPORT_REGISTRATION_REQUEST = 'report-registration-request';
export const REPORT_REGISTRATION_SUCCESS = 'report-registration-success';
export const REPORT_REGISTRATION_FAILURE = 'report-registration-failure';
export const REPORT_REGISTRATION_RESET = 'report-registration-reset';

export const REPORT_PAYABLE_CLAIM_REQUEST = 'report-payable-claim-request';
export const REPORT_PAYABLE_CLAIM_SUCCESS = 'report-payable-claim-success';
export const REPORT_PAYABLE_CLAIM_FAILURE = 'report-payable-claim-failure';
export const REPORT_PAYABLE_CLAIM_RESET = 'report-payable-claim-reset';

export const SET_SELECTED_POLICIES = 'set-selected-policies';

export const TIGO_UPLOAD_LOYALTY_REQUEST =
  'tigo-upload-loyalty-eligibilty-request';
export const TIGO_UPLOAD_LOYALTY_SUCCESS =
  'tigo-upload-loyalty-eligibilty-success';
export const TIGO_UPLOAD_LOYALTY_FAILURE =
  'tigo-upload-loyalty-eligibilty-failure';

export const LOYALTY_RENEWAL_REQUEST = 'loyalty-renewal-request';
export const LOYALTY_RENEWAL_SUCCESS = 'loyalty-renewal-success';
export const LOYALTY_RENEWAL_FAILURE = 'loyalty-renewal-failure';

export const CREATE_DEBIT_ORDER_REQUEST = 'create-debit-order-request';
export const CREATE_DEBIT_ORDER_SUCCESS = 'create-debit-order-success';
export const CREATE_DEBIT_ORDER_FAILURE = 'create-debit-order-failure';
export const RESET_CREATE_DEBIT_ORDER = 'reset-create-debit-order';

export const GET_DAILY_DEBIT_ORDER_REQUEST = 'get-daily-debit-order-request';
export const GET_DAILY_DEBIT_ORDER_SUCCESS = 'get-daily-debit-order-success';
export const GET_DAILY_DEBIT_ORDER_FAILURE = 'get-daily-debit-order-failuer';

export const UPDATE_DAILY_DEBIT_ORDER_REQUEST =
  'update-daily-debit-order-request';
export const UPDATE_DAILY_DEBIT_ORDER_SUCCESS =
  'update-daily-debit-order-success';
export const UPDATE_DAILY_DEBIT_ORDER_FAILURE =
  'update-daily-debit-order-failuer';

export const POLICIES_CREATED_REPORT_REQUEST =
  'policies-created-report-request';
export const POLICIES_CREATED_REPORT_REQUEST_SUCCESS =
  'policies-created-report-request-success';
export const POLICIES_CREATED_REPORT_REQUEST_ERROR =
  'policies-created-report-request-failure';
export const POLICIES_CREATED_REPORT_REQUEST_RESET =
  'policies-created-report-request-reset';

export const GET_POLICY_PRODUCT_REQUEST = 'get-entity-product-request';
export const GET_POLICY_PRODUCT_SUCCESS = 'get-entity-product-success';
export const GET_POLICY_PRODUCT_FAILURE = 'get-entity-product-failure';

export const INITIATE_POLICY_MOBILE_MONEY_PAYMENT_REQUEST =
  'initiate-entity-mobile-money-payment-request';
export const INITIATE_POLICY_MOBILE_MONEY_PAYMENT_SUCCESS =
  'initiate-entity-mobile-money-payment-success';
export const INITIATE_POLICY_MOBILE_MONEY_PAYMENT_FAILURE =
  'initiate-entity-mobile-money-payment-failure';
export const RESET_MOBILE_MONEY_INITIATION = 'reset-mobile-money-initiation';

export const INITIATE_PAYMENT_REQUEST = 'initiate-payment-request';
export const INITIATE_PAYMENT_SUCCESS = 'initiate-payment-success';
export const INITIATE_PAYMENT_FAILURE = 'initiate-payment-failure';
export const RESET_INITIATE_PAYMENT = 'reset-initiation-payment';

export const CLAIM_UPLOAD_CALLBACK_REQUEST = 'claim-upload-callback-request';
export const CLAIM_FILE_CREATE_REQUEST = 'claim-file-create-request';
export const CLAIM_FILE_CREATE_FAILURE = 'claim-file-create-failure';
export const CLAIM_FILE_CREATE_SUCCESS = 'claim-file-create-success';
export const CLAIM_FILE_DELETE_REQUEST = 'claim-file-delete-request';
export const CLAIM_FILE_DELETE_REQUEST_FAILURE =
  'claim-file-delete-request-failure';
export const CLAIM_FILE_DELETE_REQUEST_SUCCESS =
  'claim-file-delete-request-success';

export const CLAIM_CALLBACK_DELETE_REQUEST = 'claim-callback-delete-request';
export const CLAIM_CALLBACK_DELETE_SUCCESS = 'claim-callback-delete-success';
export const CLAIM_CALLBACK_DELETE_FAILURE = 'claim-callback-delete-failure';
export const RESET_CLAIM_CALLBACK_DELETE = 'reset-claim-callback-delete';

export const CLOSED_CLAIM_REQUEST = 'closed-claim-request';
export const CLOSED_CLAIM_SUCCESS = 'closed-claim-success';
export const CLOSED_CLAIM_FAIL = 'closed-claim-failure';

export const OPEN_CLAIM_REQUEST = 'open-claim-request';
export const OPEN_CLAIM_SUCCESS = 'open-claim-success';
export const OPEN_CLAIM_FAILURE = 'open-claim-failure';

export const SUMMARY_CLAIM_REQUEST = 'summary-claim-request';
export const SUMMARY_CLAIM_SUCCESS = 'summary-claim-success';
export const SUMMARY_CLAIM_FAILURE = 'summary-claim-failure';

export const REPORT_REFUND_REQUEST = 'report-refund-request';
export const REPORT_REFUND_SUCCESS = 'report-refund-success';
export const REPORT_REFUND_FAILURE = 'report-refund-failure';
export const REPORT_REFUND_RESET = 'report-refund-reset';

export const REPORT_PAID_CLAIM_REQUEST = 'report-paid-claim-request';
export const REPORT_PAID_CLAIM_SUCCESS = 'report-paid-claim-success';
export const REPORT_PAID_CLAIM_FAILURE = 'report-paid-claim-failure';
export const REPORT_PAID_CLAIM_RESET = 'report-paid-claim-reset';

export const REPORT_VAS_AGENT_REQUEST = 'report-vas-agent-request';
export const REPORT_VAS_AGENT_SUCCESS = 'report-vas-agent-success';
export const REPORT_VAS_AGENT_FAILURE = 'report-vas-agent-failure';
export const REPORT_VAS_AGENT_RESET = 'report-vas-agent-reset';

export const REPORT_AGENT_WEB_REQUEST = 'report-agent-web-request';
export const REPORT_AGENT_WEB_SUCCESS = 'report-agent-web-success';
export const REPORT_AGENT_WEB_FAILURE = 'report-agent-web-failure';
export const REPORT_AGENT_WEB_RESET = 'report-agent-web-reset';

export const PULL_CUSTOMER_KYC_REQUEST = 'pull-customer-kyc-request';
export const PULL_CUSTOMER_KYC_REQUEST_FAILURE = 'pull-customer-kyc-failure';
export const PULL_CUSTOMER_KYC_REQUEST_SUCCESS = 'pull-customer-kyc-success';

export const REFRESH_CLAIM_REQUEST = 'refresh-claim-request';
export const REFRESH_CLAIM_SUCCESS = 'refresh-claim-success';
export const REFRESH_CLAIM_FAILURE = 'refresh-claim-failure';

export const REPORT_PAYMENT_REQUEST = 'report-payment-request';
export const REPORT_PAYMENT_REQUEST_SUCCESS = 'report-payment-request-success';
export const REPORT_PAYMENT_REQUEST_ERROR = 'report-payment-request-failure';
export const REPORT_PAYMENT_REQUEST_RESET = 'report-payment-request-reset';

export const RETRY_FAILED_CLAIM_PAYMENT_REQUEST = 'retry-failed-claim-payment';
export const RETRY_FAILED_CLAIM_PAYMENT_REQUEST_SUCCESS =
  'retry-failed-claim-payment-success';
export const RETRY_FAILED_CLAIM_PAYMENT_REQUEST_FAILURE =
  'retry-failed-claim-payment-failure';

export const CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST =
  'claim-payment-update-outcome';
export const CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST_SUCCESS =
  'claim-payment-update-outcome-success';
export const CLAIM_PAYMENT_UPDATE_OUTCOME_REQUEST_FAILURE =
  'claim-payment-update-outcome-failure';

export const UPLOAD_BULK_SMS_REQUEST = 'upload-bulk-sms-request';
export const UPLOAD_BULK_SMS_SUCCESS = 'upload-bulk-sms-success';
export const UPLOAD_BULK_SMS_FAILED = 'upload-bulk-sms-failed';
export const UPLOAD_BULK_SMS_RESET = 'upload-bulk-sms-reset';

export const DOWNLOAD_BULK_SMS_REQUEST = 'download-bulk-sms-request';
export const DOWNLOAD_BULK_SMS_SUCCESS = 'download-download-bulk-sms-success';
export const DOWNLOAD_BULK_SMS_FAILED = 'download-bulk-sms-failed';
export const DOWNLOAD_BULK_SMS_RESET = 'download-bulk-sms-reset';

export const SUMMARY_BULK_SMS_REQUEST = 'summary-bulk-sms-request';
export const SUMMARY_BULK_SMS_SUCCESS = 'summary-bulk-sms-success';
export const SUMMARY_BULK_SMS_FAILED = 'summary-bulk-sms-failed';
export const SUMMARY_BULK_SMS_RESET = 'summary-bulk-sms-reset';

export const REPORT_CLAIM_NOTIFICATION_REQUEST =
  'report-claim-notification-request';
export const REPORT_CLAIM_NOTIFICATION_SUCCESS =
  'report-claim-notification-success';
export const REPORT_CLAIM_NOTIFICATION_FAILURE =
  'report-claim-notification-failed';
export const REPORT_CLAIM_NOTIFICATION_RESET =
  'report-claim-notification-reset';

export const REPORT_CLAIM_REGISTER_REQUEST = 'report-claim-register-request';
export const REPORT_CLAIM_REGISTER_SUCCESS = 'report-claim-register-success';
export const REPORT_CLAIM_REGISTER_FAILURE = 'report-claim-register-failed';
export const REPORT_CLAIM_REGISTER_RESET = 'report-claim-register-reset';

export const REPORT_PREMIUM_REGISTER_REQUEST =
  'report-premium-register-request';
export const REPORT_PREMIUM_REGISTER_SUCCESS =
  'report-premium-register-success';
export const REPORT_PREMIUM_REGISTER_FAILURE = 'report-premium-register-failed';
export const REPORT_PREMIUM_REGISTER_RESET = 'report-premium-register-reset';

export const REPORT_SUSPENDED_POLICIES_REQUEST =
  'report-suspended-policies-request';
export const REPORT_SUSPENDED_POLICIES_SUCCESS =
  'report-suspended-policies-success';
export const REPORT_SUSPENDED_POLICIES_FAILURE =
  'report-suspended-policies-failed';
export const REPORT_SUSPENDED_POLICIES_RESET =
  'report-suspended-policies-reset';

export const REPORT_PRE_AUTHORIZATION_REQUEST =
  'report-pre-authorization-request';
export const REPORT_PRE_AUTHORIZATION_SUCCESS =
  'report-pre-authorization-success';
export const REPORT_PRE_AUTHORIZATION_FAILURE =
  'report-pre-authorization-failed';
export const REPORT_PRE_AUTHORIZATION_RESET =
  'report-pre-authorization-reset';
export const REPORT_CLAIM_FINALIZATION_DEATH_REQUEST =
  'report-claim-finalization-death-request';
export const REPORT_CLAIM_FINALIZATION_DEATH_SUCCESS =
  'report-claim-finalization-death-success';
export const REPORT_CLAIM_FINALIZATION_DEATH_FAILURE =
  'report-claim-finalization-death-failed';
export const REPORT_CLAIM_FINALIZATION_DEATH_RESET =
  'report-claim-finalization-death-reset';
export const REPORT_FINALIZATION_HOSPITAL_CLAIM_REQUEST =
  'report-finalization-hospital-claim-request';
export const REPORT_FINALIZATION_HOSPITAL_CLAIM_SUCCESS =
  'report-finalization-hospital-claim-success';
export const REPORT_FINALIZATION_HOSPITAL_CLAIM_FAILURE =
  'report-finalization-hospital-claim-failed';
export const REPORT_FINALIZATION_HOSPITAL_CLAIM_RESET =
  'report-finalization-hospital-claim-reset';
export const REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_SUCCESS =
  'report-claim-finalization-reimbursement-success';
export const REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_REQUEST =
  'report-claim-finalization-reimbursement-request';
export const REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_FAILURE =
  'report-claim-finalization-reimbursement-failed';
  export const REPORT_CLAIM_FINALIZATION_REIMBURSEMENT_RESET =
  'report-claim-finalization-reimbursement-reset';
export const REPORT_CLAIM_DEATH_REQUEST =
  'report-claim-death-request';
export const REPORT_CLAIM_DEATH_SUCCESS =
  'report-claim-death-success';
export const REPORT_CLAIM_DEATH_FAILURE =
  'report-claim-death-failed';
export const REPORT_CLAIM_DEATH_RESET =
  'report-claim-death-reset';  

export const REPORT_HOSPITAL_CLAIM_REQUEST =
  'report-hospital-claim-request';
export const REPORT_HOSPITAL_CLAIM_SUCCESS =
  'report-hospital-claim-success';
export const REPORT_HOSPITAL_CLAIM_FAILURE =
  'report-hospital-claim-failed';
export const REPORT_HOSPITAL_CLAIM_RESET =
  'report-hospital-claim-reset';   


export const REPORT_CLAIM_REIMBURSEMENT_REQUEST =
  'report-claim-reimbursement-request';
export const REPORT_CLAIM_REIMBURSEMENT_SUCCESS =
  'report-claim-reimbursement-success';
export const REPORT_CLAIM_REIMBURSEMENT_FAILURE =
  'report-claim-reimbursement-failed';
export const REPORT_CLAIM_REIMBURSEMENT_RESET =
  'report-claim-reimbursement-reset';   

export const CLAIM_CONDITION_REQUEST = 'claim-condition-request';
export const CLAIM_CONDITION_SUCCESS = 'claim-condition-success';
export const CLAIM_CONDITION_FAILURE = 'claim-condition-failure';

export const REPORT_PREMIUM_DUE_REQUEST = 'report-premium-due-request';
export const REPORT_PREMIUM_DUE_SUCCESS = 'report-premium-due-success';
export const REPORT_PREMIUM_DUE_FAILURE = 'report-premium-due-failure';
export const REPORT_PREMIUM_DUE_RESET = 'report-premium-due-reset';

export const UPDATE_ROLES_AND_PARTNERS_REQUEST =
  'update-roles-and-partners-request';
export const UPDATE_ROLES_AND_PARTNERS_SUCCESS =
  'update-roles-and-partners-success';
export const UPDATE_ROLES_AND_PARTNERS_FAILED =
  'update-roles-and-partners-failed';
export const RESET_USER_CONTAINER = 'reset-user-container';

export const RESET_USER_PASSWORD_REQUEST = 'reset-user-password-request';
export const RESET_USER_PASSWORD_SUCCESS = 'reset-user-password-success';
export const RESET_USER_PASSWORD_FAILED = 'reset-user-password-failed';

export const DELETE_USER_REQUEST = 'delete-user-request';
export const DELETE_USER_FAILED = 'delete-user-failed';
export const DELETE_USER_SUCCESS = 'delete-user-success';

export const EXPIRED_CLAIM_REVERSAL_REQUEST = 'expired-claim-reversal-outcome';
export const EXPIRED_CLAIM_REVERSAL_REQUEST_SUCCESS =
  'expired-claim-reversal-outcome-success';
export const EXPIRED_CLAIM_REVERSAL_REQUEST_FAILURE =
  'expired-claim-reversal-outcome-failure';

export const CREATE_COUNTRY_REQUEST = 'create-country-request';
export const CREATE_COUNTRY_SUCCESS = 'create-country-success';
export const CREATE_COUNTRY_FAILED = 'create-country-failed';


export const GET_COUNTRIES_REQUEST = 'get-countries-request';
export const GET_COUNTRIES_SUCCESS = 'get-countries-success';
export const GET_COUNTRIES_FAILED = 'get-countries-failed';

export const UPDATE_COUNTRY_REQUEST = 'update-country-request';
export const UPDATE_COUNTRY_SUCCESS = 'update-country-success';
export const UPDATE_COUNTRY_FAILED = 'update-country-failed';

export const DELETE_COUNTRY_REQUEST = 'delete-country-request';
export const DELETE_COUNTRY_SUCCESS = 'delete-country-success';
export const DELETE_COUNTRY_FAILED = 'delete-country-failed';

export const RESET_CREATE_COUNTRY_ERROR = 'RESET_CREATE_COUNTRY_ERROR';
export const RESET_CREATE_COUNTRY_SUCCESS = 'RESET_CREATE_COUNTRY_SUCCESS';
export const RESET_DELETE_COUNTRY_ERROR = 'RESET_DELETE_COUNTRY_ERROR';
export const RESET_DELETE_COUNTRY_SUCCESS = 'RESET_DELETE_COUNTRY_SUCCESS';
export const RESET_UPDATE_COUNTRY_ERROR = 'RESET_UPDATE_COUNTRY_ERROR';
export const RESET_UPDATE_COUNTRY_SUCCESS = 'RESET_UPDATE_COUNTRY_SUCCESS';
export const RESET_COUNTRY_TO_DELETE = 'RESET_COUNTRY_TO_DELETE';
export const RESET_COUNTRY_TO_UPDATE = 'RESET_COUNTRY_TO_UPDATE';
export const SET_COUNTRY_TO_DELETE = 'SET_COUNTRY_TO_DELETE';
export const SET_COUNTRY_TO_UPDATE = 'SET_COUNTRY_TO_UPDATE';


export const GET_LANGUAGES_REQUEST = 'get-languages-request';
export const GET_LANGUAGES_SUCCESS = 'get-languages-success';
export const GET_LANGUAGES_FAILED = 'get-languages-failed';

export const VIEW_ALL_PARTNERS_REQUEST = 'view-all-partners-request';
export const VIEW_ALL_PARTNERS_FAILURE = 'view-all-partners-failure';
export const VIEW_ALL_PARTNERS_SUCCESS = 'view-all-partners-success';

export const CREATE_PARTNER_REQUEST = 'create-partner-request';
export const CREATE_PARTNER_FAILURE = 'create-partner-failure';
export const CREATE_PARTNER_SUCCESS = 'create-partner-success';

export const RESET_CREATE_PARTNER_SUCCESS = 'reset-create-partner-success';
export const RESET_CREATE_PARTNER_ERROR = 'reset-create-partner-error';

export const VIEW_PARTNER_REQUEST = 'view-partner-request';
export const VIEW_PARTNER_FAILURE = 'view-partner-failure';
export const VIEW_PARTNER_SUCCESS = 'view-partner-success';

export const VIEW_PRODUCT_REQUEST = 'view-product-request';
export const VIEW_PRODUCT_FAILURE = 'view-product-failure';
export const VIEW_PRODUCT_SUCCESS = 'view-product-success';

export const VIEW_ALL_PRODUCTS_REQUEST = 'view-all-products-request';
export const VIEW_ALL_PRODUCTS_FAILURE = 'view-all-products-failure';
export const VIEW_ALL_PRODUCTS_SUCCESS = 'view-all-products-success';

export const CREATE_PRODUCT_REQUEST = 'create-product-request';
export const CREATE_PRODUCT_FAILURE = 'create-product-failure';
export const CREATE_PRODUCT_SUCCESS = 'create-product-success';
export const RESET_CREATE_PRODUCT_SUCCESS = 'reset-create-product-success';
export const RESET_CREATE_PRODUCT_ERROR = 'reset-create-product-error';

export const EDIT_PRODUCT_REQUEST = 'edit-product-request';
export const EDIT_PRODUCT_FAILURE = 'edit-product-failure';
export const EDIT_PRODUCT_SUCCESS = 'edit-product-success';
export const RESET_EDIT_PRODUCT_SUCCESS = 'reset-edit-product-success';
export const RESET_EDIT_PRODUCT_ERROR = 'reset-edit-product-error';


export const PRODUCT_ENUMS_REQUEST = 'product-enums-request';
export const PRODUCT_ENUMS_FAILURE = 'product-enums-failure';
export const PRODUCT_ENUMS_SUCCESS = 'product-enums-success';

export const CREATE_PREMIUM_REQUEST = 'create-premium-request';
export const CREATE_PREMIUM_SUCCESS = 'create-premium-success';
export const CREATE_PREMIUM_FAILURE = 'create-premium-failure';

export const RESET_CREATE_PREMIUM_SUCCESS = 'reset-create-premium-success';
export const RESET_CREATE_PREMIUM_ERROR = 'reset-create-premium-error';

export const HARD_DELETE_PREMIUM_REQUEST = 'hard-delete-premium-request';
export const HARD_DELETE_PREMIUM_SUCCESS = 'hard-delete-premium-success';
export const HARD_DELETE_PREMIUM_FAILURE = 'hard-delete-premium-failure';

export const RESET_HARD_DELETE_PREMIUM_SUCCESS = 'reset-hard-delete-premium-success';
export const RESET_HARD_DELETE_PREMIUM_ERROR = 'reset-hard-delete-premium-error';

export const SOFT_DELETE_PREMIUM_REQUEST = 'soft-delete-premium-request';
export const SOFT_DELETE_PREMIUM_SUCCESS = 'soft-delete-premium-success';
export const SOFT_DELETE_PREMIUM_FAILURE = 'soft-delete-premium-failure';

export const EDIT_PREMIUM_REQUEST = 'update-premium-request';
export const EDIT_PREMIUM_SUCCESS = 'update-premium-success';
export const EDIT_PREMIUM_FAILURE = 'update-premium-failure';
export const RESET_EDIT_PREMIUM_SUCCESS = 'reset-edit-premium-success';
export const RESET_EDIT_PREMIUM_ERROR = 'reset-edit-premium-error';

export const EDIT_BENEFIT_REQUEST = 'update-Benefit-request';
export const EDIT_BENEFIT_SUCCESS = 'update-Benefit-success';
export const EDIT_BENEFIT_FAILURE = 'update-Benefit-failure';
export const RESET_EDIT_BENEFIT_SUCCESS = 'reset-edit-Benefit-success';
export const RESET_EDIT_BENEFIT_ERROR = 'reset-edit-Benefit-error';


// benefits
export const CREATE_BENEFIT_REQUEST = 'create-benefit-request';
export const CREATE_BENEFIT_SUCCESS = 'create-benefit-success';
export const CREATE_BENEFIT_FAILURE = 'create-benefit-failure';

export const RESET_CREATE_BENEFIT_SUCCESS = 'reset-create-benefit-success';
export const RESET_CREATE_BENEFIT_ERROR = 'reset-create-benefit-error';

export const HARD_DELETE_BENEFIT_REQUEST = 'hard-delete-benefit-request';
export const HARD_DELETE_BENEFIT_SUCCESS = 'hard-delete-benefit-success';
export const HARD_DELETE_BENEFIT_FAILURE = 'hard-delete-benefit-failure';

export const RESET_HARD_DELETE_BENEFIT_SUCCESS = 'reset-hard-delete-benefit-success';
export const RESET_HARD_DELETE_BENEFIT_ERROR = 'reset-hard-delete-benefit-error';

export const SOFT_DELETE_BENEFIT_REQUEST = 'soft-delete-benefit-request';
export const SOFT_DELETE_BENEFIT_SUCCESS = 'soft-delete-benefit-success';
export const SOFT_DELETE_BENEFIT_FAILURE = 'soft-delete-benefit-failure';


export const SET_PREMIUM_TO_EDIT = 'SET_PREMIUM_TO_EDIT,'
export const SET_PREMIUM_TO_DELETE = 'SET_PREMIUM_TO_DELETE,'
export const SET_BENEFIT_TO_EDIT = 'SET_BENEFIT_TO_EDIT,'
export const SET_BENEFIT_TO_DELETE = 'SET_BENEFIT_TO_DELETE,'
export const RESET_PREMIUM_TO_EDIT = 'RESET_PREMIUM_TO_EDIT,'
export const RESET_PREMIUM_TO_DELETE = 'RESET_PREMIUM_TO_DELETE,'
export const RESET_BENEFIT_TO_EDIT = 'RESET_BENEFIT_TO_EDIT,'
export const RESET_BENEFIT_TO_DELETE = 'RESET_BENEFIT_TO_DELETE,'


export const CREATE_PRODUCT_PREMIUM_REQUEST = 'create-product-premium-request';
export const CREATE_PRODUCT_PREMIUM_FAILURE = 'create-product-premium-failure';
export const CREATE_PRODUCT_PREMIUM_SUCCESS = 'create-product-premium-success';

export const PRODUCT_PREMIUM_ENUMS_REQUEST = 'product-premium-enums-request';
export const PRODUCT_PREMIUM_ENUMS_FAILURE = 'product-premium-enums-failure';
export const PRODUCT_PREMIUM_ENUMS_SUCCESS = 'product-premium-enums-success';

export const VIEW_ALL_PRODUCT_PREMIUMS_REQUEST =
  'view-all-product-premiums-request';
export const VIEW_ALL_PRODUCT_PREMIUMS_FAILURE =
  'view-all-product-premiums-failure';
export const VIEW_ALL_PRODUCT_PREMIUMS_SUCCESS =
  'view-all-product-premiums-success';

export const GET_SMS_TEMPLATES_REQUEST = 'get-sms-templates-request';
export const GET_SMS_TEMPLATES_FAIL = 'get-sms-templates-failure';
export const GET_SMS_TEMPLATES_SUCCESS = 'get-sms-templates-success';

export const CREATE_SMS_TEMPLATES_REQUEST = 'create-sms-templates-request';
export const CREATE_SMS_TEMPLATES_FAIL = 'create-sms-templates-failure';
export const CREATE_SMS_TEMPLATES_SUCCESS = 'create-sms-templates-success';

export const DELETE_SMS_TEMPLATES_REQUEST = 'delete-sms-templates-request';
export const DELETE_SMS_TEMPLATES_FAIL = 'delete-sms-templates-failure';
export const DELETE_SMS_TEMPLATES_SUCCESS = 'delete-sms-templates-success';

export const UPDATE_SMS_TEMPLATES_REQUEST = 'update-sms-templates-request';
export const UPDATE_SMS_TEMPLATES_FAIL = 'update-sms-templates-failure';
export const UPDATE_SMS_TEMPLATES_SUCCESS = 'update-sms-templates-success';

export const GET_SMS_TEMPLATES_RESET = 'get-sms-templates-reset';
export const CREATE_SMS_TEMPLATES_RESET = 'create-sms-templates-reset';
export const UPDATE_SMS_TEMPLATES_RESET = 'update-sms-templates-reset';
export const DELETE_SMS_TEMPLATES_RESET = 'delete-sms-templates-reset';

export const GET_SUPPORTED_PARAMETERS_REQUEST = 'get-supported-parameters-request';
export const GET_SUPPORTED_PARAMETERS_FAIL = 'get-supported-parameters-failure';
export const GET_SUPPORTED_PARAMETERS_SUCCESS = 'get-supported-parameters-success';

export const STOP_BLAST_REQUEST = 'stop-blast-request';
export const STOP_BLAST_SUCCESS = 'stop-blast-success';
export const STOP_BLAST_FAILED = 'stop-blast-failed';
export const STOP_BLAST_RESET = 'stop-blast-reset';

export const GET_BATCH_PROGRESS_REQUEST = 'get-batch-progress-request';
export const GET_BATCH_PROGRESS_SUCCESS = 'get-batch-progress-success';
export const GET_BATCH_PROGRESS_FAILED = 'get-batch-progress-failed';

export const SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_REQUEST = 'send-recurring-recommedation-sms-request';
export const SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_SUCCESS = 'send-recurring-recommedation-sms-success';
export const SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_FAILED = 'send-recurring-recommedation-sms-failed';
export const SEND_RECURRING_PAYMENT_RECOMMENDATION_SMS_RESET = 'send-recurring-recommedation-sms-reset';

export const GET_GROUPS_REQUEST = 'get-groups-request';
export const GET_GROUPS_SUCCESS = 'get-groups-success';
export const GET_GROUPS_FAILURE = 'get-groups-failure';

export const GET_CURRENT_GROUP_MEMBERS_REQUEST = 'get-members-request';
export const GET_CURRENT_GROUP_MEMBERS_SUCCESS = 'get-members-success';
export const GET_CURRENT_GROUP_MEMBERS_FAILURE = 'get-members-failure';

export const GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_REQUEST = 'get-members-for-admins-request';
export const GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_SUCCESS = 'get-members-for-admins-success';
export const GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS_FAILURE = 'get-members-for-admins-failure';
export const RESET_GET_CURRENT_GROUP_MEMBERS_FOR_ADMINS = 'reset-get-members-for-admins';

export const GET_CURRENT_GROUP_MEMBERS_FOR_CSV_REQUEST = 'get-members-for-csv-request';
export const GET_CURRENT_GROUP_MEMBERS_FOR_CSV_SUCCESS = 'get-members-for-csv-success';
export const GET_CURRENT_GROUP_MEMBERS_FOR_CSV_FAILURE = 'get-members-for-csv-failure';
export const RESET_GET_CURRENT_GROUP_MEMBERS_FOR_CSV = 'reset-get-members-for-csv';

export const GET_CURRENT_GROUP_POLICIES_REQUEST = 'get-current-group-policies-request';
export const GET_CURRENT_GROUP_POLICIES_SUCCESS = 'get-current-group-policies-success';
export const GET_CURRENT_GROUP_POLICIES_FAILURE = 'get-current-group-policies-failure';

export const GET_CURRENT_GROUP_PAYMENTS_REQUEST = 'get-current-group-payments-request';
export const GET_CURRENT_GROUP_PAYMENTS_SUCCESS = 'get-current-group-payments-success';
export const GET_CURRENT_GROUP_PAYMENTS_FAILURE = 'get-current-group-payments-failure';

export const GET_CURRENT_GROUP_PREMIUMS_REQUEST = 'get-current-group-premiums-request';
export const GET_CURRENT_GROUP_PREMIUMS_SUCCESS = 'get-current-group-premiums-success';
export const GET_CURRENT_GROUP_PREMIUMS_FAILURE = 'get-current-group-premiums-failure';

export const GET_CURRENT_GROUP_CLAIMS_REQUEST = 'get-current-group-claims-request';
export const GET_CURRENT_GROUP_CLAIMS_SUCCESS = 'get-current-group-claims-success';
export const GET_CURRENT_GROUP_CLAIMS_FAILURE = 'get-current-group-claims-failure';

export const GET_CURRENT_GROUP_CLAIM_CALLBACKS_REQUEST = 'get-current-group-claim-callbacks-request';
export const GET_CURRENT_GROUP_CLAIM_CALLBACKS_SUCCESS = 'get-current-group-claim-callbacks-success';
export const GET_CURRENT_GROUP_CLAIM_CALLBACKS_FAILURE = 'get-current-group-claim-callbacks-failure';

export const CREATE_GROUP_CLAIM_CALLBACK_REQUEST = 'create-group-claim-callback-request';
export const CREATE_GROUP_CLAIM_CALLBACK_SUCCESS = 'create-group-claim-callback-success';
export const CREATE_GROUP_CLAIM_CALLBACK_FAILURE = 'create-group-claim-callback-failure';
export const RESET_CREATE_GROUP_CLAIM_CALLBACK = 'reset-create-group-claim-callback';

export const PRE_REGISTER_GROUP_SUCCESS = 'pre-register-group-success';
export const PRE_REGISTER_GROUP_REQUEST = 'pre-register-group-request';
export const PRE_REGISTER_GROUP_FAILURE = 'pre-register-group-failure';
export const RESET_GROUP_PRE_REGISTRAION = 'reset-group-pre-registration';

export const REGISTER_GROUP_REQUEST = 'register-group-request';
export const REGISTER_GROUP_SUCCESS = 'register-group-success';
export const REGISTER_GROUP_FAILURE = 'register-group-failure';
export const RESET_GROUP_REGISTRAION = 'reset-group-registration';


export const GROUP_CERTIFICATE_UPLOAD_CALLBACK = 'group-certificate-upload-callback';
export const GROUP_EXCEL_UPLOAD_CALLBACK = 'group-excel-upload-callback';

export const GET_GROUP_PRODUCTS_REQUEST = 'get-group-products-request';
export const GET_GROUP_PRODUCTS_SUCCESS = 'get-group-products-success';
export const GET_GROUP_PRODUCTS_FAILURE = 'get-group-products-failure';

export const GET_ACTUAL_GROUP_QUOTE_REQUEST = 'get-actual-group-quote-request';
export const GET_ACTUAL_GROUP_QUOTE_SUCCESS = 'get-actual-group-quote-success';
export const GET_ACTUAL_GROUP_QUOTE_FAILURE = 'get-actual-group-quote-failure';

export const RESET_ACTUAL_QUOTE = 'reset-actual-group-quote';

export const GET_ALL_GROUPS_REQUEST = 'get-all-groups-request';
export const GET_ALL_GROUPS_SUCCESS = 'get-all-groups-success';
export const GET_ALL_GROUPS_FAILURE = 'get-all-groups-failure';

export const DELETE_GROUP_REQUEST = 'delete-group-request';
export const DELETE_GROUP_SUCCESS = 'delete-group-success';
export const DELETE_GROUP_FAILURE = 'delete-group-failure';

export const DELETE_MY_GROUP_REQUEST = 'delete-my-group-request';
export const DELETE_MY_GROUP_SUCCESS = 'delete-my-group-success';
export const DELETE_MY_GROUP_FAILURE = 'delete-my-group-failure';

export const ADD_GROUP_PAYMENT_REQUEST = 'add-group-payment-request';
export const ADD_GROUP_PAYMENT_SUCCESS = 'add-group-payment-success';
export const ADD_GROUP_PAYMENT_FAILURE = 'add-group-payment-failure';
export const RESET_ADD_GROUP_PAYMENT = 'reset-group-payment';

export const ADD_REMITTANCES_REQUEST = 'add-remittances-request';
export const ADD_REMITTANCES_SUCCESS = 'add-remittances-success';
export const ADD_REMITTANCES_FAIL = 'add-remittances-fail';
export const ADD_REMITTANCES_RESET = 'add-remittances-reset';

export const GET_ALL_CASHBACKS_REQUEST = 'get-all-cashbacks-request';
export const GET_ALL_CASHBACKS_SUCCESS = 'get-all-cashback-success';
export const GET_ALL_CASHBACKS_FAILURE = 'get-all-cashback-failure';

export const MARK_CASHBACK_AS_PAID_REQUEST = 'mark-cashback-as-paid-request';
export const MARK_CASHBACK_AS_PAID_SUCCESS = 'mark-cashback-as-paid-success';
export const MARK_CASHBACK_AS_PAID_FAILURE = 'mark-cashback-as-paid-failure';

export const MARK_CASHBACK_AS_UNPAID_REQUEST = 'mark-cashback-as-unpaid-request';
export const MARK_CASHBACK_AS_UNPAID_SUCCESS = 'mark-cashback-as-unpaid-success';
export const MARK_CASHBACK_AS_UNPAID_FAILURE = 'mark-cashback-as-unpaid-failure';

export const UPLOAD_LOYALTY_DATA_REQUEST = 'upload-loyalty-data-request';
export const UPLOAD_LOYALTY_DATA_SUCCESS = 'upload-loyalty-data-success';
export const UPLOAD_LOYALTY_DATA_FAIL = 'upload-loyalty-data-fail';
export const UPLOAD_LOYALTY_DATA_RESET = 'upload-loyalty-data-reset';

export const SET_LOYALTIES_START_DATE_REQUEST = 'set-loyalties-start-date-request';
export const SET_LOYALTIES_END_DATE_REQUEST = 'set-loyalties-end-date-request';

export const MAP_COLUMN_NUMBER_TO_FIELDS_REQUEST = 'map-column-number-to-fields-request';
export const MAP_COLUMN_NUMBER_TO_FIELDS_SUCCESS = 'map-column-number-to-fields-success';
export const MAP_COLUMN_NUMBER_TO_FIELDS_FAIL = 'map-column-number-to-fields-fail';

export const GET_PREVIOUS_LOYALTY_STATUS_REQUEST = 'get-previous-loyalty-status-request';
export const GET_PREVIOUS_LOYALTY_STATUS_SUCCESS = 'get-previous-loyalty-status-success';
export const GET_PREVIOUS_LOYALTY_STATUS_FAIL = 'get-previous-loyalty-status-fail';

export const GET_PREVIOUS_LOYALTIES_STATUS_REQUEST = 'get-previous-loyalties-status-request';
export const GET_PREVIOUS_LOYALTIES_STATUS_SUCCESS = 'get-previous-loyalties-status-success';
export const GET_PREVIOUS_LOYALTIES_STATUS_FAIL = 'get-previous-loyalties-status-fail';

export const GET_TEMP_MEMBERS_REQUEST = 'get-temp-members-request';
export const GET_TEMP_MEMBERS_SUCCESS = 'get-temp-members-success';
export const GET_TEMP_MEMBERS_FAILURE = 'get-temp-members-failure';

export const DELETE_PRINCIPAL_REQUEST = 'delete-principal-request';
export const DELETE_PRINCIPAL_SUCCESS = 'delete-principal-success';
export const DELETE_PRINCIPAL_FAILURE = 'delete-principal-failure';

export const EDIT_PRINCIPAL_REQUEST = 'edit-principal-request';
export const EDIT_PRINCIPAL_SUCCESS = 'edit-principal-success';
export const EDIT_PRINCIPAL_FAILURE = 'edit-principal-failure';

export const ADD_DEPENDANT_REQUEST = 'add-dependant-request';
export const ADD_DEPENDANT_SUCCESS = 'add-dependant-success';
export const ADD_DEPENDANT_FAILURE = 'add-dependant-failure';

export const EDIT_DEPENDANT_REQUEST = 'edit-dependant-request';
export const EDIT_DEPENDANT_SUCCESS = 'edit-dependant-success';
export const EDIT_DEPENDANT_FAILURE = 'edit-dependant-failure';

export const DELETE_DEPENDANT_REQUEST = 'delete-dependant-request';
export const DELETE_DEPENDANT_SUCCESS = 'delete-dependant-success';
export const DELETE_DEPENDANT_FAILURE = 'delete-dependant-failure';

export const ADD_CUSTOMER_DEPENDANT_REQUEST = 'add-customer-dependant-request';
export const ADD_CUSTOMER_DEPENDANT_SUCCESS = 'add-customer-dependant-success';
export const ADD_CUSTOMER_DEPENDANT_FAILURE = 'add-customer-dependant-failure';

export const UPDATE_CUSTOMER_DEPENDANT_REQUEST = 'update-customer-dependant-request';
export const UPDATE_CUSTOMER_DEPENDANT_SUCCESS = 'update-customer-dependant-success';
export const UPDATE_CUSTOMER_DEPENDANT_FAILURE = 'update-customer-dependant-failure';

export const DELETE_CUSTOMER_DEPENDANT_REQUEST = 'delete-customer-dependant-request';
export const DELETE_CUSTOMER_DEPENDANT_SUCCESS = 'delete-customer-dependant-success';
export const DELETE_CUSTOMER_DEPENDANT_FAILURE = 'delete-customer-dependant-failure';

export const GET_CUSTOMER_DEPENDANTS_REQUEST = 'get-customer-dependants-request';
export const GET_CUSTOMER_DEPENDANTS_SUCCESS = 'get-customer-dependants-success';
export const GET_CUSTOMER_DEPENDANTS_FAILURE = 'get-customer-dependants-failure';

export const GET_CUSTOMER_DEPENDANT_REQUEST = 'get-customer-dependant-request';
export const GET_CUSTOMER_DEPENDANT_SUCCESS = 'get-customer-dependant-success';
export const GET_CUSTOMER_DEPENDANT_FAILURE = 'get-customer-dependant-failure';
export const RESET_GET_CUSTOMER_DEPENDANT = 'reser-get-customer-dependant';

export const RESET_CREATE_CUSTOMER_DEPENDANTS_SUCCESS = 'RESET_CREATE_CUSTOMER_DEPENDANTS_SUCCESS'
export const RESET_CREATE_CUSTOMER_DEPENDANTS_ERROR = 'RESET_CREATE_CUSTOMER_DEPENDANTS_ERROR'

export const RESET_DELETE_CUSTOMER_DEPENDANTS_SUCCESS = 'RESET_DELETE_CUSTOMER_DEPENDANTS_SUCCESS'
export const RESET_DELETE_CUSTOMER_DEPENDANTS_ERROR = 'RESET_DELETE_CUSTOMER_DEPENDANTS_ERROR'

export const RESET_EDIT_CUSTOMER_DEPENDANTS_SUCCESS = 'RESET_EDIT_CUSTOMER_DEPENDANTS_SUCCESS'
export const RESET_EDIT_CUSTOMER_DEPENDANTS_ERROR = 'RESET_EDIT_CUSTOMER_DEPENDANTS_ERROR'

export const SET_CUSTOMER_DEPENDANTS_TO_EDIT = 'SET_CUSTOMER_DEPENDANTS_TO_EDIT'
export const SET_CUSTOMER_DEPENDANTS_TO_DELETE = 'SET_CUSTOMER_DEPENDANTS_TO_DELETE'
export const RESET_CUSTOMER_DEPENDANTS_TO_EDIT = 'RESET_CUSTOMER_DEPENDANTS_TO_EDIT,'
export const RESET_CUSTOMER_DEPENDANTS_TO_DELETE = 'RESET_CUSTOMER_DEPENDANTS_TO_DELETE,'

export const ADD_CUSTOMER_DOCUMENT_REQUEST = 'add-customer-document-request';
export const ADD_CUSTOMER_DOCUMENT_SUCCESS = 'add-customer-document-success';
export const ADD_CUSTOMER_DOCUMENT_FAILURE = 'add-customer-document-failure';

export const UPDATE_CUSTOMER_DOCUMENT_REQUEST = 'update-customer-document-request';
export const UPDATE_CUSTOMER_DOCUMENT_SUCCESS = 'update-customer-document-success';
export const UPDATE_CUSTOMER_DOCUMENT_FAILURE = 'update-customer-document-failure';

export const DELETE_CUSTOMER_DOCUMENT_REQUEST = 'delete-customer-document-request';
export const DELETE_CUSTOMER_DOCUMENT_SUCCESS = 'delete-customer-document-success';
export const DELETE_CUSTOMER_DOCUMENT_FAILURE = 'delete-customer-document-failure';
export const SET_CUSTOMER_DOCUMENT_TO_DELETE = 'set-delete-customer-document-failure';
export const RESET_DELETE_CUSTOMER_DOCUMENT_SUCCESS = 'reset-delete-customer-document-failure';
export const RESET_CUSTOMER_DOCUMENT_TO_DELETE = 'reset-customer-document-to-delete';
export const RESET_CUSTOMER_DELETE_DOCUMENT_ERROR = 'reset-customer-delete-document-error';



export const GET_CUSTOMER_DOCUMENTS_REQUEST = 'get-customer-documents-request';
export const GET_CUSTOMER_DOCUMENTS_SUCCESS = 'get-customer-documents-success';
export const GET_CUSTOMER_DOCUMENTS_FAILURE = 'get-customer-documents-failure';

export const GET_CUSTOMER_DOCUMENT_REQUEST = 'get-customer-document-request';
export const GET_CUSTOMER_DOCUMENT_SUCCESS = 'get-customer-document-success';
export const GET_CUSTOMER_DOCUMENT_FAILURE = 'get-customer-document-failure';

export const ADD_PRINCIPAL_REQUEST = 'add-principal-request';
export const ADD_PRINCIPAL_SUCCESS = 'add-principal-success';
export const ADD_PRINCIPAL_FAILURE = 'add-principal-failure';

export const GET_ALL_TEMP_MEMBERS_REQUEST = 'get-all-temp-members-request';
export const GET_ALL_TEMP_MEMBERS_SUCCESS = 'get-all-temp-members-success';
export const GET_ALL_TEMP_MEMBERS_FAILURE = 'get-all-temp-members-failure';

export const UPLOAD_EXCEL_MEMBERS_REQUEST = 'upload-excel-members-request';
export const UPLOAD_EXCEL_MEMBERS_SUCCESS = 'upload-excel-members-success';
export const UPLOAD_EXCEL_MEMBERS_FAILURE = 'upload-excel-members-failure';

export const UPDATE_GROUP_REQUEST = 'update-group-request';
export const UPDATE_GROUP_SUCCESS = 'update-group-success';
export const UPDATE_GROUP_FAILURE = 'update-group-failure';

export const GET_GROUP_REQUEST = 'get-group-request';
export const GET_GROUP_SUCCESS = 'get-group-success';
export const GET_GROUP_FAILURE = 'get-group-failure';

export const RESET_EXCEL_FILE_UPLOAD = 'reset-excel-file-upload';
export const RESET_ADD_EDIT_MEMBERS = 'reset-add-edit-members';

export const GET_PARTNER_CONFIGS_REQUEST = 'GET_PARTNER_CONFIGS_REQUEST';
export const GET_PARTNER_CONFIGS_FAILURE = 'GET_PARTNER_CONFIGS_FAILURE';
export const GET_PARTNER_CONFIGS_SUCCESS = 'GET_PARTNER_CONFIGS_SUCCESS';
export const GET_PARTNER_CONFIGS_CLAIM_REQUEST = 'GET_PARTNER_CONFIGS_CLAIM_REQUEST';
export const GET_PARTNER_CONFIGS_CLAIM_FAILURE = 'GET_PARTNER_CONFIGS_CLAIM_FAILURE';
export const GET_PARTNER_CONFIGS_CLAIM_SUCCESS = 'GET_PARTNER_CONFIGS_CLAIM_SUCCESS';
export const GET_PARTNER_CONFIGS_GROUP_REQUEST = 'GET_PARTNER_CONFIGS_GROUP_REQUEST';
export const GET_PARTNER_CONFIGS_GROUP_FAILURE = 'GET_PARTNER_CONFIGS_GROUP_FAILURE';
export const GET_PARTNER_CONFIGS_GROUP_SUCCESS = 'GET_PARTNER_CONFIGS_GROUP_SUCCESS';

export const UPDATE_PARTNER_REQUEST = 'UPDATE_PARTNER_REQUEST';
export const UPDATE_PARTNER_FAILURE = 'UPDATE_PARTNER_FAILURE';
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS';
export const RESET_UPDATE_PARTNER_SUCCESS = 'RESET_UPDATE_PARTNER_SUCCESS';
export const RESET_UPDATE_PARTNER_ERROR = 'RESET_UPDATE_PARTNER_ERROR';

export const UPDATE_PARTNER_CONFIG_REQUEST = 'UPDATE_PARTNER_CONFIG_REQUEST';
export const UPDATE_PARTNER_CONFIG_FAILURE = 'UPDATE_PARTNER_CONFIG_FAILURE';
export const UPDATE_PARTNER_CONFIG_SUCCESS = 'UPDATE_PARTNER_CONFIG_SUCCESS';
export const RESET_UPDATE_PARTNER_CONFIG_SUCCESS = 'RESET_UPDATE_PARTNER_CONFIG_SUCCESS';
export const RESET_UPDATE_PARTNER_CONFIG_ERROR = 'RESET_UPDATE_PARTNER_CONFIG_ERROR';

export const UPDATE_PARTNER_CONFIG_CLAIM_REQUEST = 'UPDATE_PARTNER_CONFIG_CLAIM_REQUEST';
export const UPDATE_PARTNER_CONFIG_CLAIM_FAILURE = 'UPDATE_PARTNER_CONFIG_CLAIM_FAILURE';
export const UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS = 'UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS';
export const RESET_UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS = 'RESET_UPDATE_PARTNER_CONFIG_CLAIM_SUCCESS';
export const RESET_UPDATE_PARTNER_CONFIG_CLAIM_ERROR = 'RESET_UPDATE_PARTNER_CONFIG_CLAIM_ERROR';

export const UPDATE_PARTNER_CONFIG_GROUP_REQUEST = 'UPDATE_PARTNER_CONFIG_GROUP_REQUEST';
export const UPDATE_PARTNER_CONFIG_GROUP_FAILURE = 'UPDATE_PARTNER_CONFIG_GROUP_FAILURE';
export const UPDATE_PARTNER_CONFIG_GROUP_SUCCESS = 'UPDATE_PARTNER_CONFIG_GROUP_SUCCESS';
export const RESET_UPDATE_PARTNER_CONFIG_GROUP_SUCCESS = 'RESET_UPDATE_PARTNER_CONFIG_GROUP_SUCCESS';
export const RESET_UPDATE_PARTNER_CONFIG_GROUP_ERROR = 'RESET_UPDATE_PARTNER_CONFIG_GROUP_ERROR';


export const GET_GROUP_PRODUCT_CONFIGS_REQUEST = 'GET_GROUP_PRODUCT_CONFIGS_REQUEST';
export const GET_GROUP_PRODUCT_CONFIGS_FAILURE = 'GET_GROUP_PRODUCT_CONFIGS_FAILURE';
export const GET_GROUP_PRODUCT_CONFIGS_SUCCESS = 'GET_GROUP_PRODUCT_CONFIGS_SUCCESS';

export const UPDATE_GROUP_PRODUCT_CONFIGS_REQUEST = 'UPDATE_GROUP_PRODUCT_CONFIGS_REQUEST';
export const UPDATE_GROUP_PRODUCT_CONFIGS_FAILURE = 'UPDATE_GROUP_PRODUCT_CONFIGS_FAILURE';
export const UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS = 'UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS';
export const RESET_UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS = 'RESET_UPDATE_GROUP_PRODUCT_CONFIGS_SUCCESS';
export const RESET_UPDATE_GROUP_PRODUCT_CONFIGS_ERROR = 'RESET_UPDATE_GROUP_PRODUCT_CONFIGS_ERROR';

export const ADD_GROUP_PRODUCT_CONFIGS_REQUEST = 'ADD_GROUP_PRODUCT_CONFIGS_REQUEST';
export const ADD_GROUP_PRODUCT_CONFIGS_FAILURE = 'ADD_GROUP_PRODUCT_CONFIGS_FAILURE';
export const ADD_GROUP_PRODUCT_CONFIGS_SUCCESS = 'ADD_GROUP_PRODUCT_CONFIGS_SUCCESS';
export const RESET_ADD_GROUP_PRODUCT_CONFIGS_SUCCESS = 'RESET_ADD_GROUP_PRODUCT_CONFIGS_SUCCESS';
export const RESET_ADD_GROUP_PRODUCT_CONFIGS_ERROR = 'RESET_ADD_GROUP_PRODUCT_CONFIGS_ERROR';

export const GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST = 'GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST';
export const GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE = 'GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE';
export const GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS = 'GET_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS';

export const UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST = 'UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST';
export const UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE = 'UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE';
export const UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS = 'UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS';
export const RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS = 'RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS';
export const RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR = 'RESET_UPDATE_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR';

export const ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST = 'ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST';
export const ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE = 'ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE';
export const ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS = 'ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS';
export const RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS = 'RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS';
export const RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR = 'RESET_ADD_EXTENDED_FAMILY_PRODUCT_CONFIGS_ERROR';

export const GET_ALL_BANKS_REQUEST = 'GET_ALL_BANKS_REQUEST';
export const GET_ALL_BANKS_SUCCESS = 'GET_ALL_BANKS_SUCCESS';
export const GET_ALL_BANKS_FAILURE = 'GET_ALL_BANKS_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';


export const CREATE_DEBIT_ORDERZ_REQUEST = 'create-debit-ordrerz-request';
export const CREATE_DEBIT_ORDERZ_SUCCESS = 'create-debit-ordrerz-success';
export const CREATE_DEBIT_ORDERZ_FAILURE = 'create-debit-ordrerz-failure';

export const RESET_CREATE_DEBIT_ORDERZ_SUCCESS = 'reset-create-debit-ordrerz-success';
export const RESET_CREATE_DEBIT_ORDERZ_ERROR = 'reset-create-debit-ordrerz-error';

export const HARD_DELETE_DEBIT_ORDERZ_REQUEST = 'hard-delete-debit-ordrerz-request';
export const HARD_DELETE_DEBIT_ORDERZ_SUCCESS = 'hard-delete-debit-ordrerz-success';
export const HARD_DELETE_DEBIT_ORDERZ_FAILURE = 'hard-delete-debit-ordrerz-failure';

export const RESET_HARD_DELETE_DEBIT_ORDERZ_SUCCESS = 'reset-hard-delete-debit-ordrerz-success';
export const RESET_HARD_DELETE_DEBIT_ORDERZ_ERROR = 'reset-hard-delete-debit-ordrerz-error';

export const SOFT_DELETE_DEBIT_ORDERZ_REQUEST = 'soft-delete-debit-ordrerz-request';
export const SOFT_DELETE_DEBIT_ORDERZ_SUCCESS = 'soft-delete-debit-ordrerz-success';
export const SOFT_DELETE_DEBIT_ORDERZ_FAILURE = 'soft-delete-debit-ordrerz-failure';

export const EDIT_DEBIT_ORDERZ_REQUEST = 'update-debit-ordrerz-request';
export const EDIT_DEBIT_ORDERZ_SUCCESS = 'update-debit-ordrerz-success';
export const EDIT_DEBIT_ORDERZ_FAILURE = 'update-debit-ordrerz-failure';
export const RESET_EDIT_DEBIT_ORDERZ_SUCCESS = 'reset-edit-debit-ordrerz-success';
export const RESET_EDIT_DEBIT_ORDERZ_ERROR = 'reset-edit-debit-ordrerz-error';

export const GET_DEBIT_ORDERZ_REQUEST = 'get-debit-ordrerz-request';
export const GET_DEBIT_ORDERZ_SUCCESS = 'get-debit-ordrerz-success';
export const GET_DEBIT_ORDERZ_FAILURE = 'get-debit-ordrerz-failure';


export const SET_DEBIT_ORDERZ_TO_EDIT = 'SET_DEBIT_ORDERZ_TO_EDIT,'
export const SET_DEBIT_ORDERZ_TO_DELETE = 'SET_DEBIT_ORDERZ_TO_DELETE,'
export const RESET_DEBIT_ORDERZ_TO_EDIT = 'RESET_DEBIT_ORDERZ_TO_EDIT,'
export const RESET_DEBIT_ORDERZ_TO_DELETE = 'RESET_DEBIT_ORDERZ_TO_DELETE,'

export const GET_ALL_POLICIES_REQUEST = 'get-all-policies-request';
export const GET_ALL_POLICIES_SUCCESS = 'get-all-policies-success';
export const GET_ALL_POLICIES_FAILURE = 'get-all-policies-failure';

export const GET_ALL_PAYMENTS_REQUEST = 'get-all-payments-request';
export const GET_ALL_PAYMENTS_SUCCESS = 'get-all-payments-success';
export const GET_ALL_PAYMENTS_FAILURE = 'get-all-payments-failure';

export const GET_ALL_PREMIUMS_REQUEST = 'get-all-premiums-request';
export const GET_ALL_PREMIUMS_SUCCESS = 'get-all-premiums-success';
export const GET_ALL_PREMIUMS_FAILURE = 'get-all-premiums-failure';

export const GET_ALL_PRODUCTS_REQUEST = 'get-all-products-request';
export const GET_ALL_PRODUCTS_SUCCESS = 'get-all-products-success';
export const GET_ALL_PRODUCTS_FAILURE = 'get-all-products-failure';

export const GET_ALL_CUSTOMER_CLAIMS_REQUEST = 'get-all-customer-claims-request';
export const GET_ALL_CUSTOMER_CLAIMS_SUCCESS = 'get-all-customer-claims-success';
export const GET_ALL_CUSTOMER_CLAIMS_FAILURE = 'get-all-customer-claims-failure';

export const GET_CURRENT_ROLES_REQUEST = 'GET_CURRENT_ROLES_REQUEST,'
export const GET_CURRENT_ROLES_SUCCESS = 'GET_CURRENT_ROLES_SUCCESS,'
export const GET_CURRENT_ROLES_FAIL = 'GET_CURRENT_ROLES_FAIL,'

export const GET_CURRENT_PARTNER_REQUEST = 'GET_CURRENT_PARTNER_REQUEST,'
export const GET_CURRENT_PARTNER_SUCCESS = 'GET_CURRENT_PARTNER_SUCCESS,'
export const GET_CURRENT_PARTNER_FAIL = 'GET_CURRENT_PARTNER_FAIL,'


export const CREATE_PREMIUM_CONFIG_REQUEST = 'CREATE_PREMIUM_CONFIG_REQUEST'
export const CREATE_PREMIUM_CONFIG_FAILURE = 'CREATE_PREMIUM_CONFIG_FAILURE'
export const CREATE_PREMIUM_CONFIG_SUCCESS = 'CREATE_PREMIUM_CONFIG_SUCCESS'

export const RESET_CREATE_PREMIUM_CONFIG_SUCCESS = 'RESET_CREATE_PREMIUM_CONFIG_SUCCESS'
export const RESET_CREATE_PREMIUM_CONFIG_ERROR = 'RESET_CREATE_PREMIUM_CONFIG_ERROR'

export const HARD_DELETE_PREMIUM_CONFIG_REQUEST = 'HARD_DELETE_PREMIUM_CONFIG_REQUEST'
export const HARD_DELETE_PREMIUM_CONFIG_FAILURE = 'HARD_DELETE_PREMIUM_CONFIG_FAILURE'
export const HARD_DELETE_PREMIUM_CONFIG_SUCCESS = 'HARD_DELETE_PREMIUM_CONFIG_SUCCESS'

export const RESET_HARD_DELETE_PREMIUM_CONFIG_SUCCESS = 'RESET_HARD_DELETE_PREMIUM_CONFIG_SUCCESS'
export const RESET_HARD_DELETE_PREMIUM_CONFIG_ERROR = 'RESET_HARD_DELETE_PREMIUM_CONFIG_ERROR'

export const EDIT_PREMIUM_CONFIG_REQUEST = 'EDIT_PREMIUM_CONFIG_REQUEST'
export const EDIT_PREMIUM_CONFIG_FAILURE = 'EDIT_PREMIUM_CONFIG_FAILURE'
export const EDIT_PREMIUM_CONFIG_SUCCESS = 'EDIT_PREMIUM_CONFIG_SUCCESS'

export const RESET_EDIT_PREMIUM_CONFIG_SUCCESS = 'RESET_EDIT_PREMIUM_CONFIG_SUCCESS'
export const RESET_EDIT_PREMIUM_CONFIG_ERROR = 'RESET_EDIT_PREMIUM_CONFIG_ERROR'

export const SET_PREMIUM_CONFIG_TO_EDIT = 'SET_PREMIUM_CONFIG_TO_EDIT'
export const SET_PREMIUM_CONFIG_TO_DELETE = 'SET_PREMIUM_CONFIG_TO_DELETE'

export const RESET_PREMIUM_CONFIG_TO_EDIT = 'RESET_PREMIUM_CONFIG_TO_EDIT'
export const RESET_PREMIUM_CONFIG_TO_DELETE = 'RESET_PREMIUM_CONFIG_TO_DELETE'

export const GET_PREMIUM_CONFIGS_REQUEST = 'GET_PREMIUM_CONFIGS_REQUEST';
export const GET_PREMIUM_CONFIGS_FAILURE = 'GET_PREMIUM_CONFIGS_FAILURE';
export const GET_PREMIUM_CONFIGS_SUCCESS = 'GET_PREMIUM_CONFIGS_SUCCESS';


export const GET_TOTAL_PAYABLE_PREMIUM_REQUEST = 'GET_TOTAL_PAYABLE_PREMIUM_REQUEST';
export const GET_TOTAL_PAYABLE_PREMIUM_FAILURE = 'GET_TOTAL_PAYABLE_PREMIUM_FAILURE';
export const GET_TOTAL_PAYABLE_PREMIUM_SUCCESS = 'GET_TOTAL_PAYABLE_PREMIUM_SUCCESS';


export const ADD_CUSTOMER_BENEFICIARY_REQUEST = 'add-customer-beneficiary-request';
export const ADD_CUSTOMER_BENEFICIARY_SUCCESS = 'add-customer-beneficiary-success';
export const ADD_CUSTOMER_BENEFICIARY_FAILURE = 'add-customer-beneficiary-failure';

export const RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_SUCCESS = 'RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_SUCCESS'
export const RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_ERROR = 'RESET_ADD_CUSTOMER_BENEFICIARY_DETAILS_ERROR'


export const UPDATE_CUSTOMER_BENEFICIARY_REQUEST = 'update-customer-beneficiary-request';
export const UPDATE_CUSTOMER_BENEFICIARY_SUCCESS = 'update-customer-beneficiary-success';
export const UPDATE_CUSTOMER_BENEFICIARY_FAILURE = 'update-customer-beneficiary-failure';

export const DELETE_CUSTOMER_BENEFICIARY_REQUEST = 'delete-customer-beneficiary-request';
export const DELETE_CUSTOMER_BENEFICIARY_SUCCESS = 'delete-customer-beneficiary-success';
export const DELETE_CUSTOMER_BENEFICIARY_FAILURE = 'delete-customer-beneficiary-failure';

export const GET_CUSTOMER_BENEFICIARY_REQUEST = 'get-customer-beneficiary-request';
export const GET_CUSTOMER_BENEFICIARY_SUCCESS = 'get-customer-beneficiary-success';
export const GET_CUSTOMER_BENEFICIARY_FAILURE = 'get-customer-beneficiary-failure';


export const RESET_CREATE_CUSTOMER_BENEFICIARY_SUCCESS = 'RESET_CREATE_CUSTOMER_BENEFICIARY_SUCCESS'
export const RESET_CREATE_CUSTOMER_BENEFICIARY_ERROR = 'RESET_CREATE_CUSTOMER_BENEFICIARY_ERROR'

export const RESET_DELETE_CUSTOMER_BENEFICIARY_SUCCESS = 'RESET_DELETE_CUSTOMER_BENEFICIARY_SUCCESS'
export const RESET_DELETE_CUSTOMER_BENEFICIARY_ERROR = 'RESET_DELETE_CUSTOMER_BENEFICIARY_ERROR'

export const RESET_EDIT_CUSTOMER_BENEFICIARY_SUCCESS = 'RESET_EDIT_CUSTOMER_BENEFICIARY_SUCCESS'
export const RESET_EDIT_CUSTOMER_BENEFICIARY_ERROR = 'RESET_EDIT_CUSTOMER_BENEFICIARY_ERROR'


export const SET_CUSTOMER_BENEFICIARY_TO_EDIT = 'SET_CUSTOMER_BENEFICIARY_TO_EDIT'
export const SET_CUSTOMER_BENEFICIARY_TO_DELETE = 'SET_CUSTOMER_BENEFICIARY_TO_DELETE'
export const RESET_CUSTOMER_BENEFICIARY_TO_EDIT = 'RESET_CUSTOMER_BENEFICIARY_TO_EDIT,'
export const RESET_CUSTOMER_BENEFICIARY_TO_DELETE = 'RESET_CUSTOMER_BENEFICIARY_TO_DELETE,'



export const ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST = 'add-customer-beneficiary-bank-details-request';
export const ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS = 'add-customer-beneficiary-bank-details-success';
export const ADD_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE = 'add-customer-beneficiary-bank-datils-failure';



export const UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST = 'update-customer-beneficiary-bank-details-request';
export const UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS = 'update-customer-beneficiary-bank-details-success';
export const UPDATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE = 'update-customer-beneficiary-bank-details-failure';

export const DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST = 'delete-customer-beneficiary-bank-details-request';
export const DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS = 'delete-customer-beneficiary-bank-details-success';
export const DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE = 'delete-customer-beneficiary-bank-details-failure';

export const GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_REQUEST = 'get-customer-beneficiary-bank-details-request';
export const GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS = 'get-customer-beneficiary-bank-details-success';
export const GET_CUSTOMER_BENEFICIARY_BANK_DETAILS_FAILURE = 'get-customer-beneficiary-bank-details-failure';


export const RESET_CREATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS = 'RESET_CREATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS'
export const RESET_CREATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR = 'RESET_CREATE_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR'


export const RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS = 'RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS'
export const RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR = 'RESET_DELETE_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR'

export const RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS = 'RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_SUCCESS'
export const RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR = 'RESET_EDIT_CUSTOMER_BENEFICIARY_BANK_DETAILS_ERROR'


export const SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT = 'SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT'
export const SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE = 'SET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE'
export const RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT = 'RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_EDIT,'
export const RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE = 'RESET_CUSTOMER_BENEFICIARY_BANK_DETAILS_TO_DELETE,'

export const SET_CUSTOMER_BENEFICIARY_DETAILS_TO_DELETE = 'SET_CUSTOMER_BENEFICIARY_DETAILS_TO_DELETE'
export const RESET_CUSTOMER_BENEFICIARY_DETAILS_TO_DELETE = 'RESET_CUSTOMER_BENEFICIARY_DETAILS_TO_DELETE,'


export const ADD_CUSTOMER_BANK_DETAILS_REQUEST = 'add-customer-bank-details-request';
export const ADD_CUSTOMER_BANK_DETAILS_SUCCESS = 'add-customer-bank-details-success';
export const ADD_CUSTOMER_BANK_DETAILS_FAILURE = 'add-customer-bank-datils-failure';
export const RESET_ADD_CUSTOMER_BANK_DETAILS_SUCCESS = 'RESET_ADD_CUSTOMER_BANK_DETAILS_SUCCESS'
export const RESET_ADD_CUSTOMER_BANK_DETAILS_ERROR = 'RESET_ADD_CUSTOMER_BANK_DETAILS_ERROR'



export const DELETE_CUSTOMER_BANK_DETAILS_REQUEST = 'delete-customer-bank-details-request';
export const DELETE_CUSTOMER_BANK_DETAILS_SUCCESS = 'delete-customer-bank-details-success';
export const DELETE_CUSTOMER_BANK_DETAILS_FAILURE = 'delete-customer-bank-details-failure';

export const GET_CUSTOMER_BANK_DETAILS_REQUEST = 'get-customer-bank-details-request';
export const GET_CUSTOMER_BANK_DETAILS_SUCCESS = 'get-customer-bank-details-success';
export const GET_CUSTOMER_BANK_DETAILS_FAILURE = 'get-customer-bank-details-failure';


export const RESET_CREATE_CUSTOMER_BANK_DETAILS_SUCCESS = 'RESET_CREATE_CUSTOMER_BANK_DETAILS_SUCCESS'
export const RESET_CREATE_CUSTOMER_BANK_DETAILS_ERROR = 'RESET_CREATE_CUSTOMER_BANK_DETAILS_ERROR'

export const RESET_DELETE_CUSTOMER_BANK_DETAILS_SUCCESS = 'RESET_DELETE_CUSTOMER_BANK_DETAILS_SUCCESS'
export const RESET_DELETE_CUSTOMER_BANK_DETAILS_ERROR = 'RESET_DELETE_CUSTOMER_BANK_DETAILS_ERROR'



export const SET_CUSTOMER_BANK_DETAILS_TO_DELETE = 'SET_CUSTOMER_BANK_DETAILS_TO_DELETE'
export const RESET_CUSTOMER_BANK_DETAILS_TO_DELETE = 'RESET_CUSTOMER_BANK_DETAILS_TO_DELETE,'



export const EDIT_CUSTOMER_BANK_DETAILS_REQUEST = 'edit-customer-bank-details-request';
export const EDIT_CUSTOMER_BANK_DETAILS_SUCCESS = 'edit-customer-bank-details-success';
export const EDIT_CUSTOMER_BANK_DETAILS_FAILURE = 'edit-customer-bank-details-failure';

export const RESET_EDIT_CUSTOMER_BANK_DETAILS_SUCCESS = 'RESET_EDIT_CUSTOMER_BANK_DETAILS_SUCCESS'
export const RESET_EDIT_CUSTOMER_BANK_DETAILS_ERROR = 'RESET_EDIT_CUSTOMER_BANK_DETAILS_ERROR'

export const SET_CUSTOMER_BANK_DETAILS_TO_EDIT = 'SET_CUSTOMER_BANK_DETAILS_TO_EDIT'
export const RESET_CUSTOMER_BANK_DETAILS_TO_EDIT = 'RESET_CUSTOMER_BANK_DETAILS_TO_EDIT'

export const SET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT = 'SET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT'
export const RESET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT = 'RESET_CUSTOMER_BENEFICIARY_DETAILS_TO_EDIT,'

export const RESET_ADD_BENEFICIARY_BANK_DETAILS_SUCCESS = 'RESET_ADD_BENEFICIARY_BANK_DETAILS_SUCCESS'
export const RESET_ADD_BENEFICIARY_BANK_DETAILS_ERROR = 'RESET_ADD_BENEFICIARY_BANK_DETAILS_ERROR'
export const CREATE_DEPENDANT_REQUEST = 'create-dependant-request';
export const CREATE_DEPENDANT_SUCCESS = 'create-dependant-success';
export const CREATE_DEPENDANT_FAILURE = 'create-dependant-failure';

export const RESET_CREATE_DEPENDANT_SUCCESS = 'reset-create-dependant-success';
export const RESET_CREATE_DEPENDANT_ERROR = 'reset-create-dependant-error';


export const CREATE_QUOTE_REQUEST = 'create-quote-request';
export const CREATE_QUOTE_FAILURE = 'create-quote-failure';
export const CREATE_QUOTE_SUCCESS = 'create-quote-success';
export const RESET_CREATE_QUOTE_SUCCESS = 'reset-create-quote-success';
export const RESET_CREATE_QUOTE_ERROR = 'reset-create-quote-error';

export const VIEW_ALL_CUSTOMER_QUOTES_REQUEST = "view-all-customer-quotes-request"
export const VIEW_CUSTOMER_QUOTES_REQUEST = 'view-customer-quotes-request';
export const VIEW_CUSTOMER_QUOTES_FAILURE = 'view-customer-quotes-failure';
export const VIEW_ALL_CUSTOMER_QUOTES_SUCCESS = 'view-customer-quotes-success';

export const BUY_POLICY_REQUEST = 'buy-entity-request';
export const BUY_POLICY_FAILURE = 'buy-entity-failure';
export const BUY_POLICY_SUCCESS = 'buy-entity-success';
export const RESET_BUY_POLICY_SUCCESS = 'reset-buy-entity-success';
export const RESET_BUY_POLICY_ERROR = 'reset-buy-entity-error';

export const CHANGE_QUOTE_REQUEST = 'change-quote-request';
export const CHANGE_QUOTE_FAILURE = 'change-quote-failure';
export const CHANGE_QUOTE_SUCCESS = 'change-quote-success';
export const RESET_CHANGE_QUOTE_SUCCESS = 'reset-change-quote-success';
export const RESET_CHANGE_QUOTE_ERROR = 'reset-change-quote-error';


export const GET_ALL_CUSTOMER_POLICIES_REQUEST = 'get-all-customer-policies-request';
export const GET_ALL_CUSTOMER_POLICIES_SUCCESS = 'get-all-customer-policies-success';
export const GET_ALL_CUSTOMER_POLICIES_FAILURE = 'get-all-customer-policies-failure';

export const GET_ALL_CUSTOMER_POLICIES_REQUEST_BY_GUID = 'get-all-customer-policies-request-by-guid';
export const GET_ALL_CUSTOMER_POLICIES_SUCCESS_BY_GUID = 'get-all-customer-policies-success-by-guid';
export const GET_ALL_CUSTOMER_POLICIES_FAILURE_BY_GUID = 'get-all-customer-policies-failure-by-guid';

export const GET_ALL_CUSTOMER_PREMIUMS_REQUEST = 'get-all-customer-premiums-request';
export const GET_ALL_CUSTOMER_PREMIUMS_SUCCESS = 'get-all-customer-premiums-success';
export const GET_ALL_CUSTOMER_PREMIUMS_FAILURE = 'get-all-customer-premiums-failure';


export const GET_STARTED_REQUEST = 'get-started-request';
export const GET_STARTED_SUCCESS = 'get-started-success';
export const GET_STARTED_FAILURE = 'get-started-failure';
export const RESET_GET_STARTED_SUCCESS = 'reset-get-started-success';
export const RESET_GET_STARTED_ERROR = 'reset-get-started-error';


export const CANCEL_POLICY_REQUEST = 'cancel-entity-request';
export const CANCEL_POLICY_SUCCESS = 'cancel-entity-success';
export const CANCEL_POLICY_FAILURE = 'cancel-entity-failure';
export const RESET_CANCEL_POLICY_SUCCESS = 'reset-cancel-entity-success';
export const RESET_CANCEL_POLICY_ERROR = 'reset-cancel-entity-error';


export const CANCEL_V2_POLICY_REQUEST = 'cancel-v2-entity-request';
export const CANCEL_V2_POLICY_SUCCESS = 'cancel-v2-entity-success';
export const CANCEL_V2_POLICY_FAILURE = 'cancel-v2-entity-failure';

export const RESET_CANCEL_V2_POLICY_SUCCESS = 'reset-cancel-v2-entity-success';
export const RESET_CANCEL_V2_POLICY_ERROR = 'reset-cancel-v2-entity-error';
export const SET_V2_POLICY_TO_CANCEL = 'set-v2-entity-to-cancel';
export const RESET_V2_POLICY_TO_CANCEL= 'reset-v2-entity-to-cancel';

export const GET_EVENT_QUOTE_REQUEST = 'get-event-quote-request';
export const GET_EVENT_QUOTE_FAILURE = 'get-event-quote-failure';
export const GET_EVENT_QUOTE_SUCCESS = 'get-event-quote-success';
export const RESET_GET_EVENT_QUOTE_SUCCESS= 'reset-get-event-quote-success';
export const RESET_GET_EVENT_QUOTE_ERROR= 'reset-get-event-quote-error';


export const DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST';
export const DOWNLOAD_DOCUMENT_FAILURE = 'DOWNLOAD_DOCUMENT_FAILURE';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const RESET_DOWNLOAD_DOCUMENT_SUCCESS = 'RESET_DOWNLOAD_DOCUMENT_SUCCESS';
export const RESET_DOWNLOAD_DOCUMENT_ERROR = 'RESET_DOWNLOAD_DOCUMENT_ERROR';
export const SET_ENTITY_TO_DOWNLOAD_FOR = 'SET_ENTITY_TO_DOWNLOAD_FOR';
export const RESET_ENTITY_TO_DOWNLOAD_FOR = 'RESET_ENTITY_TO_DOWNLOAD_FOR';


export const GET_TEMPLATE_DOCUMENTS_REQUEST = 'GET_TEMPLATE_DOCUMENTS_REQUEST';
export const GET_TEMPLATE_DOCUMENTS_SUCCESS = 'GET_TEMPLATE_DOCUMENTS_SUCCESS';
export const GET_TEMPLATE_DOCUMENTS_FAILURE = 'GET_TEMPLATE_DOCUMENTS_FAILURE';
export const RESET_GET_TEMPLATE_DOCUMENTS = 'RESET_GET_TEMPLATE_DOCUMENTS';
export const ADD_TEMPLATE_DOCUMENT_REQUEST = 'ADD_TEMPLATE_DOCUMENT_REQUEST';
export const ADD_TEMPLATE_DOCUMENT_SUCCESS = 'ADD_TEMPLATE_DOCUMENT_SUCCESS';
export const ADD_TEMPLATE_DOCUMENT_FAILURE = 'ADD_TEMPLATE_DOCUMENT_FAILURE';

export const EDIT_TEMPLATE_DOCUMENT_REQUEST = 'EDIT_TEMPLATE_DOCUMENT_REQUEST';
export const EDIT_TEMPLATE_DOCUMENT_SUCCESS = 'EDIT_TEMPLATE_DOCUMENT_SUCCESS';
export const EDIT_TEMPLATE_DOCUMENT_FAILURE = 'EDIT_TEMPLATE_DOCUMENT_FAILURE';
export const DELETE_TEMPLATE_DOCUMENT_REQUEST = 'DELETE_TEMPLATE_DOCUMENT_REQUEST';
export const DELETE_TEMPLATE_DOCUMENT_SUCCESS = 'DELETE_TEMPLATE_DOCUMENT_SUCCESS';
export const DELETE_TEMPLATE_DOCUMENT_FAILURE = 'DELETE_TEMPLATE_DOCUMENT_FAILURE';


export const INITIATE_CLAIM_REQUEST = 'initiate-claim-request';
export const INITIATE_CLAIM_SUCCESS = 'initiate-claim-success';
export const INITIATE_CLAIM_FAILURE = 'initiate-claim-failure';
export const RESET_INITIATE_CLAIM_SUCCESS = 'reset-initiate-claim-success';
export const RESET_INITIATE_CLAIM_ERROR = 'reset-initiate-claim-error';


export const GET_CUSTOMER_CLAIMS_DCP_REQUEST = 'get-customer-claims-dcp-request';
export const GET_CUSTOMER_CLAIMS_DCP_SUCCESS = 'get-customer-claims-dcp-success';
export const GET_CUSTOMER_CLAIMS_DCP_FAILURE = 'get-customer-claims-dcp-failure';

export const GET_CLAIMS_DCP_REQUEST = 'get-claims-dcp-request';
export const GET_CLAIMS_DCP_SUCCESS = 'get-claims-dcp-success';
export const GET_CLAIMS_DCP_FAILURE = 'get-claims-dcp-failure';


export const GET_CLAIMS_DCP_FILTERED_REQUEST = 'get-claims-dcp-filtered-request';
export const GET_CLAIMS_DCP_FILTERED_SUCCESS = 'get-claims-dcp-filtered-success';
export const GET_CLAIMS_DCP_FILTERED_FAILURE = 'get-claims-dcp-filtered-failure';



export const CREATE_EXPENSE_REQUEST = 'create-expense-request';
export const CREATE_EXPENSE_SUCCESS = 'create-expense-success';
export const CREATE_EXPENSE_FAILURE = 'create-expense-failure';
export const RESET_CREATE_EXPENSE_SUCCESS = 'reset-create-expense-success';
export const RESET_CREATE_EXPENSE_ERROR = 'reset-create-expense-error';

export const DELETE_EXPENSE_REQUEST = 'delete-expense-request';
export const SET_EXPENSE_TO_DELETE = 'set-expense-to-delete';
export const DELETE_EXPENSE_SUCCESS = 'delete-expense-success';
export const RESET_DELETE_EXPENSE_SUCCESS = 'reset-delete-expense-success';
export const DELETE_EXPENSE_FAILED = 'delete-expense-failed';
export const RESET_EXPENSE_TO_DELETE = 'reset-expense-to-delete';
export const RESET_DELETE_EXPENSE_ERROR = 'reset-delete-expense-error';


export const SET_EXPENSE_TO_EDIT = 'set-expense-to-edit';
export const EXPENSE_EDIT_REQUEST = 'expense-edit-request';
export const EXPENSE_EDIT_SUCCESS = 'expense-edit-success';
export const EXPENSE_EDIT_FAILED = 'expense-edit-failed';
export const RESET_EXPENSE_EDIT_SUCCESS = 'reset-expense-edit-success';
export const RESET_EXPENSE_EDIT_ERROR = 'reset-expense-edit-error';
export const RESET_EXPENSE_EDIT = 'reset-expense-edit';


export const ADD_CLAIM_DOCUMENTS_REQUEST = 'add_claim_documents_request';
export const ADD_CLAIM_DOCUMENTS_SUCCESS = 'add_claim_documents_success';
export const ADD_CLAIM_DOCUMENTS_FAILURE = 'add_claim_documents_failure';


export const DELETE_CLAIM_DOCUMENTS_REQUEST = 'delete-claim-documents-request';
export const DELETE_CLAIM_DOCUMENTS_SUCCESS = 'delete-claim-documents-success';
export const DELETE_CLAIM_DOCUMENTS_FAILURE = 'delete-claim-documents-failure';
export const SET_DOCUMENT_TO_DELETE = 'set-document-to-delete';
export const RESET_DELETE_DOCUMENT_SUCCESS = 'reset-delete-document-success';
export const RESET_DOCUMENT_TO_DELETE = 'reset-document-to-delete';
export const RESET_DELETE_DOCUMENT_ERROR = 'reset-delete-document-error';



export const CLAIM_ACTION_REQUEST = 'claim-action-request';
export const CLAIM_ACTION_SUCCESS = 'claim-action-success';
export const CLAIM_ACTION_FAILURE = 'claim-action-failure';
export const RESET_CLAIM_ACTION_SUCCESS = 'reset-claim-action-success';
export const RESET_CLAIM_ACTION_ERROR = 'reset-claim-action-error';


export const CLAIM_NOTES_REQUEST = 'claim-notes-request';
export const CLAIM_NOTES_SUCCESS = 'claim-notes-success';
export const CLAIM_NOTES_FAILURE = 'claim-notes-failure';
export const RESET_CLAIM_NOTES_SUCCESS = 'reset-claim-notes-success';
export const RESET_CLAIM_NOTES_ERROR = 'reset-claim-notes-error';

export const SET_QUOTATION_TO_RECREATE = 'SET_QUOTATION_TO_RECREATE';

export const GET_CLAIM_PROCESSING_DCP_REQUEST = 'get-claim-processing-dcp-request';
export const GET_CLAIM_PROCESSING_DCP_SUCCESS = 'get-claim-processing-dcp-sucess';
export const GET_CLAIM_PROCESSING_DCP_FAILURE = 'get-claim-processing-dcp-failure';

export const SAVE_CLAIM_DCP_DOCUMENT_REQUEST = 'SAVE_CLAIM_DCP_DOCUMENT_REQUEST';
export const SAVE_CLAIM_DCP_DOCUMENT_SUCCESS = 'SAVE_CLAIM_DCP_DOCUMENT_SUCCESS';
export const SAVE_CLAIM_DCP_DOCUMENT_FAILURE = 'SAVE_CLAIM_DCP_DOCUMENT_FAILURE';


export const CLAIM_FACILTY_NOTES_REQUEST = 'claim-facility-notes-request';
export const CLAIM_FACILITY_NOTES_SUCCESS = 'claim-facility-notes-success';
export const CLAIM_FACILITY_NOTES_FAILURE = 'claim-facility-notes-failure';
export const RESET_FACILITY_CLAIM_NOTES_SUCCESS = 'reset-facility-claim-notes-success';
export const RESET_FACILITY_CLAIM_NOTES_ERROR = 'reset-facility-claim-notes-error';


export const POLICY_NOTES_REQUEST = 'policy-notes-request';
export const POLICY_NOTES_SUCCESS = 'policy-notes-success';
export const POLICY_NOTES_FAILURE = 'policy-notes-failure';
export const RESET_POLICY_NOTES_SUCCESS = 'reset-policy-notes-success';
export const RESET_POLICY_NOTES_ERROR = 'reset-policy-notes-error';
export const SET_POLICY_NOTE_TO_VIEW = 'set-policy-note-to-view';
export const RESET_POLICY_NOTE_TO_VIEW = 'reset-policy-note-to-view';



export const POLICY_NOTES_ADD_REQUEST = 'policy-notes-add-request';
export const POLICY_NOTES_ADD_SUCCESS = 'policy-notes-add-success';
export const POLICY_NOTES_ADD_FAILURE = 'policy-notes-add-failure';
export const RESET_POLICY_NOTES_ADD_SUCCESS = 'reset-policy-notes-add-success';
export const RESET_POLICY_NOTES_ADD_ERROR = 'reset-policy-notes-add-error';

export const SET_POLICY_TO_ADD = 'set-policy-note-to-add';
export const RESET_POLICY_NOTE_TO_ADD = 'reset-policy-note-to-add';


export const CREATE_LOU_REQUEST = 'create-lou-request'
export const CREATE_LOU_SUCCESS = 'create-lou-success'
export const CREATE_LOU_FAILURE = 'create-lou-failure'

export const RESET_CREATE_LOU_SUCCESS = 'RESET_CREATE_LOU_SUCCESS'
export const RESET_CREATE_LOU_FAILURE = 'RESET_CREATE_LOU_FAILUR'

export const EDIT_LOU_REQUEST = 'edit-lou-request'
export const EDIT_LOU_SUCCESS = 'edit-lou-success'
export const EDIT_LOU_FAILURE = 'edit-lou-failure'
export const RESET_EDIT_LOU = 'reset-edit-lou'
export const RESET_EDIT_LOU_SUCCESS = 'reset-edit-lou-success'
export const RESET_EDIT_LOU_ERROR = 'reset-edit-lou-error'
export const SET_LOU_TO_EDIT = 'set-lou-to-edit'

export const GET_LOUS_REQUEST = 'get-lous-request'
export const GET_LOUS_SUCCESS = 'get-lous-success'
export const GET_LOUS_FAILURE = 'get-lous-failure'


export const CREATE_HOSPITAL_REQUEST = 'create-hospital-request'
export const CREATE_HOSPITAL_SUCCESS = 'create-hospital-success'
export const CREATE_HOSPITAL_FAILURE = 'create-hospital-failure'
export const RESET_CREATE_HOSPITAL_SUCCESS = 'reset-create-hospital-success'
export const RESET_CREATE_HOSPITAL_ERROR = 'reset-create-hospital-failure'


export const DELETE_HOSPITAL_REQUEST = 'delete-hospital-request'
export const DELETE_HOSPITAL_SUCCESS = 'delete-hospital-success'
export const DELETE_HOSPITAL_FAILURE = 'delete-hospital-failure'
export const RESET_DELETE_HOSPITAL_SUCCESS = 'reset-delete-hospital-success'
export const RESET_DELETE_HOSPITAL_ERROR = 'reset-delete-hospital-error'
export const SET_HOSPITAL_TO_DELETE = 'set-hospital-to-delete'
export const RESET_HOSPITAL_TO_DELETE = 'reset-hospital-to-delete'

export const GET_HOSPITALS_REQUEST = 'get-hospitals-request'
export const GET_HOSPITALS_SUCCESS = 'get-hospitals-success'
export const GET_HOSPITALS_FAILURE = 'get-hospitals-failure'

export const SEARCH_HOSPITALS_REQUEST = 'search-hospitals-request'
export const SEARCH_HOSPITALS_SUCCESS = 'search-hospitals-success'
export const SEARCH_HOSPITALS_FAILURE = 'search-hospitals-failure'

export const CREATE_DIAGNOSIS_REQUEST = 'create-diagnosis-request'
export const CREATE_DIAGNOSIS_SUCCESS = 'create-diagnosis-success'
export const CREATE_DIAGNOSIS_FAILURE = 'create-diagnosis-failure'
export const RESET_CREATE_DIAGNOSIS_SUCCESS = 'reset-create-diagnosis-success'
export const RESET_CREATE_DIAGNOSIS_ERROR = 'reset-create-diagnosis-failure'

export const DELETE_DIAGNOSIS_REQUEST = 'delete-diagnosis-request'
export const DELETE_DIAGNOSIS_SUCCESS = 'delete-diagnosis-success'
export const DELETE_DIAGNOSIS_FAILURE = 'delete-diagnosis-failure'
export const RESET_DELETE_DIAGNOSIS_SUCCESS = 'reset-delete-diagnosis-success'
export const RESET_DELETE_DIAGNOSIS_ERROR = 'reset-delete-diagnosis-error'
export const SET_DIAGNOSIS_TO_DELETE = 'set-diagnosis-to-delete'
export const RESET_DIAGNOSIS_TO_DELETE = 'reset-diagnosis-to-delete'

export const GET_DIAGNOSIS_REQUEST = 'get-diagnosis-request'
export const GET_DIAGNOSIS_SUCCESS = 'get-diagnosis-success'
export const GET_DIAGNOSIS_FAILURE = 'get-diagnosis-failure'

export const SEARCH_DIAGNOSIS_REQUEST = 'search-diagnosis-request'
export const SEARCH_DIAGNOSIS_SUCCESS = 'search-diagnosis-success'
export const SEARCH_DIAGNOSIS_FAILURE = 'search-diagnosis-failure'

export const SET_LOU_TO_VIEW = 'set-lou-to-view';
export const RESET_LOU_TO_VIEW = 'reset-lou-to-view';

export const BULK_APPROVE_REQUEST = 'bulk-approve-request'
export const BULK_APPROVE_SUCCESS = 'bulk-approve-success'
export const BULK_APPROVE_FAILURE = 'bulk-approve-failure'
export const RESET_BULK_APPROVE_SUCCESS = 'reset-bulk-approve-success'
export const RESET_BULK_APPROVE_FAILURE = 'reset-bulk-approve-failure'


export const SAVE_BULK_POLICY_ONBOARDING_REQUEST = 'save-bulk-policy-onboarding-request';
export const SAVE_BULK_POLICY_ONBOARDING_SUCCESS = 'save-bulk-policy-onboarding-success';
export const SAVE_BULK_POLICY_ONBOARDING_FAILURE = 'save-bulk-policy-onboarding-failure';

export const ADD_BULK_POLICY_ONBOARDING_REQUEST = 'add-bulk-policy-onboarding-request';
export const ADD_BULK_POLICY_ONBOARDING_SUCCESS = 'add-bulk-policy-onboarding-success';
export const ADD_BULK_POLICY_ONBOARDING_FAILURE = 'add-bulk-policy-onboarding-failure';

export const GET_BULK_POLICY_ONBOARDING_BATCHES_REQUEST = 'get-bulk-policy-onboarding-batches-request';
export const GET_BULK_POLICY_ONBOARDING_BATCHES_SUCCESS= 'get-bulk-policy-onboarding-batches-success';
export const GET_BULK_POLICY_ONBOARDING_BATCHES_FAILURE= 'get-bulk-policy-onboarding-batches-failure';

export const GET_FAILED_BATCHES_REQUEST = 'get-failed-batches-request';
export const GET_FAILED_BATCHES_SUCCESS= 'get-failed-batches-success';
export const GET_BATCHES_FAILURE= 'get-batches-failure';
export const GET_PREAUTH_DETAILS_REQUEST = 'get-preauth-details-request'
export const GET_PREAUTH_DETAILS_SUCCESS = 'get-preauth-details-success'
export const GET_PREAUTH_DETAILS_FAILURE = 'get-preauth-details-failure'


export const DELETE_LOU_REQUEST = 'delete-lou-request';
export const DELETE_LOU_SUCCESS = 'delete-lou-success';
export const DELETE_LOU_FAILURE = 'delete-lou-failure';

export const RESET_DELETE_LOU_SUCCESS = 'reset-delete-lou-success';
export const RESET_DELETE_LOU_ERROR = 'reset-delete-lou-error';

export const SET_LOU_TO_DELETE = 'SET_LOU_TO_DELETE';
export const RESET_LOU_TO_DELETE = 'RESET_LOU_TO_DELETE';


export const GET_SMS_ACTIVITY_REQUEST = 'get-sms-activity-request';
export const GET_SMS_ACTIVITY_SUCCESS = 'get-sms-activity-success';
export const GET_SMS_ACTIVITY_FAILURE = 'get-sms-activity-failure';

export const CREATE_ADDON_CONFIG_REQUEST = 'CREATE_ADDON_CONFIG_REQUEST'
export const CREATE_ADDON_CONFIG_FAILURE = 'CREATE_ADDON_CONFIG_FAILURE'
export const CREATE_ADDON_CONFIG_SUCCESS = 'CREATE_ADDON_CONFIG_SUCCESS'

export const RESET_CREATE_ADDON_CONFIG_SUCCESS = 'RESET_CREATE_ADDON_CONFIG_SUCCESS'
export const RESET_CREATE_ADDON_CONFIG_ERROR = 'RESET_CREATE_ADDON_CONFIG_ERROR'

export const SAVE_BULK_PAYMENTS_REQUEST = 'save-bulk-payments-request';
export const SAVE_BULK_PAYMENTS_SUCCESS = 'save-bulk-payments-success';
export const SAVE_BULK_PAYMENTS_FAILURE = 'save-bulk-payments-failure';

export const ADD_BULK_PAYMENTS_REQUEST = 'add-bulk-payments-request';
export const ADD_BULK_PAYMENTS_SUCCESS = 'add-bulk-payments-success';
export const ADD_BULK_PAYMENTS_FAILURE = 'add-bulk-payments-failure';

export const GET_BULK_PAYMENTS_BATCHES_REQUEST = 'get-bulk-payments-batches-request';
export const GET_BULK_PAYMENTS_BATCHES_SUCCESS= 'get-bulk-payments-batches-success';
export const GET_BULK_PAYMENTS_BATCHES_FAILURE= 'get-bulk-payments-batches-failure';

export const GET_FAILED_PAYMENTS_BATCHES_REQUEST = 'get-failed-payments-batches-request';
export const GET_FAILED_PAYMENTS_BATCHES_SUCCESS= 'get-failed-payments-batches-success';
export const GET_BATCHES_PAYMENTS_FAILURE= 'get-payments-batches-failure';

export const SET_SELECTED_DRAWER_ITEM = 'SET_SELECTED_DRAWER_ITEM'
export const SET_SELECTED_DRAWER_ID = 'SET_SELECTED_DRAWER_ID'
export const SET_DRAWER_OPEN = 'SET_DRAWER_OPEN'

export const GET_ALL_ORANGE_POLICIES_REQUEST = 'GET_ALL_ORANGE_POLICIES_REQUEST'
export const GET_ALL_ORANGE_POLICIES_SUCCESS = 'GET_ALL_ORANGE_POLICIES_SUCCESS'
export const GET_ALL_ORANGE_POLICIES_FAILURE = 'GET_ALL_ORANGE_POLICIES_FAILURE'

export const PURCHASE_ORANGE_POLICY_REQUEST = 'PURCHASE_ORANGE_POLICY_REQUEST'
export const PURCHASE_ORANGE_POLICY_SUCCESS = 'PURCHASE_ORANGE_POLICY_SUCCESS'
export const PURCHASE_ORANGE_POLICY_FAILURE = 'PURCHASE_ORANGE_POLICY_FAILURE'

export const PAID_ORANGE_PRODUCT_LIST_REQUEST = 'PAID_ORANGE_PRODUCT_LIST_REQUEST'
export const PAID_ORANGE_PRODUCT_LIST_SUCCESS = 'PAID_ORANGE_PRODUCT_LIST_SUCCESS'
export const PAID_ORANGE_PRODUCT_LIST_FAIL = 'PAID_ORANGE_PRODUCT_LIST_FAIL'
export const PURCHASE_ORANGE_POLICY_RESET = 'PURCHASE_ORANGE_POLICY_RESET'


export const DELETE_ORANGE_POLICY_REQUEST = 'DELETE_ORANGE_POLICY_REQUEST'
export const DELETE_ORANGE_POLICY_SUCCESS = 'DELETE_ORANGE_POLICY_SUCCESS'
export const DELETE_ORANGE_POLICY_FAIL = 'DELETE_ORANGE_POLICY_FAIL'

export const SET_ORANGE_POLICY_TO_CANCEL = 'SET_ORANGE_POLICY_TO_CANCEL'
export const RESET_ORANGE_POLICY_TO_CANCEL = 'RESET_ORANGE_POLICY_TO_CANCEL'

export const SET_ORANGE_POLICY_TO_EDIT = 'SET_ORANGE_POLICY_TO_EDIT'
export const RESET_ORANGE_POLICY_TO_EDIT = 'RESET_ORANGE_POLICY_TO_EDIT'

export const UPDATE_ORANGE_POLICY_REQUEST = 'UPDATE_ORANGE_POLICY_REQUEST'
export const UPDATE_ORANGE_POLICY_SUCCESS = 'UPDATE_ORANGE_POLICY_SUCCESS'
export const UPDATE_ORANGE_POLICY_FAILURE = 'UPDATE_ORANGE_POLICY_FAILURE'
export const EDIT_ORANGE_POLICY_RESET = 'EDIT_ORANGE_POLICY_RESET'


export const GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST = 'GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_REQUEST'
export const GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS = 'GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_SUCCESS'
export const GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE = 'GET_ORANGE_EXTENDED_FAMILY_PRODUCT_CONFIGS_FAILURE'

export const GET_TOTAL_PAYABLE_ORANGE_PREMIUM_REQUEST = 'GET_TOTAL_PAYABLE_ORANGE_PREMIUM_REQUEST'
export const GET_TOTAL_PAYABLE_ORANGE_PREMIUM_SUCCESS = 'GET_TOTAL_PAYABLE_ORANGE_PREMIUM_SUCCESS'
export const GET_TOTAL_PAYABLE_ORANGE_PREMIUM_FAILURE = 'GET_TOTAL_PAYABLE_ORANGE_PREMIUM_FAILURE'

export const GET_ALL_ORANGE_PAYMENTS_REQUEST = 'GET_ALL_ORANGE_PAYMENTS_REQUEST'
export const GET_ALL_ORANGE_PAYMENTS_SUCCESS = 'GET_ALL_ORANGE_PAYMENTS_SUCCESS'
export const GET_ALL_ORANGE_PAYMENTS_FAILURE = 'GET_ALL_ORANGE_PAYMENTS_FAILURE'

export const GET_ALL_ORANGE_PRODUCTS_REQUEST = 'GET_ALL_ORANGE_PRODUCTS_REQUEST'
export const GET_ALL_ORANGE_PRODUCTS_SUCCESS = 'GET_ALL_ORANGE_PRODUCTS_SUCCESS'
export const GET_ALL_ORANGE_PRODUCTS_FAILURE = 'GET_ALL_ORANGE_PRODUCTS_FAILURE'


export const ADD_ORANGE_MISSING_PAYMENT_REQUEST = 'ADD_ORANGE_MISSING_PAYMENT_REQUEST'
export const ADD_ORANGE_MISSING_PAYMENT_SUCCESS = 'ADD_ORANGE_MISSING_PAYMENT_SUCCESS'
export const ADD_ORANGE_MISSING_PAYMENT_FAIL = 'ADD_ORANGE_MISSING_PAYMENT_FAIL'
export const ADD_ORANGE_MISSING_PAYMENT_RESET = 'ADD_ORANGE_MISSING_PAYMENT_RESET'

export const GET_ALL_ORANGE_PREMIUMS_REQUEST = 'GET_ALL_ORANGE_PREMIUMS_REQUEST'
export const GET_ALL_ORANGE_PREMIUMS_SUCCESS = 'GET_ALL_ORANGE_PREMIUMS_SUCCESS'
export const GET_ALL_ORANGE_PREMIUMS_FAILURE = 'GET_ALL_ORANGE_PREMIUMS_FAILURE'

export const GET_ALL_ORANGE_CUSTOMER_CLAIMS_REQUEST = 'GET_ALL_ORANGE_CUSTOMER_CLAIMS_REQUEST'
export const GET_ALL_ORANGE_CUSTOMER_CLAIMS_SUCCESS = 'GET_ALL_ORANGE_CUSTOMER_CLAIMS_SUCCESS'
export const GET_ALL_ORANGE_CUSTOMER_CLAIMS_FAILURE = 'GET_ALL_ORANGE_CUSTOMER_CLAIMS_FAILURE'


export const GET_ORANGE_SMS_ACTIVITY_REQUEST = 'GET_ORANGE_SMS_ACTIVITY_REQUEST'
export const GET_ORANGE_SMS_ACTIVITY_SUCCESS = 'GET_ORANGE_SMS_ACTIVITY_SUCCESS'
export const GET_ORANGE_SMS_ACTIVITY_FAILURE = 'GET_ORANGE_SMS_ACTIVITY_FAILURE'

export const GET_ORANGE_CUSTOMER_DOCUMENTS_REQUEST = 'GET_ORANGE_CUSTOMER_DOCUMENTS_REQUEST'
export const GET_ORANGE_CUSTOMER_DOCUMENTS_SUCCESS = 'GET_ORANGE_CUSTOMER_DOCUMENTS_SUCCESS'
export const GET_ORANGE_CUSTOMER_DOCUMENTS_FAILURE = 'GET_ORANGE_CUSTOMER_DOCUMENTS_FAILURE'

export const ADD_ORANGE_CUSTOMER_DOCUMENT_REQUEST = 'ADD_ORANGE_CUSTOMER_DOCUMENT_REQUEST'
export const ADD_ORANGE_CUSTOMER_DOCUMENT_SUCCESS = 'ADD_ORANGE_CUSTOMER_DOCUMENT_SUCCESS'
export const ADD_ORANGE_CUSTOMER_DOCUMENT_FAILURE = 'ADD_ORANGE_CUSTOMER_DOCUMENT_FAILURE'

export const DELETE_ORANGE_CUSTOMER_DOCUMENT_REQUEST = 'DELETE_ORANGE_CUSTOMER_DOCUMENT_REQUEST'
export const DELETE_ORANGE_CUSTOMER_DOCUMENT_SUCCESS = 'DELETE_ORANGE_CUSTOMER_DOCUMENT_SUCCESS'
export const DELETE_ORANGE_CUSTOMER_DOCUMENT_FAILURE = 'DELETE_ORANGE_CUSTOMER_DOCUMENT_FAILURE'

export const SET_ORANGE_CUSTOMER_DOCUMENT_TO_DELETE = 'SET_ORANGE_CUSTOMER_DOCUMENT_TO_DELETE'
export const RESET_DELETE_ORANGE_CUSTOMER_DOCUMENT_SUCCESS = 'RESET_DELETE_ORANGE_CUSTOMER_DOCUMENT_SUCCESS'
export const RESET_ORANGE_CUSTOMER_DOCUMENT_TO_DELETE = 'RESET_ORANGE_CUSTOMER_DOCUMENT_TO_DELETE'
export const RESET_ORANGE_CUSTOMER_DELETE_DOCUMENT_ERROR = 'RESET_ORANGE_CUSTOMER_DELETE_DOCUMENT_ERROR'

export const GET_FEATURE_FLAGS_REQUEST = 'GET_FEATURE_FLAGS_REQUEST';
export const GET_FEATURE_FLAGS_SUCCESS = 'GET_FEATURE_FLAGS_SUCCESS';
export const GET_FEATURE_FLAGS_FAILURE = 'GET_FEATURE_FLAGS_FAILURE';


export const REGISTER_ACCESS_BANK_CUSTOMER_REQUEST = 'REGISTER_ACCESS_BANK_CUSTOMER_REQUEST';
export const REGISTER_ACCESS_BANK_CUSTOMER_SUCCESS = 'REGISTER_ACCESS_BANK_CUSTOMER_SUCCESS';
export const REGISTER_ACCESS_BANK_CUSTOMER_FAIL = 'REGISTER_ACCESS_BANK_CUSTOMER_FAIL';
export const SET_ACCESS_BANK_REGISTRATION_ERROR = 'SET_ACCESS_BANK_REGISTRATION_ERROR';
export const RESET_ACCESS_BANK_REGISTRATION = 'RESET_ACCESS_BANK_REGISTRATION';


export const UPDATE_ACCESS_BANK_CUSTOMER_REQUEST = 'UPDATE_ACCESS_BANK_CUSTOMER_REQUEST';
export const UPDATE_ACCESS_BANK_CUSTOMER_SUCCESS = 'UPDATE_ACCESS_BANK_CUSTOMER_SUCCESS';
export const UPDATE_ACCESS_BANK_CUSTOMER_FAIL = 'UPDATE_ACCESS_BANK_CUSTOMER_FAIL';
export const UPDATE_CUSTOMER_ACCESS_BANK_RESET = 'UPDATE_CUSTOMER_ACCESS_BANK_RESET';
export const EDIT_ACCESS_BANK_CUSTOMER_DISPLAY_ERROR = 'EDIT_ACCESS_BANK_CUSTOMER_DISPLAY_ERROR';


export const UPLOAD_POLICY_DCP_DOCUMENT_REQUEST = 'UPLOAD_POLICY_DCP_DOCUMENT_REQUEST';
export const UPLOAD_POLICY_DCP_DOCUMENT_SUCCESS = 'UPLOAD_POLICY_DCP_DOCUMENT_SUCCESS';
export const UPLOAD_POLICY_DCP_DOCUMENT_FAILURE = 'UPLOAD_POLICY_DCP_DOCUMENT_FAILURE';


export const CREATE_DEBIT_ORDERS_DCP_REQUEST = 'CREATE_DEBIT_ORDERS_DCP_REQUEST';
export const CREATE_DEBIT_ORDERS_DCP_SUCCESS = 'CREATE_DEBIT_ORDERS_DCP_SUCCESS';
export const CREATE_DEBIT_ORDERS_DCP_FAILURE = 'CREATE_DEBIT_ORDERS_DCP_FAILURE';
export const RESET_CREATE_DEBIT_ORDERS_DCP = 'RESET_CREATE_DEBIT_ORDERS_DCP';

export const GET_POLICIES_DCP_REQUEST = 'GET_POLICIES_DCP_REQUEST';
export const GET_POLICIES_DCP_SUCCESS = 'GET_POLICIES_DCP_SUCCESS';
export const GET_POLICIES_DCP_FAILURE = 'GET_POLICIES_DCP_FAILURE';


export const GET_POLICY_PROCESSING_DCP_REQUEST = 'GET_POLICY_PROCESSING_DCP_REQUEST';
export const GET_POLICY_PROCESSING_DCP_SUCCESS = 'GET_POLICY_PROCESSING_DCP_SUCCESS';
export const GET_POLICY_PROCESSING_DCP_FAILURE = 'GET_POLICY_PROCESSING_DCP_FAILURE';


export const POLICY_ACTION_REQUEST = 'POLICY_ACTION_REQUEST';
export const POLICY_ACTION_SUCCESS = 'POLICY_ACTION_SUCCESS';
export const POLICY_ACTION_FAILURE = 'POLICY_ACTION_FAILURE';

export const RESET_POLICY_ACTION_SUCCESS = 'RESET_POLICY_ACTION_SUCCESS';
export const RESET_POLICY_ACTION_ERROR = 'RESET_POLICY_ACTION_ERROR';

export const SET_POLICY_TO_APPROVE = 'SET_POLICY_TO_APPROVE';
export const RESET_POLICY_TO_APPROVE = 'RESET_POLICY_TO_APPROVE';



export const POLICY_REJECT_ACTION_REQUEST = 'POLICY_REJECT_ACTION_REQUEST';
export const POLICY_REJECT_ACTION_SUCCESS = 'POLICY_REJECT_ACTION_SUCCESS';
export const POLICY_REJECT_ACTION_FAILURE = 'POLICY_REJECT_ACTION_FAILURE';


export const ADD_POLICY_NOTES_REQUEST = 'ADD_POLICY_NOTES_REQUEST';
export const ADD_POLICY_NOTES_SUCCESS = 'ADD_POLICY_NOTES_SUCCESS';
export const ADD_POLICY_NOTES_FAILURE = 'ADD_POLICY_NOTES_FAILURE';
export const RESET_ADD_POLICY_NOTES_SUCCESS = 'RESET_ADD_POLICY_NOTES_SUCCESS';
export const RESET_ADD_POLICY_NOTES_ERROR = 'RESET_ADD_POLICY_NOTES_ERROR';


export const GET_PAYMENTS_DCP_REQUEST = 'GET_PAYMENTS_DCP_REQUEST';
export const GET_PAYMENTS_DCP_SUCCESS = 'GET_PAYMENTS_DCP_SUCCESS';
export const GET_PAYMENTS_DCP_FAILURE = 'GET_PAYMENTS_DCP_FAILURE';

export const GET_PAYMENT_PROCESSING_DCP_REQUEST = 'GET_PAYMENT_PROCESSING_DCP_REQUEST';
export const GET_PAYMENT_PROCESSING_DCP_SUCCESS = 'GET_PAYMENT_PROCESSING_DCP_SUCCESS';
export const GET_PAYMENT_PROCESSING_DCP_FAILURE = 'GET_PAYMENT_PROCESSING_DCP_FAILURE';

export const PAYMENT_APPROVE_ACTION_REQUEST = 'PAYMENT_APPROVE_ACTION_REQUEST';
export const PAYMENT_APPROVE_ACTION_SUCCESS = 'PAYMENT_APPROVE_ACTION_SUCCESS';
export const PAYMENT_APPROVE_ACTION_FAILURE = 'PAYMENT_APPROVE_ACTION_FAILURE';
export const RESET_PAYMENT_APPROVE_ACTION_SUCCESS = 'RESET_PAYMENT_APPROVE_ACTION_SUCCESS';
export const RESET_PAYMENT_APPROVE_ACTION_ERROR = 'RESET_PAYMENT_APPROVE_ACTION_ERROR';


export const PAYMENT_REJECT_ACTION_REQUEST = 'PAYMENT_REJECT_ACTION_REQUEST';
export const PAYMENT_REJECT_ACTION_SUCCESS = 'PAYMENT_REJECT_ACTION_SUCCESS';
export const PAYMENT_REJECT_ACTION_FAILURE = 'PAYMENT_REJECT_ACTION_FAILURE';

export const CANCEL_POLICY_ACTION_REQUEST = 'CANCEL_POLICY_ACTION_REQUEST';
export const CANCEL_POLICY_ACTION_SUCCESS = 'CANCEL_POLICY_ACTION_SUCCESS';
export const CANCEL_POLICY_ACTION_FAILURE = 'CANCEL_POLICY_ACTION_FAILURE';


export const GET_ACCESSBANK_CUSTOMER_QUOTES_REQUEST = 'GET_ACCESSBANK_CUSTOMER_QUOTES_REQUEST';
export const GET_ACCESSBANK_CUSTOMER_QUOTES_SUCCESS = 'GET_ACCESSBANK_CUSTOMER_QUOTES_SUCCESS';
export const GET_ACCESSBANK_CUSTOMER_QUOTES_FAILURE = 'GET_ACCESSBANK_CUSTOMER_QUOTES_FAILURE';

export const GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_REQUEST = 'GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_REQUEST';
export const GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_SUCCESS = 'GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_SUCCESS';
export const GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_FAILURE = 'GET_ALL_ACCESSBANK_CUSTOMER_PREMIUMS_FAILURE';

export const GET_ACCESSBANK_CUSTOMER_CLAIMS_REQUEST = 'GET_ACCESSBANK_CUSTOMER_CLAIMS_REQUEST';
export const GET_ACCESSBANK_CUSTOMER_CLAIMS_SUCCESS = 'GET_ACCESSBANK_CUSTOMER_CLAIMS_SUCCESS';
export const GET_ACCESSBANK_CUSTOMER_CLAIMS_FAILURE = 'GET_ACCESSBANK_CUSTOMER_CLAIMS_FAILURE';

export const GET_ACCESSBANK_SMS_ACTIVITY_REQUEST = 'GET_ACCESSBANK_SMS_ACTIVITY_REQUEST';
export const GET_ACCESSBANK_SMS_ACTIVITY_SUCCESS = 'GET_ACCESSBANK_SMS_ACTIVITY_SUCCESS';
export const GET_ACCESSBANK_SMS_ACTIVITY_FAILURE = 'GET_ACCESSBANK_SMS_ACTIVITY_FAILURE';


export const DELETE_POLICY_DOCUMENT_REQUEST = 'DELETE_POLICY_DOCUMENT_REQUEST';
export const DELETE_POLICY_DOCUMENT_SUCCESS = 'DELETE_POLICY_DOCUMENT_SUCCESS';
export const DELETE_POLICY_DOCUMENT_FAILURE = 'DELETE_POLICY_DOCUMENT_FAILURE';

export const SET_POLICY_DOCUMENT_TO_DELETE = 'SET_POLICY_DOCUMENT_TO_DELETE';
export const RESET_DELETE_POLICY_DOCUMENT_SUCCESS = 'RESET_DELETE_POLICY_DOCUMENT_SUCCESS';
export const RESET_POLICY_DOCUMENT_TO_DELETE = 'RESET_POLICY_DOCUMENT_TO_DELETE';
export const RESET_POLICY_DELETE_DOCUMENT_ERROR = 'RESET_POLICY_DELETE_DOCUMENT_ERROR';

export const ADD_EMAIL_TEMPLATE_REQUEST = 'ADD_EMAIL_TEMPLATE_REQUEST';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMAIL_TEMPLATE_FAILURE = 'ADD_EMAIL_TEMPLATE_FAILURE';

export const GET_EMAIL_TEMPLATES_REQUEST = 'GET_EMAIL_TEMPLATES_REQUEST';
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
export const GET_EMAIL_TEMPLATES_FAILURE = 'GET_EMAIL_TEMPLATES_FAILURE';
export const RESET_GET_EMAIL_TEMPLATES = 'RESET_GET_EMAIL_TEMPLATES'

export const GET_AUDITS_REQUEST = 'GET_AUDITS_REQUEST';
export const GET_AUDITS_SUCCESS = 'GET_AUDITS_SUCCESS';
export const GET_AUDITS_FAILURE = 'GET_AUDITS_FAILURE';

export const GET_REPORT_TEMPLATES_REQUEST = 'GET_REPORT_TEMPLATES_REQUEST';
export const GET_REPORT_TEMPLATES_SUCCESS = 'GET_REPORT_TEMPLATES_SUCCESS';
export const GET_REPORT_TEMPLATES_FAILURE = 'GET_REPORT_TEMPLATES_FAILURE';

export const RESET_GET_REPORT_TEMPLATES = 'RESET_GET_REPORT_TEMPLATES';
export const ADD_REPORT_TEMPLATE_REQUEST = 'ADD_REPORT_TEMPLATE_REQUEST';
export const ADD_REPORT_TEMPLATE_SUCCESS = 'ADD_REPORT_TEMPLATE_SUCCESS';
export const ADD_REPORT_TEMPLATE_FAILURE = 'ADD_REPORT_TEMPLATE_FAILURE'


export const GENERATE_REPORT_REQUEST = 'GENERATE_REPORT_REQUEST';
export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const GENERATE_REPORT_FAILURE = 'GENERATE_REPORT_FAILURE';
export const RESET_GENERATE_REPORT = 'RESET_GENERATE_REPORT'


export const EDIT_REPORT_TEMPLATE_REQUEST = 'EDIT_REPORT_TEMPLATE_REQUEST';
export const EDIT_REPORT_TEMPLATE_SUCCESS = 'EDIT_REPORT_TEMPLATE_SUCCESS';
export const EDIT_REPORT_TEMPLATE_FAILURE = 'EDIT_REPORT_TEMPLATE_FAILURE';
export const SET_REPORT_TEMPLATE_TO_EDIT = 'SET_REPORT_TEMPLATE_TO_EDIT';
export const RESET_EDIT_REPORT_TEMPLATE_SUCCESS = 'RESET_EDIT_REPORT_TEMPLATE_SUCCESS';
export const RESET_EDIT_REPORT_TEMPLATE_ERROR = 'RESET_EDIT_REPORT_TEMPLATE_ERROR';
export const RESET_REPORT_TEMPLATE_TO_EDIT = 'RESET_REPORT_TEMPLATE_TO_EDIT';



export const DELETE_REPORT_TEMPLATE_REQUEST = 'DELETE_REPORT_TEMPLATE_REQUEST';
export const DELETE_REPORT_TEMPLATE_SUCCESS = 'DELETE_REPORT_TEMPLATE_SUCCESS';
export const DELETE_REPORT_TEMPLATE_FAILURE = 'DELETE_REPORT_TEMPLATE_FAILURE';
export const RESET_DELETE_REPORT_TEMPLATE_SUCCESS = 'RESET_DELETE_REPORT_TEMPLATE_SUCCESS';
export const RESET_DELETE_REPORT_TEMPLATE_ERROR = 'RESET_DELETE_REPORT_TEMPLATE_ERROR';
export const SET_REPORT_TEMPLATE_TO_DELETE = 'SET_REPORT_TEMPLATE_TO_DELETE';
export const RESET_REPORT_TEMPLATE_TO_DELETE = 'RESET_REPORT_TEMPLATE_TO_DELETE';


export const AMEND_QUOTE_REQUEST = 'AMEND_QUOTE_REQUEST';
export const AMEND_QUOTE_FAILURE = 'AMEND_QUOTE_FAILURE';
export const AMEND_QUOTE_SUCCESS = 'AMEND_QUOTE_SUCCESS';
export const RESET_AMEND_QUOTE_SUCCESS = 'RESET_AMEND_QUOTE_SUCCESS';
export const RESET_AMEND_QUOTE_ERROR = 'RESET_AMEND_QUOTE_ERROR';
export const SET_QUOTATION_TO_AMEND = 'SET_QUOTATION_TO_AMEND';


export const GET_ALL_MANDATES_REQUEST = 'GET_ALL_MANDATES_REQUEST';
export const GET_ALL_MANDATES_SUCCESS = 'GET_ALL_MANDATES_SUCCESS';
export const GET_ALL_MANDATES_FAILURE = 'GET_ALL_MANDATES_FAILURE';


export const GET_PAYMENTS_BY_POLICY_GUID_REQUEST = 'GET_PAYMENTS_BY_POLICY_GUID_REQUEST';
export const GET_PAYMENTS_BY_POLICY_GUID_SUCCESS = 'GET_PAYMENTS_BY_POLICY_GUID_SUCCESS';
export const GET_PAYMENTS_BY_POLICY_GUID_FAILURE = 'GET_PAYMENTS_BY_POLICY_GUID_FAILURE';

export const GET_GUIDE_REQUEST = 'GET_GUIDE_REQUEST';
export const GET_GUIDE_SUCCESS = 'GET_GUIDE_SUCCESS';
export const GET_GUIDE_FAILURE = 'GET_GUIDE_FAILURE';

export const GET_GUIDES_REQUEST = 'GET_GUIDES_REQUEST';
export const GET_GUIDES_SUCCESS = 'GET_GUIDES_SUCCESS';
export const GET_GUIDES_FAILURE = 'GET_GUIDES_FAILURE';

export const RESET_GET_GUIDES = 'RESET_GET_GUIDES';
export const ADD_GUIDE_REQUEST = 'ADD_GUIDE_REQUEST';
export const ADD_GUIDE_SUCCESS = 'ADD_GUIDE_SUCCESS';
export const ADD_GUIDE_FAILURE = 'ADD_GUIDE_FAILURE'


export const EDIT_GUIDE_REQUEST = 'EDIT_GUIDE_REQUEST';
export const EDIT_GUIDE_SUCCESS = 'EDIT_GUIDE_SUCCESS';
export const EDIT_GUIDE_FAILURE = 'EDIT_GUIDE_FAILURE';
export const SET_GUIDE_TO_EDIT = 'SET_GUIDE_TO_EDIT';
export const RESET_EDIT_GUIDE_SUCCESS = 'RESET_EDIT_GUIDE_SUCCESS';
export const RESET_EDIT_GUIDE_ERROR = 'RESET_EDIT_GUIDE_ERROR';
export const RESET_GUIDE_TO_EDIT = 'RESET_GUIDE_TO_EDIT';



export const DELETE_GUIDE_REQUEST = 'DELETE_GUIDE_REQUEST';
export const DELETE_GUIDE_SUCCESS = 'DELETE_GUIDE_SUCCESS';
export const DELETE_GUIDE_FAILURE = 'DELETE_GUIDE_FAILURE';
export const RESET_DELETE_GUIDE_SUCCESS = 'RESET_DELETE_GUIDE_SUCCESS';
export const RESET_DELETE_GUIDE_ERROR = 'RESET_DELETE_GUIDE_ERROR';
export const SET_GUIDE_TO_DELETE = 'SET_GUIDE_TO_DELETE';
export const RESET_GUIDE_TO_DELETE = 'RESET_GUIDE_TO_DELETE';


export const GET_ELASTIC_SEARCH_REQUEST = 'GET_ELASTIC_SEARCH_REQUEST';
export const GET_ELASTIC_SEARCH_SUCCESS = 'GET_ELASTIC_SEARCH_SUCCESS';
export const GET_ELASTIC_SEARCH_FAILURE = 'GET_ELASTIC_SEARCH_FAILURE';


export const GET_NGSGROUP_CUSTOMER_QUOTES_REQUEST = 'GET_NGSGROUP_CUSTOMER_QUOTES_REQUEST';
export const GET_NGSGROUP_CUSTOMER_QUOTES_SUCCESS = 'GET_NGSGROUP_CUSTOMER_QUOTES_SUCCESS';
export const GET_NGSGROUP_CUSTOMER_QUOTES_FAILURE = 'GET_NGSGROUP_CUSTOMER_QUOTES_FAILURE';

export const GET_NGSGROUP_CUSTOMER_CLAIMS_REQUEST = 'GET_NGSGROUP_CUSTOMER_CLAIMS_REQUEST';
export const GET_NGSGROUP_CUSTOMER_CLAIMS_SUCCESS = 'GET_NGSGROUP_CUSTOMER_CLAIMS_SUCCESS';
export const GET_NGSGROUP_CUSTOMER_CLAIMS_FAILURE = 'GET_NGSGROUP_CUSTOMER_CLAIMS_FAILURE';

export const GET_EMAIL_TEMPLATE_BY_GUID_REQUEST = 'GET_EMAIL_TEMPLATE_BY_GUID_REQUEST';
export const GET_EMAIL_TEMPLATE_BY_GUID_SUCCESS = 'GET_EMAIL_TEMPLATE_BY_GUID_SUCCESS';
export const GET_EMAIL_TEMPLATE_BY_GUID_FAILURE = 'GET_EMAIL_TEMPLATE_BY_GUID_FAILURE';


export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';