import React from 'react'
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./Style";

import { AddCircleRounded } from '@mui/icons-material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { getPartner } from '../../../../../../lib/access.es6';

export default function ViewMembers(props) {

  if (!props.dependants && props.dependants.length === 0) {
    return
  }

  const classes = useStyle();

  const currencyCode = getPartner().country.currency_code

  const additionalDetails = props.dependants[0].additional_details;
  const benefits = props.dependants[0].benefits;
  const totalPremium = (props.dependants[0].premium_amount_in_cents / 100).toLocaleString();



 

  return (
    <>

      <section className={classes.headerDetails}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sm={4}>
            <h6 style={{ textAlign: 'left' }} ><strong> {additionalDetails.product_type}</strong> </h6>
          </Grid>
          <Grid item xs={12} md={4} sm={4}>
            <ul style={{
              listStyle: "none",
              display: "flex",
            }}>
              <li style={{
                marginTop: "-2px",
              }}><h6 style={{ textAlign: 'left' }} ><strong> Additional  Benefits</strong>  </h6></li>
              <li style={{
                cursor: "pointer",
                paddingLeft: "5px"
              }}><AddCircleRounded /></li>
            </ul>

          </Grid>
          <Grid item xs={12} md={4} sm={4}>

            <h6 style={{ textAlign: 'left' }} ><strong> Total Premium:</strong>({currencyCode + " " + totalPremium}) </h6>
          </Grid>
        </Grid>
      </section>
      <section className={classes.detailsTable}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7} sm={7}>
            <table>
              <tr>
                <td>Vehicle Make</td>
                <td>{additionalDetails.vehicle_make}</td>
              </tr>
              <tr>
                <td>Vehicle Model</td>
                <td>{additionalDetails.vehicle_model}</td>
              </tr>
              <tr>
                <td>Year of Manufacture</td>
                <td>{additionalDetails.year_of_manufacture}</td>
              </tr>
              <tr>
                <td>Valuation</td>
                <td> {currencyCode + " " + additionalDetails.valuation_value.toLocaleString()}</td>
              </tr>
              <tr>
                <td>Basic Premium</td>
                <td> {
                  additionalDetails.product_type === "Motor Comprehensive" ? (<>
                    {
                      `${currencyCode}  ${(additionalDetails.valuation_value * additionalDetails.comprehensive_rate / 100).toLocaleString()}` 
            
                    }

                  </>) : (<>
                    {
                      `${currencyCode} ${(additionalDetails.third_party_rate).toLocaleString()}`

                    }

                  </>)

                }</td>
              </tr>
              <tr>
                <td>Stamp Duty</td>
                <td>{currencyCode + " " + additionalDetails.stamp_duty}</td>
              </tr>
              <tr>
                <td>Premium Rate</td>
                <td>{

                  additionalDetails.product_type === "Motor Comprehensive" ? (<>{ `${additionalDetails.comprehensive_rate} % `}</>) : (<>
                    {
                      `${currencyCode}  ${(additionalDetails.third_party_rate).toLocaleString()}`
                    }

                  </>)

                }</td>
              </tr>
              <tr>
                <td>IRA Levy </td>
                <td>{ `${additionalDetails.ira_levy * 100}  %`}</td>
              </tr>
            </table>
          </Grid>
          <Grid item xs={12} md={5} sm={5}>
      
            <table>
              <thead>
                <tr><th colSpan="2">Benefits</th></tr>
              </thead>
              <tbody>
                {benefits.map((benefit, index) => (
                  <tr key={index}>
                    <td>{benefit.type}</td>
                    <td><CheckCircleRoundedIcon color="success" fontSize="small" /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </Grid>

   
      </section>
    </>
  )
}
